import { Output, Input, EventEmitter } from '@angular/core';
import { Component } from '@angular/core';
import { AffidavitProvider } from '../../providers/affidavit.provider';
import { ToastrService } from 'ngx-toastr';



@Component({
  selector: 'app-admin-affidavit',
  templateUrl: './admin-affidavit.component.html',
  styleUrls: ['./admin-affidavit.component.scss']
})
export class AdminAffidavitComponent {
  addingNewAffidavit = false;
  loadingAffidavitRows = false;
  savingNewAffidavit = false;
  affidavitRows: Array<{
    id?: number,
    text: string
  }> = [];
  newAffidavit: string = '';
  constructor(
    private toastr: ToastrService,
    private affidavitProvider: AffidavitProvider
  ) {

    this.loadingAffidavitRows = true;
    this.affidavitProvider.list().subscribe({
      next: (response: any) => {
        this.loadingAffidavitRows = false;
        this.affidavitRows = response.data;
      },
      error: () => {
        this.toastr.error('Could not load affidavit rows', 'Error');
        this.loadingAffidavitRows = false;
      }
    });
  }
  ngOnInit() {
    window['affidavit'] = this;
  }
  submitNewAffidavit() {
    if (this.savingNewAffidavit) {
      return ;
    }
    this.savingNewAffidavit = true;
    this.affidavitProvider.create({text: this.newAffidavit}).subscribe({
      next: (response: any) => {
        this.savingNewAffidavit = false;
        this.affidavitRows.push(response.data);
        this.newAffidavit = '';
        this.addingNewAffidavit = false;
        this.toastr.success('Affidavit created', 'Success');
      },
      error: () => {
        this.toastr.error('Could not create new affidavit', 'Error');
        this.savingNewAffidavit = false;
      }
    });
  }
  editAffidavit(affidavit) {
    if (affidavit._savingEdits) {
      return ;
    }
    affidavit._savingEdits = true;
    this.affidavitProvider.update(affidavit.id, {text: affidavit.text}).subscribe({
      next: (response: any) => {
        affidavit._savingEdits = false;
        affidavit._edit = false;
        this.toastr.success('Affidavit updated', 'Success');
      },
      error: () => {
        this.toastr.error('Could not update affidavit', 'Error');
        affidavit._savingEdits = false;
      }
    });
  }
  removeAffidavit(affidavit) {
    if (confirm('Are you sure you want to remove this affidavit')) {
      affidavit._removing = true;
      this.affidavitProvider.delete(affidavit.id).subscribe({
        next: (response: any) => {
          const ind = this.affidavitRows.indexOf(affidavit);
          this.affidavitRows.splice(ind, 1);
          affidavit._removing = false;
          this.toastr.success('Affidavit deleted', 'Success');
        },
        error: () => {
          this.toastr.error('Could not delete affidavit', 'Error');
          affidavit._removing = false;
        }
      });
    }
  }
}
