import { Injectable } from '@angular/core';
import { APIProvider } from './api.provider';

@Injectable()
export class TankInspectionProvider {

  constructor(private api: APIProvider) {
  }

  get(companyId: number, customerId: number, inspectionId: number, params?) {
    return this.api.get(`company/${companyId}/customer/${customerId}/tank-inspection/${inspectionId}`, params);
  }
  getInspectionStats(companyId: number, params?) {
    return this.api.get(`company/${companyId}/tank-inspection/stats`, params);
  }
  getAdminInspectionStats(params?) {
    return this.api.get(`tank-inspection/stats`, params);
  }
  list(companyId: number, customerId?: number, params?) {
    if (customerId) {
      return this.api.get(`company/${companyId}/customer/${customerId}/tank-inspection`, params);
    } else {
      return this.api.get(`company/${companyId}/tank-inspection`, params);
    }
  }
  create(companyId: number, customerId: number, payload) {
    return this.api.post(`company/${companyId}/customer/${customerId}/tank-inspection`, payload, false);
  }
  update(companyId: number, customerId: number, inspectionId: number, payload, multipart = false) {
    return this.api.put(`company/${companyId}/customer/${customerId}/tank-inspection/${inspectionId}`, payload, multipart);
  }
  rejectTank(companyId: number, customerId: number, inspectionId: number, payload, multipart = false) {
    return this.api.put(`company/${companyId}/customer/${customerId}/tank-inspection/${inspectionId}/reject`, payload, multipart);
  }

  updateInspectionStepStatus(companyId: number, customerId: number, inspectionId: number, payload, multipart = false) {
    return this.api.put(`company/${companyId}/customer/${customerId}/tank-inspection/${inspectionId}/inspection-step-status`, payload, multipart);
  }
  generateChecklist(companyId: number, customerId: number, inspectionId: number) {
    return this.api.get(`company/${companyId}/customer/${customerId}/tank-inspection/${inspectionId}/checklist`);
  }
  beginSigningChecklist(companyId: number, customerId: number, inspectionId: number) {
    return this.api.get(`company/${companyId}/customer/${customerId}/tank-inspection/${inspectionId}/checklist/sign`);
  }
  getExternalInfo(companyId: number, customerId: number, externalId: number) {
    return this.api.get(`company/${companyId}/customer/${customerId}/tank-inspection/external/${externalId}`);
  }
}
