import { Injectable } from '@angular/core';
import { APIProvider } from './api.provider';

@Injectable()
export class AffidavitProvider {

  constructor(private api: APIProvider) {

  }

  list() {
    return this.api.get('affidavit');
  }
  create(payload) {
    return this.api.post('affidavit', payload);
  }
  update(id: number, payload: any) {
    return this.api.put('affidavit/' + id, payload);
  }
  delete(id: number) {
    return this.api.delete('affidavit/' + id);
  }
}
