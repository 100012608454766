<section class="dashboard" *ngIf="inspectionStats">

  <section class="dashboard-greeting" *ngIf="user">
    Hi {{user.firstName}}! Welcome to <span class="color-2">Tracker Control</span>
  </section>

  <div class="item-wrapper" *ngFor="let tile of tiles">
    <div class="item" [ngClass]="{link: tile.routerLink}" [routerLink]="tile.routerLink">
      <table>
        <tbody>
          <tr>
            <td class="label">{{tile.label}}</td>
          </tr>
          <tr>
            <td class="value">{{tile.value}}</td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>

  <div class="row bottom">
    <div class="col-12 col-sm-5">
      <button class="btn btn-slick btn-icon"  routerLink="/company/{{companyId}}/inspection/add">
        <img src="assets/img/add-inspection-icon.png" class="add-inspection-icon" alt="person icon">
        <label>Start a new inspection</label>
      </button>
    </div>
    <div class="col-1 d-none d-sm-block"></div>

    <div class="col-6 d-none d-sm-block">
      <img src="assets/img/radar-icon.png" class="radar-icon" alt="add inspection">
    </div>
  </div>
  <!--
  <div class="row">
    <div class="col-6 col-sm-4">
      <div class="item">
        <div class="label">Un-Tracked Inspections</div>
        <div class="value">{{inspectionStats.openCustomers}}</div>
      </div>
    </div>

    <div class="col-6 col-sm-4">
      <div class="item">
        <div class="label">Tracking Inspections</div>
        <div class="value">{{inspectionStats.activeCustomers}}</div>
      </div>
    </div>

    <div class="col-12 col-sm-4 d-none d-sm-block HIDDENONXS">
      <div class="item" routerLink="/company/{{companyId}}/inspection/completed">
        <div class="label">Tracked Inspections</div>
        <div class="value">{{inspectionStats.completedInspections}}</div>
      </div>
    </div>
  </div>
  <div class="row">
    <div class="col-6 col-sm-4">
      <div class="item inline" routerLink="/company/{{companyId}}/customer/attention">
        <div class="label" style="max-width: inherit;">Inspections Waiting On Additional Information</div>
        <div class="value">{{inspectionStats.inspectionsNeedingAttention}}</div>
      </div>

      <div class="item inline" routerLink="/company/{{companyId}}/inspection/ready">
        <div class="label">Inspections Ready For Review</div>
        <div class="value">{{inspectionStats.inspectionsReadyToCertify}}</div>
      </div>
    </div>
    <div class="col-6 col-sm-4 d-block d-sm-none">
      <div class="item completed-inspections" routerLink="/company/{{companyId}}/inspection/completed">
        <div class="label">Tracked Inspections</div>
        <div class="value">{{inspectionStats.completedInspections}}</div>
      </div>
    </div>

    <div class="col-12 col-sm-8 d-none d-sm-block HIDDENONXS">
      <div class="item-group money-saved">
        <div class="row">
          <div class="col-6">
            <div class="item">
              <div class="label">Total time saved (mins)</div>
              <div class="value">{{inspectionStats.totalTimeSaved}}</div>
            </div>
          </div>
          <div class="col-6">
            <div class="item">
              <div class="label">Total money saved</div>
              <div class="value">${{inspectionStats.totalMoneySaved}}</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="row d-sm-none">
    <div class="col-6">
      <div class="item">
        <div class="label">Total time saved (mins)</div>
        <div class="value">{{inspectionStats.totalTimeSaved}}</div>
      </div>
    </div>

    <div class="col-6">
      <div class="item">
        <div class="label">Total money saved</div>
        <div class="value">${{inspectionStats.totalMoneySaved}}</div>
      </div>
    </div>
  </div>

  <div class="row">
    <div class="col-6 col-sm-4"></div>
    <div class="col-sm-4 d-none d-sm-block">
      <div class="item inline nr-inspections-plan" *ngIf="company && company.plan">
        <div class="label">Number of Inspections Per {{company.plan.name}} Plan</div>
        <div class="value">
          {{nrInspectionsAllowed}}/{{inspectionStats.nrInspectionsThisMonth}}
        </div>
      </div>
    </div>
    <div class="col-6 col-sm-4">
      <button class="btn btn-slick" routerLink="/company/{{companyId}}/inspection/add">Start a new inspection</button>
    </div>
  </div>
  -->
</section>
<section class="dashboard" *ngIf="!inspectionStats" style="text-align: center; padding: 2em;">
  <img *ngIf="loading" src="assets/img/loader.gif" class='loader' alt="loading...">
</section>
