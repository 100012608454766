<section class="inspection-settings clearfix">
  <h4>
    Requested information:
  </h4>

  <div style="text-align: center" *ngIf="loadingInspectionSteps">
    <img class="loader" src="assets/img/loader.gif" alt="loading...">
  </div>

  <div
    *ngFor="let step of inspectionSteps"
    [ngClass]="{'expanded': editingStep && editingStep.id === step.id, 'hidden': editingStep && editingStep.id !== step.id}"

    class="setting"
  >
    <img *ngIf="!step._toggling" class="left-col" (click)="toggleInspectionStepAvailable(step)"
         [src]="step.available ? '/assets/img/check-full-2.png' : '/assets/img/check-empty.png'"
         [alt]="step.available ? 'checked' : 'not checked'"
    >
    <img *ngIf="step._toggling"  class="loader left-col" src="assets/img/loader.gif" alt="loading...">
    <div class="right-col">
      <div *ngIf="!step._editName" class="step-name" (click)="step._editName = true">{{step.name}}</div>
      <div *ngIf="step._editName" class="step-name">
        <input
          id="step-{{step.id}}"
          class="form-control"
          [(ngModel)]="step.name"
          ngModel
          style="margin-bottom: 0.2em;"
        >
        <button class="btn btn-slick small float-right" (click)="saveStepName(step)">
          save
          <img *ngIf="step._saving" src="assets/img/loader.gif" class='loader' alt="loading...">
        </button>
        <button class="btn btn-slick small light float-right" (click)="step._editName = false">
          cancel
        </button>
      </div>

      <div class="inputs" *ngIf="!step._editName">
        <div class="link-2" *ngIf="step._editMode !== 'upload'" (click)="toggleEditMode('upload', step)">
          {{ 'Upload ' + step.type }}
        </div>
        <div class="link-2" *ngIf="step._editMode !== 'instructions'" (click)="toggleEditMode('instructions', step)">
          Add instructions
        </div>
        <div class="link-2"
             *ngIf="step._editMode !== 'questions'"
             (click)="toggleEditMode('questions', step)">
          Add questions
        </div>
        <div class="link-2"
             *ngIf="step._editMode !== 'recommendations'"
             (click)="toggleEditMode('recommendations', step)">
          Add recommendations
        </div>
      </div>

      <app-admin-inspection-question
        [inspectionStep]="step"
        *ngIf="step._editMode === 'questions'"
        (onClose)="toggleEditMode('questions', step)"
      >
      </app-admin-inspection-question>

      <app-admin-inspection-recommendation
        [inspectionStep]="step"
        *ngIf="step._editMode === 'recommendations'"
        (onClose)="toggleEditMode('recommendations', step)"
      >
      </app-admin-inspection-recommendation>

      <!-- Add Instructions Begin -->
      <section *ngIf="step._editMode === 'instructions'">
        <div class="form-group">
          <label for="instructions">Enter your instructions</label>
          <textarea
            style="margin: 0.4em; min-height: 8em;"
            class="form-control"
            name="instructions"
            id="instructions"
            [(ngModel)]="step.instructions"
            placeholder="enter instructions"
            ngModel
          >
          </textarea>

          <button class="btn btn-slick small float-right" (click)="saveInstructions(step)">
            Save Instructions
            <img *ngIf="savingInstructions" src="assets/img/loader.gif" class='loader' alt="loading...">
          </button>
          <button class="btn btn-slick light small float-right" (click)="toggleEditMode('instructions', step)">
            back
          </button>
        </div>
      </section>
      <!-- Add Instructions End -->

      <section *ngIf="step._editMode === 'upload'">
        <div class="uploaded-files">
          <div
            class="file-wrapper"
            *ngFor="let file of step.files"
            (click)="toggleExistingFile(file)"
            [ngClass]="{'selected': selectedExistingFiles.includes(file)}"
          >

            <img [src]="apiUrl + 'file/' + file.uuid" *ngIf="step.type === 'image'">

            <video width="100%" style="max-height: 100%;" controls *ngIf="step.type === 'video'">
              <source [src]="apiUrl + 'file/' + file.uuid">
              Your browser does not support HTML5 video.
            </video>

          </div>
        </div>
        <div class="form-group" style="margin-top: 3em;">
          <input style="margin: 1em 0" type="file" id="step-{{step.id}}-file" accept="{{step.type}}/*" (change)="fileSelected($event)">
          <button class="btn btn-slick light small" (click)="toggleEditMode('upload', step)">
            back
          </button>
          <button *ngIf="selectedExistingFiles.length" style="margin-left: 0.4em;" class="btn btn-slick small warning " (click)="deleteSelectedFiles()">
            Delete
            <img *ngIf="deletingFile" src="assets/img/loader.gif" class='loader' alt="loading...">
          </button>
          <button class="btn btn-slick small" (click)="submitFile()">
            Upload
            <img *ngIf="uploadingFile" src="assets/img/loader.gif" class='loader' alt="loading...">
          </button>
        </div>
        <div class="form-group" style="margin-top: 0.4em;" title="how many files does the customer need to upload">
          <label for="nr-files-required">Nr of files required</label>
          <input
            id="nr-files-required"
            class="form-control"
            name="nrFiles"
            maxlength="255"
            [(ngModel)]="step.nrFiles"
            ngModel
            style="max-width: 5em; display: inline-block; margin-left: 1em;"
            type="number"
          />
          <button class="btn btn-slick small" (click)="saveNrFilesRequired(step)">
            save
          </button>
        </div>
        <div class="upload-preview" *ngIf="selectedFileDataUrl">
            <div *ngIf="!selectedFileDataUrl.length">file preview</div>
            <img *ngIf="selectedFileDataUrl.length" [src]="selectedFileDataUrl" alt="file preview">
        </div>
      </section>
    </div>
  </div>
  <hr>
  <button *ngIf="!addingNewStep" class="btn btn-slick float-right add-inspection-btn" (click)="addingNewStep = true">
    Add New Inspection Step
  </button>
  <br>

  <div class="add-inspection-step" *ngIf="addingNewStep">
    <h2>Add inspection step</h2>
    <div class="form-group">
      <label for="name">Name</label>
      <input
        placeholder="e.g Entire Tank"
        title="Entire Tank, Top of tank, etc"
        required
        id="name"
        class="form-control"
        name="name"
        maxlength="255"
        [(ngModel)]="newInspectionStep.name"
        ngModel
      />
    </div>
    <div class="form-group" title="What kind of files must the user upload during this step">
      <label for="type">Type</label>
      <select class="custom-select" id="type" name="type" [(ngModel)]="newInspectionStep.type">
        <option selected value="">Choose File type</option>
        <option value="image">Image</option>
        <option value="video">Video</option>
      </select>
    </div>

    <div class="form-group" title="How many files does the user need to upload before this step is considered completed">
      <label for="nrFiles">Number of files required</label>
      <input required id="nrFiles" class="form-control" name="nrFiles" maxlength="255" [(ngModel)]="newInspectionStep.nrFiles" ngModel/>
    </div>

    <div class="row" style="margin-top: 2em;">
      <div class="col-12">
        <button class="btn btn-slick float-right" (click)="createInspectionStep()">
          Add
          <img *ngIf="savingInspectionStep" src="assets/img/loader.gif" class='loader' alt="loading...">
        </button>
        <button class="btn btn-slick float-right" style="margin-right: 1em;" (click)="addingNewStep = false">
          Cancel
        </button>
      </div>
    </div>
  </div>
</section>
