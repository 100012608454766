import { Output, Input, EventEmitter } from '@angular/core';
import { Component } from '@angular/core';
import { Customer } from '../models/customer';
import { User } from '../models/user';
import { Recommendation } from '../models/recommendation';
import { CustomerInspectionStep } from '../models/customer-inspection-step';
import { CustomerInspectionStepProvider } from '../providers/customer-inspection-step.provider';

@Component({
  selector: 'app-recommendation',
  templateUrl: './recommendation.component.html',
  styleUrls: ['./recommendation.component.scss']
})
export class RecommendationComponent {
  @Input() customerStep: CustomerInspectionStep;
  @Input() customer: Customer;
  @Output() onSubmit = new EventEmitter<any>();
  loading = true;
  inspectionRecommendations: any[] = [];
  constructor(private customerInspectionStepProvider: CustomerInspectionStepProvider) {

  }
  ngOnInit() {
    this.customerInspectionStepProvider.getRecommendations(
      this.customer.companyId,
      this.customer.id,
      this.customerStep.id
    ).subscribe({
      next: (response: any) => {
        this.inspectionRecommendations = response.data || [];
        this.customerStep.inspectionStep.recommendations.forEach((rec: Recommendation) => {
          rec.inspectionRecommendation = this.inspectionRecommendations.filter(ir => ir.recommendationId === rec.id)[0];
        });
        this.loading = false;
      },
      error: (error) => {
        console.log('error', error.message);
        this.loading = false;
      },
    });
  }
  toggleRecommendation(rec, saveOther) {
    if (rec.inspectionRecommendation) {
      // remove rec
      rec._saving = true;
      this.customerInspectionStepProvider.deleteRecommendation(
        this.customer.companyId,
        this.customer.id,
        this.customerStep.id,
        rec.inspectionRecommendation.id
      ).subscribe({
        next: (response: any) => {
          rec._saving = false;

          const index = this.inspectionRecommendations.indexOf(rec.inspectionRecommendation);
          if (index !== -1) {
            this.inspectionRecommendations.splice(index, 1);
          }
          rec.inspectionRecommendation = undefined;
        },
        error: (error) => {
          rec._saving = false;
        },
      });
    } else {
      // add rec
      if (rec.text.match(/other/i)) {
        if (!saveOther) {
          rec._editText = true;
          rec.text = undefined;
          return ;
        } else if (!rec.text) {
          return alert('Please enter your recommendation');
        }
      }
      rec._saving = true;
      this.customerInspectionStepProvider.addRecommendation(
        this.customer.companyId,
        this.customer.id,
        this.customerStep.id,
        {
          recommendationId: rec.id,
          text: rec.text
        }
      ).subscribe({
        next: (response: any) => {
          rec._saving = false;
          rec.inspectionRecommendation = response.data;
          delete rec._editText;
        },
        error: (error) => {
          rec._saving = false;
        },
      });
    }
    // todo: handle other
  }
}
