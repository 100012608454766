import { Injectable }       from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot } from '@angular/router';
import { UserProvider } from "./providers/user.provider"
@Injectable()
export class AdminCanActivateGuard implements CanActivate {

  constructor(private userProvider: UserProvider) {
  }

  canActivate(route: ActivatedRouteSnapshot): Promise<boolean> {
    return new Promise((resolve, reject) => {
      const adminPath = route.url.filter((segment) => {
        return segment.path === 'admin';
      });
      if (adminPath.length) {
        this.userProvider.loggedInUserObservable.subscribe((user) => {
          if (!user || !user.roles) {
            alert('You are not authorised to visit this page');
            return reject(false);
          }
          const superAdminRole = user.roles.filter((role) => {
            return role.code === 'SUPER_ADMIN';
          });
          if (!superAdminRole.length) {
            alert('You do not have enough permissions to view this page');
            return reject(false);
          }
        });
      }
      resolve(true);
    });
  }
}
