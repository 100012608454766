import { Component, OnInit } from '@angular/core';
import { CompanyProvider } from '../../../providers/company.provider';
import { TankInspectionProvider } from '../../../providers/tank-inspection.provider';
import { ToastrService } from 'ngx-toastr';
import { Router, ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss'],
  providers: []
})
export class DashboardComponent implements OnInit {
  inspectionStats: any;
  loading: boolean;
  nrActiveCompanies: number;
  nrPendingCompanies: number;
  constructor(
    private companyProvider: CompanyProvider,
    private toastr: ToastrService,
    private router: Router,
    private route: ActivatedRoute,
    private tankInspectionProvider: TankInspectionProvider
  ) {}

  ngOnInit() {
    this.route.paramMap.subscribe(params => {
      this.loadInspectionStats();
      this.loadCompanyStats();
    });
  }
  loadInspectionStats() {
    this.loading = true;
    this.tankInspectionProvider.getAdminInspectionStats()
      .subscribe({
        next: (response: any) => {
          this.inspectionStats = response.data;
          this.loading = false;
        },
        error: (error) => {
          this.loading = false;
          this.toastr.error('Could not load dashboard data: ' + error.message, 'Error');
        }
      });
  }
  loadCompanyStats() {
    this.companyProvider.list({
      status: 'pending',
      count: 1
    }).subscribe({
        next: (response: any) => {
          this.nrPendingCompanies = response.data;
        }
      });
    this.companyProvider.list({
      status: 'approved',
      count: 1
    }).subscribe({
      next: (response: any) => {
        this.nrActiveCompanies = response.data;
      }
    });
  }
  showCompanies(status) {
    this.router.navigate(['admin/companies', {status}]);
  }
}
