import { Injectable } from '@angular/core';
import { APIProvider } from './api.provider';
import { User } from '../models/user';
import { BehaviorSubject } from 'rxjs';
import { ActivatedRoute } from '@angular/router';

@Injectable()
export class UserProvider {
  loggedInUserSubject = new BehaviorSubject<User>(undefined);
  loggedInUserObservable = this.loggedInUserSubject.asObservable();

  constructor(private api: APIProvider, private route: ActivatedRoute) {
    // auto-log in user/customer if url contains a token.
    let token;
    this.route.queryParamMap.subscribe((paramMap: any) => {
      token = paramMap.params.token;
      if (!token) {
        token = paramMap.params.jwt;
        if (token) {
          // note: params.token and params.jwt are different things.
          // params.token is shortened url hash to make url's prettier.
          // jwt holds the session. Both can be used to log the user in.
          localStorage.setItem('jwt', token);
        }
      }
      if (token) {
        localStorage.removeItem('user');
        this.tokenLogin(token).subscribe({
          next: (response: any) => {
            const user = response.data.user;
            // accept users who use temporary company setup link
            const company = response.data.company;
            localStorage.setItem('jwt', response.data.token);
            if (user && user.id) {
              localStorage.setItem('user', JSON.stringify(user));
              this.loggedInUserSubject.next(user);
            } else if (company) {
              //
            } else {
              // remove invalid token
              localStorage.removeItem('jwt');
            }
          },
          error: (error) => {
            console.log('token login error', error);
            localStorage.removeItem('jwt');
          }
        });
      }

      let user: any = localStorage.getItem('user');
      if (user) {
        try {
          user = JSON.parse(user);
          this.loggedInUserSubject.next(user);
        } catch (error) {
          console.log('UserData Parsing Error', error, user);
        }
      }
    });
  }

  get(id: number) {
    return this.api.get(`user/${id}`);
  }
  list(companyId: number, params?) {
    return this.api.get(`company/${companyId}/user`, params);
  }
  create(companyId: number, payload) {
    return this.api.post(`company/${companyId}/user`, payload);
  }
  update(companyId: number, userId: number, payload) {
    return this.api.put(`company/${companyId}/user/${userId}`, payload);
  }
  delete(companyId: number, userId: number) {
    return this.api.delete(`company/${companyId}/user/${userId}`);
  }
  setPassword(password) {
    return this.api.put('user/password', { password });
  }
  sendPasswordResetLink(email) {
    return this.api.post('user/password-reset', { email });
  }
  login(email, password) {
    return this.api.post('user/login', {email, password});
  }
  tokenLogin(token) {
    // get session data from login token
    return this.api.get('login/token/' + token, {});
  }
}
