<section class="customer-list">
  <h1>{{title}}</h1>
  <div class="description" *ngIf="description">
    {{description}}
  </div>
  <img *ngIf="loading" src="assets/img/loader.gif" class='loader' alt="loading...">

  <div class="form-group search-box">
    <label for="keyword">Search</label>
    <input
      id="keyword"
      class="form-control"
      [(ngModel)]="keyword"
      ngModel
      (ngModelChange)="onKeywordChange()"
    >
  </div>
  <table class="table">
    <thead class="thead-dark">
    <tr>
      <th>First Name</th>
      <th>Last Name</th>
      <th>Email</th>
      <th>Phone</th>
      <th *ngIf="!hideColumns.address">
        Address
      </th>
      <th style="min-width: 10em;">Inspections</th>
      <th>Inspection<br>Started</th>
      <th *ngIf="!hideColumns.daysSinceLastAction">Days since last action</th>
      <th>Actions</th>
    </tr>
    </thead>

    <tr *ngFor="let customer of customers">
      <ng-container *ngIf="customer._editing">
        <!-- allow inline editing for customer info -->
        <td [attr.colspan]="9 - hiddenColumns" style="padding: 2em 0">
          <app-customer-information
            [editMode]="true"
            [customer]="customer"
            [validationErrors]="validationErrors"
          >
          </app-customer-information>

          <div class="row" style="margin-top: 2em;">
            <div class="col-12">
              <button class="btn btn-slick float-right" (click)="updateCustomer(customer)">
                Save Changes
                <img *ngIf="updatingCustomer" src="assets/img/loader.gif" class='loader' alt="loading...">
              </button>
              <button class="btn btn-slick float-right light" (click)="customer._editing = false" style="margin-right: 1em;">
                Cancel
              </button>
            </div>
          </div>

        </td>
      </ng-container>
      <ng-container *ngIf="!customer._editing">
        <td>{{customer.firstName}}</td>
        <td>{{customer.lastName}}</td>
        <td>{{customer.email}}</td>
        <td>{{customer.cellPhone}}</td>
        <td *ngIf="!hideColumns.address" class="address">
          <div>
            <label>Street</label>{{customer.streetAddress}}
          </div>
          <div>
            <label>State</label>{{customer.state}}
          </div>
          <div>
            <label>Town</label>{{customer.town}}
          </div>
          <div>
            <label>Zip</label>{{customer.zip}}
          </div>
        </td>
        <td>
          <div class="stats">
            <div
              *ngIf="!customer._tankInspectionsInProgress"
              (click)="loadCustomerInspections(customer, {status: ['pending', 'inprogress', 'submitted']})">
              <div class="label">In Progress</div>
              <span class="value" *ngIf="!customer._loadingInspectionsInProgress">
                {{customer.nrInspectionsInProgress}}
              </span>
              <img *ngIf="customer._loadingInspectionsInProgress" src="assets/img/loader.gif" class='loader' alt="loading...">

            </div>
            <div *ngIf="customer._tankInspectionsInProgress" class="expanded">
              <div class="label">In Progress</div>
              <div
                *ngFor="let inspection of customer._tankInspectionsInProgress"
                class="value"
                routerLink="/company/{{customer.companyId}}/customer/{{customer.id}}/inspection/{{inspection.id}}"
              >
                Tank size: {{inspection.tankSize.value}}
              </div>
            </div>
          </div>
          <div class="stats">
            <div
              *ngIf="!customer._tankInspectionsCompleted"
              (click)="loadCustomerInspections(customer, {status: 'completed'})">
              <div class="label">Completed</div>
              <span *ngIf="!customer._loadingInspectionsCompleted" class="value" style="color: green;">
                {{customer.nrCompletedInspections}}
              </span>
              <img *ngIf="customer._loadingInspectionsCompleted" src="assets/img/loader.gif" class='loader' alt="loading...">
            </div>
            <div *ngIf="customer._tankInspectionsCompleted" class="expanded">
              <div class="label">Completed</div>
              <div
                *ngFor="let inspection of customer._tankInspectionsCompleted"
                class="value"
                routerLink="/company/{{customer.companyId}}/customer/{{customer.id}}/inspection/{{inspection.id}}"
              >
                Tank size: {{inspection.tankSize.value}}
              </div>
            </div>
          </div>
        </td>
        <td>
          <span
            *ngIf="customer.tokenCreatedAt"
            [attr.title]="'Inspection started at' + moment(customer.tokenCreatedAt).format('YYYY-MM-DD HH:mm')"
          >
            {{moment.duration(now - moment(customer.tokenCreatedAt)).humanize()}} ago
          </span>
          <span *ngIf="!customer.tokenCreatedAt">
            -
          </span>
        </td>
        <td *ngIf="!hideColumns.daysSinceLastAction">
          <span *ngIf="!customer.lastLogin">-</span>
          <span *ngIf="customer.lastLogin">
            {{daysSinceLogin(customer)}}
          </span>
        </td>
        <td class="buttons" *ngIf="!customer._confirmDelete">
          <button
            class="btn btn-slick small"
            (click)="customer._editing = true"
          >
            Edit Customer
          </button>

          <button
            *ngIf="customer.nrInspectionsInProgress"
            class="btn btn-slick small"
            (click)="reviewTankInspection(customer)"
          >
            Review Changes
          </button>

          <button
            class="btn btn-slick small"
            (click)="resendInspectionRequest(customer)"
            title="Customer will be notified via email and SMS to complete their tank inspection"
          >
            Resend Inspection Request
            <img *ngIf="customer._sendingRequest" src="assets/img/loader.gif" class='loader' alt="loading...">
          </button>
          <button
            class="btn btn-slick small warning"
            (click)="customer._confirmDelete = true"
          >
            Delete Customer
          </button>
        </td>
        <td class="buttons" *ngIf="customer._confirmDelete">
          Confirm deleting customer
          <button
            class="btn btn-slick small warning"
            (click)="deleteCustomer(customer)"
          >
            <img *ngIf="customer._deleting" src="assets/img/loader.gif" class='loader' alt="loading...">
            Delete
          </button>
          <button class="btn btn-slick small light" (click)="customer._confirmDelete = false" style="margin-right: 1em;">
            Cancel
          </button>
        </td>
      </ng-container>

    </tr>
  </table>
  <div style="text-align: center">
    <button
      *ngIf="canLoadMore"
      class="btn btn-slick"
      (click)="loadCustomers()"
    >
      Load More
      <img *ngIf="loading" src="assets/img/loader.gif" class='loader' alt="loading...">
    </button>
  </div>

</section>
