<section *ngIf="inspectionStep" class="recommendations">
  <section *ngFor="let recommendation of inspectionStep.recommendations">
    <div class="recommendation">
      <div class="row">
        <div class="col-8">
          {{recommendation.text}}
        </div>
        <div class="col-4">
          <button class="btn btn-slick small" (click)="startEditingRecommendation(recommendation)">edit</button>
          <button class="btn btn-slick small warning" (click)="deleteRecommendation(inspectionStep, recommendation)">
            delete
            <img *ngIf="inspectionStep._deletingRecommendation" src="assets/img/loader.gif" class='loader' alt="loading...">
          </button>
        </div>
      </div>
    </div>
  </section>

  <div class="form-group" id="edit-recommendation-section">
    <label for="recommendation">{{recommendation.id ? 'Edit': 'Enter' }} your recommendation</label>
    <textarea
      style="margin: 0.4em; min-height: 8em;"
      class="form-control"
      name="recommendation"
      id="recommendation"
      [(ngModel)]="recommendation.text"
      placeholder="enter recommendation"
      ngModel
    >
    </textarea>
    <div class="row" style="margin-bottom: 0.4em;">
      <div class="col-12">
        <label for="recommendation-label">Label</label>
        <input
          style="margin-left: 1em;"
          id="recommendation-label"
          [(ngModel)]="recommendation.label"
          title="Label should describe the recommendation and not change once it is assigned"
        >
      </div>
    </div>

    <button class="btn btn-slick small float-right" (click)="saveRecommendation(inspectionStep)">
      {{recommendation.id ? 'Edit': 'Add'}} Recommendation
      <img *ngIf="savingQuestions" src="assets/img/loader.gif" class='loader' alt="loading...">
    </button>
    <button class="btn btn-slick light small float-right" (click)="onClose.emit(true)">
      back
    </button>
  </div>
</section>
