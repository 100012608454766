<!-- Step 1 - show what is required/instructions -->
<section
  class="customer-inspection-step"
  *ngIf="
    inspectionStep &&
    !rejectedFile &&
    !customerInspectionStep.rejectNote &&
    !uploadNextFile"
>
  <div class="content-box">
    <section
      class="instruction-file-wrapper bg-cover"
      *ngIf="inspectionStep.type === 'image' && instructionFileUuid"
      [ngStyle]="{'background-image': getBgUrl(instructionFileUuid)}"
    >
    </section>
    <section
      *ngIf="inspectionStep.type === 'video' && instructionFileUuid"
      class="instruction-file-wrapper"
    >
      <video width="100%" style="max-height: 100%;" controls>
        <source [src]="getFileUrl(instructionFileUuid)">
        Your browser does not support HTML5 video.
      </video>
    </section>

    <div class="instructions-text" *ngIf="inspectionStep.instructions">
      {{inspectionStep.instructions}}
    </div>
    <div class="tank-label">
      Tank size: {{tankInspection.tankSize.value}}
    </div>
    <div *ngIf="nrFilesNeeded > 1">
      {{toCamelCase(inspectionStep.type)}}s needed: {{nrFilesNeeded}}
    </div>
  </div>

  <ng-container *ngIf="showCantTakeImage">
    <textarea
      style="margin: 0.4em; min-height: 4em;"
      class="form-control"
      name="reason"
      id="reason"
      [(ngModel)]="cantTakeImageReason"
      placeholder="Why can't you take this image"
      ngModel
    >
    </textarea>
    <button class="btn btn-slick" (click)="submitCantTakeImageReason(inspectionStep)">
      Submit
      <img *ngIf="submitting" class="loader" src="assets/img/loader.gif" alt="loading...">
    </button>
  </ng-container>
  <ng-container *ngIf="!showCantTakeImage">
    <div class="link-2" (click)="showCantTakeImage = true">
      I can’t take this image
    </div>
    <button class="btn btn-slick" (click)="ready()">
      Ready
    </button>
  </ng-container>

</section>

<!-- Step 1 - show the image that was rejected and the reason why -->
<section
  class="customer-inspection-step"
  *ngIf="
    inspectionStep &&
    ( rejectedFile || customerInspectionStep.rejectNote) &&
    !uploadNextFile"
>
  <div class="content-box" style="margin: 1em 0;">
    <h3>
      Take an {{inspectionStep.type}} of {{inspectionStep.name}}
    </h3>
    <div class="tank-label">
      Tank size: {{tankInspection.tankSize.value}}
    </div>
    <ng-container *ngIf="rejectedFile">
      <section
        *ngIf="inspectionStep.type === 'image'"
        class="instruction-file-wrapper bg-cover"
        [ngStyle]="{'background-image': getBgUrl(rejectedFile.uuid)}"
      >
      </section>
      <section
        *ngIf="inspectionStep.type === 'video'"
        class="instruction-file-wrapper"
      >
        <video width="100%" style="max-height: 100%;" controls>
          <source [src]="getFileUrl(rejectedFile.uuid)">
          Your browser does not support HTML5 video.
        </video>
      </section>
      <div class="reject-reason">
        <div class="reason-title">
          Reason why your {{inspectionStep.type}} was rejected:
        </div>
        <span class="reason-text">
          {{rejectedFile ? rejectedFile.statusNotes : customerInspectionStep.rejectNote}}
        </span>
      </div>
    </ng-container>
    <ng-container *ngIf="!rejectedFile && customerInspectionStep.rejectNote">
      <!-- Admin rejected customer reason for why they couldnt provide a file -->
      <div class="customer-note">
        <div>Reason why you couldn't submit a file</div>
        {{customerInspectionStep.customerNote}}
      </div>
      <div class="reject-reason">
        <div class="reason-title">Response from admin</div>
        <span class="reason-text">
          {{rejectedFile ? rejectedFile.statusNotes : customerInspectionStep.rejectNote}}
        </span>
      </div>
    </ng-container>

  </div>

  <button class="btn btn-slick" (click)="ready()">
    Retake {{inspectionStep.type}}
  </button>
</section>


<input
  *ngIf="inspectionStep && customerInspectionStep"
  style="margin: 1em 0; opacity: 0;"
  type="file"
  id="customer-file"
  accept="{{inspectionStep.type}}/*;"
  (change)="fileSelected($event)"
  capture="environment"
>
<!-- Confirm selected file -->
<section class="customer-inspection-step" *ngIf="uploadNextFile">
  <button class="btn btn-slick dark" *ngIf="fileIsUnsaved" (click)="retake()" style="width: 50%;">
    Retake
  </button>
  <button class="btn btn-slick" *ngIf="fileIsUnsaved" (click)="submit()" style="width: 50%;">
    Submit
    <img *ngIf="submitting" class="loader" src="assets/img/loader.gif" alt="loading...">
  </button>

  <hr style="margin-top: 0.4em;">

  <section
    class="preview-file-wrapper bg-cover"
    *ngIf="fileDataUrl && inspectionStep.type !== 'video'"
    [ngStyle]="{'background-image': 'url('+ fileDataUrl +')'}"
  >
  </section>
  <section class="preview-file-wrapper" *ngIf="fileDataUrl && inspectionStep.type === 'video'">
    <video width="100%" controls>
      <source src="{{fileObjUrl}}" *ngIf="fileObjUrl">
      Your browser does not support HTML5 video.
    </video>
  </section>

  <section class="instruction-file-wrapper" *ngIf="!fileDataUrl" style="text-align: center;">
    upload next {{inspectionStep.type}}
  </section>


  <div style="margin: 1em;" *ngIf="nrFilesNeeded > 1">
    {{toCamelCase(inspectionStep.type)}} {{nrFilesUploaded + 1}}/{{inspectionStep.nrFiles}}
  </div>


  <div style="min-height: 13em; width: 100%; position: relative;">
    <app-question-answer
      *ngIf="currentQuestion && isQuestionVisible(currentQuestion, customerInspectionStep.inspectionStep.questions)"
      [question]="currentQuestion"
      [questionAnswer]="currentQuestion.answer ? currentQuestion.answer.value: undefined"
      [saveBtnText]="currentQuestion.answer && currentQuestion.answer._saved ? 'saved': 'save'"
      [saveBtnClass]="'dark'"
      (onAnswer)="questionAnswered($event)"
      [customer]="customer"
      [customerInspectionStep]="customerInspectionStep"
      [@inOutAnimation]
    >
    </app-question-answer>
  </div>

  <button class="btn btn-slick" *ngIf="nrFilesNeeded && !fileIsUnsaved" (click)="triggerUpload()">
    <span *ngIf="inspectionStep.nrFiles > 1">
      Upload {{inspectionStep.type}} {{(nrFilesUploaded + 1)}} of {{inspectionStep.nrFiles}}
    </span>
    <span *ngIf="inspectionStep.nrFiles < 2">
      Upload {{inspectionStep.type}}
    </span>
  </button>
  <button class="btn btn-slick" *ngIf="!nrFilesNeeded && !fileIsUnsaved" (click)="completeInspectionStep()">
    Complete This Step
  </button>
</section>


<section class="customer-inspection-step" *ngIf="loadingInspectionStep" style="text-align: center;">
  <img class="loader" src="assets/img/loader.gif" alt="loading...">
</section>
