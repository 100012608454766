<section class="affidavit-section">
  <h4>
    Affidavit
  </h4>

  <div *ngIf="(!affidavitRows || !affidavitRows.length) && !loadingAffidavitRows">
    No affidavit entries!<br>
    Click "Add new affidavit" to add add some.
  </div>
  <div *ngIf="loadingAffidavitRows" style="text-align: center">
    <img src="assets/img/loader.gif" class='loader' alt="loading...">
  </div>

  <div class="row affidavit" *ngFor="let affidavit of affidavitRows">
    <div class="col-9 text">
      <span *ngIf="!affidavit._edit">{{affidavit.text}}</span>
      <div *ngIf="affidavit._edit">
        <textarea
          style="margin: 0.4em; min-height: 8em;"
          class="form-control"
          name="affidavit-text"
          id="affidavit-text"
          [(ngModel)]="affidavit.text"
          placeholder="enter affidavit text"
          ngModel
        >
        </textarea>
      </div>
    </div>
    <div class="col-3">
      <div *ngIf="!affidavit._edit">
        <button class="btn btn-slick small" (click)="affidavit._edit = true;">
          edit
        </button>
        <button class="btn btn-slick small warning" (click)="removeAffidavit(affidavit)">
          remove
          <img *ngIf="affidavit._removing" src="assets/img/loader.gif" class='loader' alt="loading...">
        </button>
      </div>
      <div *ngIf="affidavit._edit">
        <button class="btn btn-slick small" (click)="editAffidavit(affidavit)">
          save changes
          <img *ngIf="affidavit._savingEdits" src="assets/img/loader.gif" class='loader' alt="loading...">
        </button>
      </div>
    </div>
  </div>

  <section *ngIf="addingNewAffidavit" class="new-affidavit-input">
    <div class="form-group">
      <label for="new-affidavit-text">Enter affidavit text</label>
      <textarea
        style="margin: 0.4em; min-height: 8em;"
        class="form-control"
        name="text"
        id="new-affidavit-text"
        [(ngModel)]="newAffidavit"
        placeholder="enter affidavit text"
        ngModel
      >
      </textarea>
    </div>
  </section>

  <button class="btn btn-slick new-affidavit" *ngIf="addingNewAffidavit" (click)="submitNewAffidavit()">
    Save Affidavit
    <img *ngIf="savingNewAffidavit" src="assets/img/loader.gif" class='loader' alt="loading...">
  </button>
  <button class="btn btn-slick new-affidavit" *ngIf="!addingNewAffidavit" (click)="addingNewAffidavit = true;">
    Add New Affidavit
  </button>
</section>
