<section class="recommendations" *ngIf="!loading">

  <div *ngIf="!customerStep.inspectionStep.recommendations.length" style="margin: 2em 0;">
    No recommendations available
  </div>
  <div class="recommendation" *ngFor="let rec of customerStep.inspectionStep.recommendations">
    <div class="row" *ngIf="!rec._editText">
      <div class="col-10">
        <label>
          {{ rec.inspectionRecommendation && rec.inspectionRecommendation.text ? rec.inspectionRecommendation.text : rec.text}}
        </label>
      </div>
      <div class="col-2">
        <div class="custom-checkbox" (click)="toggleRecommendation(rec)">
          <div *ngIf="rec.inspectionRecommendation && !rec._saving" class="custom-checkbox-check"></div>
          <img *ngIf="rec._saving" src="assets/img/loader.gif" class="loader" alt="loading...">
        </div>
      </div>
    </div>


    <div class="row" *ngIf="rec._editText">
      <div class="col-10">
        <input
           placeholder="enter your recommendation"
           required id="rec-text"
           class="form-control"
           name="rec-text"
           maxlength="255"
           [(ngModel)]="rec.text"
           ngModel
        />
      </div>
      <div class="col-2">
        <button class="btn btn-slick small" (click)="toggleRecommendation(rec, true)">
          <span *ngIf="!rec._saving">Save</span>
          <img *ngIf="rec._saving" src="assets/img/loader.gif" class='loader' alt="loading...">
        </button>
      </div>
    </div>

  </div>
</section>
