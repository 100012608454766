<h1 style="text-align: center; margin-top: 3em;" *ngIf="showThankyou">
  Thank you!<br>
  We’ll be in touch shortly.
</h1>
<section class="company-setup" *ngIf="!showThankyou">
  <div class="row">
    <div class="col-12">
      <h1>Company Information</h1>
      <div *ngIf="!company.id">
        Tell us a little bit about your company.<br>
        Once you submit this information you will receive notification of your approval along with next steps!
      </div>
    </div>
  </div>
  <hr>
  <div class="row">
    <div class="col-6">
      <div class="form-group">
        <label for="company-name">Company Name</label>
        <div class="form-value" *ngIf="!editMode">{{company.name}}</div>
        <input *ngIf="editMode" required id="company-name" class="form-control" name="name" maxlength="255" [(ngModel)]="company.name" ngModel/>
        <div class="error" *ngIf="getError('company-name')">{{getError('company-name')}}</div>
      </div>
      <div class="form-group">
        <label for="company-phone">Phone</label>
        <div class="form-value" *ngIf="!editMode">{{company.phone}}</div>
        <input *ngIf="editMode" type="tel" required id="company-phone" class="form-control" name="phone" maxlength="255" [(ngModel)]="company.phone" ngModel/>
        <div class="error" *ngIf="getError('company-phone')">{{getError('company-phone')}}</div>
      </div>
      <div class="form-group">
        <label for="company-email">Email</label>
        <div class="form-value" *ngIf="!editMode">{{company.email}}</div>
        <input *ngIf="editMode" required id="company-email" class="form-control" name="email" maxlength="255" [(ngModel)]="company.email" ngModel/>
        <div class="error" *ngIf="getError('company-email')">{{getError('company-email')}}</div>
      </div>
      <div class="form-group">
        <label for="company-website" title="Company website">Website</label>
        <div class="form-value" *ngIf="!editMode">{{company.website}}</div>
        <input *ngIf="editMode" required id="company-website" class="form-control" name="website" maxlength="255" [(ngModel)]="company.website" ngModel/>
        <div class="error" *ngIf="getError('company-website')">{{getError('company-website')}}</div>
      </div>
    </div>
    <div class="col-6">

      <div class="form-group">
        <label for="company-streetAddress">Street Address</label>
        <div class="form-value" *ngIf="!editMode">{{company.streetAddress}}</div>
        <input *ngIf="editMode" required id="company-streetAddress" class="form-control" name="streetAddress" maxlength="255" [(ngModel)]="company.streetAddress" ngModel/>
        <div class="error" *ngIf="getError('company-streetAddress')">{{getError('company-streetAddress')}}</div>
      </div>
      <div class="form-group">
        <label for="state">State</label>
        <div class="form-value" *ngIf="!editMode">{{company.state}}</div>
        <div class="error" *ngIf="getError('company-state')">{{getError('company-state')}}</div>
        <select
          *ngIf="editMode"
          required
          id="state"
          class="form-control"
          name="state"
          [(ngModel)]="company.state"
        >
          <option *ngFor="let state of stateListProvider.states" value="{{state}}">{{state}}</option>
        </select>
      </div>
      <div class="form-group">
        <label for="company-city">City</label>
        <div class="form-value" *ngIf="!editMode">{{company.city}}</div>
        <input *ngIf="editMode" required id="company-city" class="form-control" name="city" maxlength="255" [(ngModel)]="company.city" ngModel/>
        <div class="error" *ngIf="getError('company-city')">{{getError('company-city')}}</div>
      </div>
      <div class="form-group">
        <label for="company-zip">Zip Code</label>
        <div class="form-value" *ngIf="!editMode">{{company.zip}}</div>
        <input *ngIf="editMode" required id="company-zip" class="form-control" pattern="[0-9+\-]+" name="zip" maxlength="255" [(ngModel)]="company.zip" ngModel/>
        <div class="error" *ngIf="getError('company-zip')">{{getError('company-zip')}}</div>
      </div>

    </div>
  </div>
  <div class="row" style="padding-top: 1.4em; padding-bottom: 2em;">
    <div class="col-6 col-sm-4">
      <div class="form-group" style="margin-top: 3em;">
        <label for="company-logo">Company Logo</label>
        <input *ngIf="editMode" type="file" class="form-control-file" id="company-logo" accept="image/*" (change)="logoSelected($event)">
      </div>
    </div>
    <div class="col-6 col-sm-8">
      <div class="logo-preview" *ngIf="!company.logo || !company.logo.uuid">
        <div *ngIf="!logoDataUrl.length">logo preview</div>
        <img *ngIf="logoDataUrl.length" [src]="logoDataUrl" alt="logo preview">
      </div>
      <div class="logo-preview" *ngIf="company.logo && company.logo.uuid">
        <img [src]="apiUrl + 'file/' + company.logo.uuid" alt="company logo">
      </div>
    </div>
  </div>
  <div class="row">
    <div class="col-6 col-sm-4"></div>
    <div class="col-sm-4 d-none d-sm-block"></div>
    <div class="col-6 col-sm-4">
      <button *ngIf="editMode"
        class="btn btn-slick"
        (click)="submitCompanyInformation()"
        [disabled]="loadingCompany"
      >
        Submit Company Information
        <img *ngIf="loadingCompany" src="assets/img/loader.gif" class='loader' alt="loading...">
      </button>


      <button *ngIf="!editMode"
              class="btn btn-slick"
              (click)="editMode = true"
      >
        Edit Company Information
      </button>
    </div>
  </div>

  <section *ngIf="company.id" class="company-users">
    <hr>
    <!-- users can be added after company entry has been created -->
    <div class="row">
      <div class="col-12">
        <h2>Users</h2>
      </div>
    </div>

    <table class="table">
      <thead class="thead-dark">
        <tr>
          <th>First Name</th>
          <th>Last Name</th>
          <th>Cell Phone</th>
          <th>Email</th>
          <th>Roles</th>
          <th></th>
        </tr>
      </thead>

      <tr *ngIf="!company.users || !company.users.length">
        <td colspan="5">Please add at least one admin user in order to login in the future.</td>
      </tr>
      <tr *ngFor="let user of company.users">
        <ng-container *ngIf="!user._editing">
          <td>{{user.firstName}}</td>
          <td>{{user.lastName}}</td>
          <td>{{user.cellPhone}}</td>
          <td>{{user.email}}</td>
          <td>{{userRoles(user)}}</td>
          <td>
            <ng-container *ngIf="!user._confirmDelete">
              <button class="btn btn-slick small" (click)="user._editing = true;">
                Edit
              </button>
              <button class="btn btn-slick small warning" (click)="user._confirmDelete = true;">
                Delete
              </button>
            </ng-container>
            <ng-container *ngIf="user._confirmDelete">
              Confirm deleting {{user.firstName}} {{user.lastName}}
              <button class="btn btn-slick small warning" (click)="deleteUser(user)">
                Confirm
                <img *ngIf="user._deleting" src="assets/img/loader.gif" class='loader' alt="loading...">
              </button>
            </ng-container>
          </td>
        </ng-container>
        <ng-container *ngIf="user._editing">
          <td>
            <input
              required
              title="edit first name"
              id="user-{{user.id}}-firstName"
              class="form-control"
              name="firstName"
              maxlength="255"
              [(ngModel)]="user.firstName"
              ngModel
            />

            <div class="error" *ngIf="getError('user-' + user.id + '-firstName')">
              {{getError('user-' + user.id + '-firstName')}}
            </div>
          </td>
          <td>
            <input
              required
              title="edit last name"
              id="user-{{user.id}}-lastName"
              class="form-control"
              name="lastName"
              maxlength="255"
              [(ngModel)]="user.lastName"
              ngModel
            />

            <div class="error" *ngIf="getError('user-' + user.id + '-lastName')">
              {{getError('user-' + user.id + '-lastName')}}
            </div>
          </td>
          <td>
            <input
              required
              type="tel"
              title="edit cell phone"
              id="user-{{user.id}}-cellPhone"
              class="form-control"
              name="cellPhone"
              maxlength="255"
              [(ngModel)]="user.cellPhone"
              ngModel
            />

            <div class="error" *ngIf="getError('user-' + user.id + '-cellPhone')">
              {{getError('user-' + user.id + '-cellPhone')}}
            </div>
          </td>
          <td>
            <input
              required
              type="email"
              title="edit user email"
              id="user-{{user.id}}-email"
              class="form-control"
              name="lastName"
              maxlength="255"
              [(ngModel)]="user.email"
              ngModel
            />

            <div class="error" *ngIf="getError('user-' + user.id + '-email')">
              {{getError('user-' + user.id + '-email')}}
            </div>
          </td>
          <td class="user-roles">
            <div *ngFor="let role of roles">
              <input
                id="{{'user-' + user.id + '-role-'+ role.id}}"
                type="checkbox"
                [checked]="userHasRole(user, role.code)"
                (click)="toggleUserRole(user, role)"
              >
              <label for="{{'user-' + user.id + '-role-'+ role.id}}">{{role.name}}</label>
            </div>
            <div class="error" *ngIf="getError('user-' + user.id + '-role')">
              {{getError('user-' + user.id + '-role')}}
            </div>
          </td>
          <td>
            <button
              *ngIf="!user._saving"
              class="btn btn-slick small light"
              (click)="user._editing = false"
            >
              Cancel
            </button>
            <button class="btn btn-slick small" (click)="updateUser(user)">
              Save Changes
              <img *ngIf="user._saving" src="assets/img/loader.gif" class='loader' alt="loading...">
            </button>
          </td>
        </ng-container>

      </tr>
    </table>

    <div class="row">
      <div class="col-6">
        <div class="form-group">
          <label for="user-firstName">First Name</label>
          <input required id="user-firstName" class="form-control" name="firstName" maxlength="255" [(ngModel)]="newUser.firstName" ngModel/>
          <div class="error" *ngIf="getError('user-firstName')">{{getError('user-firstName')}}</div>
        </div>
        <div class="form-group">
          <label for="user-lastName">Last Name</label>
          <input required id="user-lastName" class="form-control" name="lastName" maxlength="255" [(ngModel)]="newUser.lastName" ngModel/>
          <div class="error" *ngIf="getError('user-lastName')">{{getError('user-lastName')}}</div>
        </div>
        <div style="text-align: center; font-size: 0.9em;">
          Note: User will receive an email with instructions on how to setup their password.
        </div>
      </div>

      <div class="col-6">
        <div class="form-group">
          <label for="user-cellPhone">Cell Phone</label>
          <input type="tel" required id="user-cellPhone" class="form-control" name="cellPhone" maxlength="255" [(ngModel)]="newUser.cellPhone" ngModel/>
          <div class="error" *ngIf="getError('user-cellPhone')">{{getError('user-cellPhone')}}</div>
        </div>
        <div class="form-group">
          <label for="user-email">Email</label>
          <input required id="user-email" class="form-control" type="email" name="email" maxlength="255" [(ngModel)]="newUser.email" ngModel/>
          <div class="error" *ngIf="getError('user-email')">{{getError('user-email')}}</div>
        </div>

        <div class="form-group user-roles">
          <label>User Roles</label>
          <div *ngFor="let role of roles">
            <input
              id="{{'new-user-role-'+ role.id}}"
              name="roleId"
              type="checkbox"
              [checked]="userHasRole(newUser, role.code)"
              (click)="toggleUserRole(newUser, role)"
            >
            <label for="{{'new-user-role-'+ role.id}}">{{role.name}}</label>
          </div>
          <div class="error" *ngIf="getError('user-role')">
            {{getError('user-role')}}
          </div>
          <!--select class="custom-select" id="user-roleId" name="roleId" [(ngModel)]="newUserRole">
            <option selected value="">Choose Role</option>
            <option *ngFor="let role of roles" [ngValue]="role">
              {{role.name}}
            </option>
          </select>
          <div class="error" *ngIf="getError('user-role')">{{getError('user-role')}}</div-->
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-6 col-sm-4"></div>
      <div class="col-sm-4 d-none d-sm-block"></div>
      <div class="col-6 col-sm-4">
        <button class="btn btn-slick" (click)="addUser()" [disabled]="loadingUser">
          Add User
          <img *ngIf="loadingUser" src="assets/img/loader.gif" class='loader' alt="loading...">
        </button>
      </div>
    </div>
  </section>

</section>
