import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { DashboardComponent } from "./pages/dashboard/dashboard.component";
import { InspectionAddComponent } from "./pages/inspection-add/inspection-add.component";
import { CustomerInspectionComponent } from "./pages/customer-inspection/customer-inspection.component";
import { CompanyInformationComponent } from "./pages/company-information/company-information.component";
import { CompanyInspectionListComponent } from "./pages/company-inspection-list/company-inspection-list.component";
import { CompanyCustomerListComponent } from "./pages/company-customer-list/company-customer-list.component";
import { InspectionCertifyComponent } from "./pages/inspection-certify/inspection-certify.component";
import { InspectionDetailComponent } from "./pages/inspection-detail/inspection-detail.component";
import { UserPasswordSetupComponent } from './pages/user-password-setup/user-password-setup.component';
import { LoginComponent } from './pages/login/login.component';
import { CompanySettingsComponent } from './pages/company-settings/company-settings.component';
import { AdminGuard } from './admin-can-load.guard';
import { CompanyGuard } from './company-can-activate.guard';
import { PendingChangesGuard } from './pending-changes.guard';


const routes: Routes = [
  { path: 'admin', loadChildren:'./admin/admin.module#AdminModule', canLoad:[AdminGuard]},
  { path: 'company/:companyId/dashboard', component: DashboardComponent, canActivate:[CompanyGuard] },
  { path: '', component: LoginComponent },
  { path: 'company/:companyId/inspection/add', component: InspectionAddComponent, canActivate:[CompanyGuard] },
  {
    path: 'i/:token',
    component: CustomerInspectionComponent,
    canActivate: [CompanyGuard],
    canDeactivate: [PendingChangesGuard]
  },
  { path: 'company/setup', component: CompanyInformationComponent },
  { path: 'company/:companyId', component: CompanyInformationComponent, canActivate:[CompanyGuard] },
  { path: 'company/:companyId/settings', component: CompanySettingsComponent, canActivate:[CompanyGuard] },
  {
    path: 'company/:companyId/inspection/ready',
    component: CompanyInspectionListComponent,
    canActivate:[CompanyGuard],
    data: {
      title: 'Inspections Ready To Certify',
      inspectionFilters: {status: 'submitted'}
    }
  },
  {
    path: 'company/:companyId/inspection/completed',
    component: CompanyInspectionListComponent,
    canActivate:[CompanyGuard],
    data: {
      title: 'Completed Inspections',
      inspectionFilters: {status: ['completed', 'rejected']}
    }
  },
  {
    path: 'company/:companyId/customer/:customerId/inspection/:inspectionId/certify',
    component: InspectionCertifyComponent,
    canActivate:[CompanyGuard]
  },
  {
    path: 'company/:companyId/customer/:customerId/inspection/:inspectionId',
    component: InspectionDetailComponent,
    canActivate:[CompanyGuard]
  },
  {
    path: 'company/:companyId/customer',
    component: CompanyCustomerListComponent,
    canActivate:[CompanyGuard],
    data: {
      title: 'Customer List',
      inspectionFilters: {}
    }
  },
  {
    path: 'company/:companyId/customer/attention',
    component: CompanyCustomerListComponent,
    canActivate:[CompanyGuard],
    data: {
      title: 'Inspections Waiting On Additional Information',
      description: 'This is where you see customers that you are awaiting additional information from.',
      customerFilters: {needsAttention: 1},
      hideColumns: {address: 1}
    }
  },
  {
    path: 'company/:companyId/customer/untracked',
    component: CompanyCustomerListComponent,
    canActivate:[CompanyGuard],
    data: {
      title: 'Untracked Inspections',
      customerFilters: {untracked: 1},
      hideColumns: {daysSinceLastAction: 1}
    }
  },
  { path: 'user/:id/password', component: UserPasswordSetupComponent },
  { path: 'login', component: LoginComponent }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
