import { Injectable } from '@angular/core';
import { APIProvider } from './api.provider';

@Injectable()
export class CompanyInspectionStepProvider {

  constructor(private api: APIProvider) {

  }

  get(companyId: number, stepId: number, params?) {
    return this.api.get(`company/${companyId}/company-inspection-step/${stepId}`, params);
  }
  list(companyId: number, params?) {
    return this.api.get(`company/${companyId}/company-inspection-step`, params);
  }
  update(companyId: number, stepId: number, payload, multipart = false) {
    return this.api.put(`company/${companyId}/company-inspection-step/${stepId}`, payload, multipart);
  }
  create(companyId: number, payload, multipart = false) {
    return this.api.post(`company/${companyId}/company-inspection-step/`, payload, multipart);
  }
}
