<section class="inspection inspection-add">
  <div class="row">
    <div class="col-12">
      <h1>Inspection Request</h1>
    </div>
  </div>

  <section class="inspection-step" *ngIf="inspectionStep === 'customer'">
    <div class="row" style="margin-top: 2em;">
      <div class="col-6">
        <button
          (click)="setInputType('newCustomer')"
          class="btn btn-slick light block"
          title="Click to add new customer"
          [ngClass]="{active: inputType === 'newCustomer'}"
        >
          New Customer
        </button>
      </div>

      <div class="col-6">
        <button
          (click)="setInputType('existingCustomer')"
          class="btn btn-slick light block"
          title="Click to select existing customer"
          [ngClass]="{active: inputType === 'existingCustomer'}"
        >
          Existing Customer
        </button>
      </div>
    </div>

    <div *ngIf="inputType === 'existingCustomer'" class="select-existing-customer">
      <app-autocomplete-input
        [inputLabel]="'Search for customer'"
        [optionLabel]="getCustomerOptionLabel"
        [searchFn]="searchCustomers.bind(this)"
        (select)="onCustomerSelect($event)"
        [id]="'customer-search'"
        [name]="'customer'"
        [onClear]="resetCustomer.bind(this)"
        *ngIf="!customers.length"
      >
      </app-autocomplete-input>
      <hr>

      <ng-container *ngIf="!customers.length && !uploadingCSV && !customer.id">
        <label for="customer-csv-upload">
          Import customers from CSV
        </label>
        <input type="file" class="form-control-file" id="customer-csv-upload" accept=".csv" (change)="customerCSVSelected($event)">
      </ng-container>
      <img *ngIf="uploadingCSV" src="assets/img/loader.gif" class='loader' alt="loading...">

      <ng-container *ngIf="customers.length && !customer.id">
        <table class="table">
          <thead class="thead-dark">
          <tr>
            <th>
              <input
                title="click to select/unselect all customers for inspection"
                type="checkbox"
                (click)="toggleCustomerSelection()"
                class="form-control"
                checked
              >
            </th>
            <th>First Name</th>
            <th>Last Name</th>
            <th>City</th>
            <th>State</th>
            <th>Street</th>
            <th>Zip</th>
          </tr>
          </thead>
          <tbody>
            <tr *ngFor="let customer of customers">
              <td>
                <input
                  title="click to select/unselect this customer for inspection"
                  type="checkbox"
                  [(ngModel)]="customer._selected"
                >
              </td>
              <td>{{customer.firstName}}</td>
              <td>{{customer.lastName}}</td>
              <td>{{customer.town}}</td>
              <td>{{customer.state}}</td>
              <td>{{customer.streetAddress}}</td>
              <td>{{customer.zip}}</td>
            </tr>
          </tbody>
        </table>
      </ng-container>
    </div>
    <hr>
    <section class="customer-information">
      <app-customer-information
        *ngIf="customer.id || inputType === 'newCustomer'"
        [editMode]="editCustomer"
        [customer]="customer"
        [validationErrors]="validationErrors"
      >
      </app-customer-information>
      <div class="row buttons">
        <div class="col-12">
          <button class="btn btn-slick float-right" (click)="saveCustomer()" [disabled]="!customerSelectionDefined">
            Next
            <img *ngIf="savingCustomer" src="assets/img/loader.gif" class='loader' alt="loading...">
          </button>
          <button class="btn btn-slick float-right" *ngIf="!editCustomer && customer.id" (click)="editCustomer=true">
            Edit
          </button>
        </div>
      </div>
    </section>
  </section>

  <section class="inspection-step" *ngIf="inspectionStep === 'staff'" style="max-width: 40em;">
    <div class="form-group" *ngIf="!showStaffSearch">
      <label style="font-weight: normal; font-size: 1.3em;">
        Would you like to assign this inspection to one of your technicians?
      </label>
    </div>
    <app-autocomplete-input
      *ngIf="showStaffSearch"
      [placeholder]="'Search Technicians'"
      [inputLabel]="'Would you like to assign this inspection to one of your technicians?'"
      [optionLabel]="getStaffOptionLabel"
      [searchFn]="searchStaff.bind(this)"
      (select)="onStaffSelect($event)"
      [id]="'staff-search'"
      [name]="'staff'"
      [onClear]="resetStaff.bind(this)"
    >
    </app-autocomplete-input>
    <div
      *ngIf="selectedStaff && selectedStaff.email"
      style="display: inline-block;"
      class="form-group"
    >
      <p>
        Select a date and time to send your technician. You can select an exact time or a range.
        Your customer will receive and email and a text notifying them.
      </p>
      <div class="row">
        <div class="col-12 col-sm-6 datepicker">
          <label for="datepicker" style="width: 100%;">Inspection Date and Time</label>
          <ngb-datepicker #dp id="datepicker" [(ngModel)]="staffInspectionStartDate"></ngb-datepicker>
        </div>
        <div class="col-12 col-sm-6 timepickers">
          <label for="inspection-start-time">From</label>
          <ngb-timepicker
            id="inspection-start-time"
            [(ngModel)]="staffInspectionStartTime"
            [meridian]="'ON'">
          </ngb-timepicker>

          <label for="inspection-end-time">To (optional)</label>
          <ngb-timepicker
            id="inspection-end-time"
            [(ngModel)]="staffInspectionEndTime"
            [meridian]="'ON'">
          </ngb-timepicker>
        </div>
      </div>

    </div>
    <div class="row buttons">
      <div class="col-12" *ngIf="!showStaffSearch">
        <button class="btn btn-slick float-right" (click)="resetStaff();showStaffSearch = true;">
          Yes
        </button>
        <button class="btn btn-slick float-right" (click)="resetStaff();assignStaff()">
          No
        </button>
        <button class="btn btn-slick light float-right" (click)="inspectionStep='customer'">
          Back
        </button>
      </div>
      <div class="col-12" *ngIf="showStaffSearch">
        <button class="btn btn-slick float-right" (click)="assignStaff()">
          Next
          <img *ngIf="assigningStaff" src="assets/img/loader.gif" class='loader' alt="loading...">
        </button>
        <button class="btn btn-slick float-right" (click)="resetStaff();assignStaff()">
          Skip
        </button>
        <button class="btn btn-slick light float-right" (click)="inspectionStep='customer'">
          Back
        </button>
      </div>
    </div>
  </section>
  <section class="inspection-step" *ngIf="inspectionStep === 'confirm'" style="max-width: 40em;">
    <br>
    <span class="confirm-message">
      <span *ngIf="inspectionStarted">
        Your inspection has been started.
      </span>
      <span *ngIf="!inspectionStarted">
        Confirm starting inspection for {{customer.firstName}} {{customer.lastName}}, at {{customer.streetAddress}}
      </span>
    </span>
    <br>

    <div *ngIf="upgradeRequestSent" style="font-size: 1.6em; margin-top: 2em;">
      Thank you for your interest!<br>
      A representative will reach out shortly.
    </div>
    <div class="row buttons nr-btn-2" *ngIf="showUpgradePlan && !upgradeRequestSent">
      <span style="padding: 1em;">
        You have exceeded your monthly inspections.<br>
        Would you like to upgrade?<br>
      </span>
      <button class="btn btn-slick dark" (click)="upgradePlan()">
        Click Here.
        <img *ngIf="upgradingPlan" src="assets/img/loader-dark.gif" class='loader' alt="loading...">
      </button>
    </div>
    <div class="row buttons nr-btn-2" *ngIf="!showUpgradePlan">
      <div class="col-12">
        <button *ngIf="!inspectionStarted" class="btn btn-slick float-right" (click)="startInspection()">
          Start Inspection
          <img *ngIf="startingInspection" src="assets/img/loader.gif" class='loader' alt="loading...">
        </button>
        <button *ngIf="inspectionStarted" class="btn btn-slick float-right" (click)="startAnotherInspection()">
          Add Another Inspection
        </button>
        <button class="btn btn-slick float-right light" (click)="inspectionStep='staff'">
          Back
        </button>
      </div>
    </div>
  </section>
</section>
