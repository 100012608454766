import { TankInspection } from './tank-inspection';

export interface Customer {
  id?: number,
  firstName: string;
  lastName: string;
  cellPhone: string;
  email: string;
  streetAddress: string;
  town: string;
  state: string;
  zip: string;
  companyId?: number;
  accessToken?: string;
  tankInspections?: TankInspection[];
  fieldsChangedByCustomer?: any;
  nrCompletedInspections?: number;
  nrInspectionsInProgress?: number;
  inspectionType?: string;
  staffId?: number;
  lastLogin?: any;
  _selected?: boolean;
}

export const Customer = (fieldValues?): Customer => {
  const customer = {
    firstName: '',
    lastName: '',
    cellPhone: '',
    email: '',
    streetAddress: '',
    town: '',
    state: '',
    zip: ''
  };
  if (fieldValues) {
    Object.assign(customer, fieldValues)
  }
  return customer;
};


export const CustomerFields = {
  firstName: 'First Name',
  lastName: 'Last Name',
  cellPhone: 'Cell Phone',
  email: 'Email',
  streetAddress: 'Street',
  town: 'Town',
  state: 'State',
  zip: 'ZIP'
};
