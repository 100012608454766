import { Injectable } from '@angular/core';
import { APIProvider } from '../../providers/api.provider';

@Injectable()
export class NotificationTemplateProvider {

  constructor(private api: APIProvider) {

  }

  get() {
    return this.api.get(`notification-template`);
  }
  update(id: number, payload) {
    return this.api.put(`notification-template/${id}`, payload);
  }
}
