import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { ConfigurationComponent } from "./pages/configuration/configuration.component";
import { CompaniesComponent } from "./pages/companies/companies.component";
import { DashboardComponent } from "./pages/dashboard/dashboard.component";

import { AdminCanActivateGuard } from '../admin-can-activate.guard';
const routes: Routes = [
  { path: 'admin/configuration', component: ConfigurationComponent, canActivate: [AdminCanActivateGuard] },
  { path: 'admin/companies', component: CompaniesComponent, canActivate: [AdminCanActivateGuard]},
  { path: 'admin/dashboard', component: DashboardComponent, canActivate: [AdminCanActivateGuard]},
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class AdminRoutingModule { }
