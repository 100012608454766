import { Injectable } from '@angular/core';
import { APIProvider } from './api.provider';

@Injectable()
export class CompanyProvider {

  constructor(private api: APIProvider) {

  }

  get(id: number) {
    return this.api.get(`company/${id}`);
  }
  getNrRemainingInspections(id: number) {
    return this.api.get(`company/${id}/inspections-remaining`);
  }
  list(params?) {
    return this.api.get('company', params);
  }
  getUsingToken(token: string) {
    return this.api.get(`company/token/${token}`);
  }
  create(payload) {
    return this.api.post('company', payload, true);
  }
  update(id: number, payload) {
    return this.api.put(`company/${id}`, payload, true);
  }
  approve(id: number) {
    return this.api.put(`company/${id}/approve`);
  }
  reject(id: number, payload?) {
    return this.api.put(`company/${id}/reject`, payload);
  }
  delete(id: number) {
    return this.api.delete(`company/${id}`);
  }
  upgradePlan(id: number) {
    return this.api.post(`company/${id}/upgrade`);
  }
}
