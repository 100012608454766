import { Question } from './question';
import { Recommendation } from "./recommendation";

export interface InspectionStep {
  id?: number;
  name: string;
  type: string;
  nrFiles: number;
  available: number;
  instructions?: string;
  questions?: Question[];
  recommendations?: Recommendation[];
  files?: Array<{
    id: number;
    uuid: string;
  }>;
  _saving?: boolean;
  _addQuestions?: boolean;
  _editMode?: string;
}

export const InspectionStep = (fieldValues?): InspectionStep => {
  const step = {
    name: '',
    type: '',
    nrFiles: 1,
    available: 0,
    questions: []
  };
  if (fieldValues) {
    Object.assign(step, fieldValues)
  }
  return step;
};
