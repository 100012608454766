import { Output, Input, EventEmitter } from '@angular/core';
import { Component } from '@angular/core';

@Component({
  selector: 'app-autocomplete-input',
  templateUrl: './autocomplete-input.component.html',
  styleUrls: ['./autocomplete-input.component.scss']
})
export class AutocompleteInputComponent {
  keyword: string = '';
  autocompleteVisible: boolean;
  @Input() selectedItem: any;
  @Input() placeholder: string = '';
  @Input() items: any[];
  @Input() id;
  @Input() name: string;
  @Input() inputLabel;
  @Input() optionLabel; // string or function that returns a string
  @Input() onKeyPress;
  @Input() onClear;
  @Input() editMode = true;
  @Input() loadingItems = false;
  @Input() searchFn: any;
  @Output() select = new EventEmitter<any>();
  searchTimeout: any;
  constructor() {

  }
  ngOnInit() {

  }
  getOptionLabel(option) {
    if (typeof this.optionLabel === 'string') {
      return option[this.optionLabel];
    }
    if (typeof this.optionLabel === 'function') {
      return this.optionLabel(option);
    }
    throw new Error('Invalid value for autocomplete input "optionLabel"');
  }
  onItemSelected(item) {
    this.selectedItem = item;
    this.autocompleteVisible = false;
    this.editMode = false;
    this.select.emit(this.selectedItem);
  }
  clear() {
    if (typeof this.onClear === 'function') {
      this.onClear();
    } else {
      this.onItemSelected(undefined);
    }
  }
  keyPress(event) {
    if (typeof this.onKeyPress === 'function') {
      this.onKeyPress(event);
    }
    this.searchItems();
  }
  searchItems() {
    if (this.searchTimeout) {
      clearTimeout(this.searchTimeout);
    }
    this.autocompleteVisible = true;
    this.loadingItems = true;
    this.searchTimeout = setTimeout(() => {
      this.searchFn(this.keyword).subscribe({
        next: (response: any) => {
          this.items = Array.isArray(response) ? response : response.data;
          this.loadingItems = false;
        },
        error: (error) => {
          this.items = [];
          this.loadingItems = false;
          console.log('Error getting autocomplete list', error);
        }
      });
    }, 500);
  }
}
