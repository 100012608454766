import { Directive, ElementRef } from '@angular/core';

@Directive({
  selector: '[appScrollIntoView]'
})
export class ScrollIntoViewDirective {
  /*
  * Automatically scrolls element info view once it is created
  * */
  constructor(el: ElementRef) {
    const jQ = window['jQuery'];

    if (jQ) {
      setTimeout(() => {
        jQ('html, body').animate({
          scrollTop: jQ(el.nativeElement).offset().top - 20
        }, 300);
      }, 64);
    }
  }
}
