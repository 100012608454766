import { Injectable } from '@angular/core';
import { APIProvider } from './api.provider';

@Injectable()
export class CustomerAffidavitProvider {

  constructor(private api: APIProvider) {

  }

  list(companyId: number, customerId: number, tankInspectionId: number) {
    return this.api.get(`company/${companyId}/customer/${customerId}/tank-inspection/${tankInspectionId}/affidavit`);
  }
  update(companyId: number, customerId: number, tankInspectionId: number, id: number, payload: any) {
    return this.api.put(`company/${companyId}/customer/${customerId}/tank-inspection/${tankInspectionId}/affidavit/` + id, payload);
  }
}
