<section class="dashboard" *ngIf="inspectionStats">
  <div class="row">
    <div class="col-12 col-sm-4">
      <div class="item">
        <div class="label">Completed Inspections</div>
        <div class="value">{{inspectionStats.completedInspections}}</div>
      </div>
    </div>

    <div class="col-6 col-sm-4">
      <div class="item">
        <div class="label">Open Inspections</div>
        <div class="value">{{inspectionStats.openCustomers}}</div>
      </div>
    </div>

    <div class="col-6 col-sm-4">
      <div class="item">
        <div class="label">Active Inspections</div>
        <div class="value">{{inspectionStats.activeCustomers}}</div>
      </div>
    </div>
  </div>
  <div class="row">
    <div class="col-12 col-sm-4">
      <div class="item" (click)="showCompanies('pending')">
        <div class="label">Pending Companies</div>
        <div class="value">{{nrPendingCompanies}}</div>
      </div>
    </div>

    <div class="col-6 col-sm-4">
      <div class="item" (click)="showCompanies('approved')">
        <div class="label">Active Companies</div>
        <div class="value">{{nrActiveCompanies}}</div>
      </div>
    </div>

    <div class="col-6 col-sm-4">

    </div>
  </div>
</section>
<section class="dashboard" *ngIf="!inspectionStats" style="text-align: center; padding: 2em;">
  <img *ngIf="loading" src="assets/img/loader.gif" class='loader' alt="loading...">
</section>
