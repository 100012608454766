<div class="autocomplete-input">
  <div class="form-group">
    <label for="{{id}}">{{inputLabel}}</label>
    <input
      placeholder="{{placeholder}}"
      required
      id="{{id}}"
      class="form-control"
      name="{{name}}"
      maxlength="255"
      [(ngModel)]="keyword"
      ngModel
      (keypress)="keyPress($event)"
      autocomplete="off"
      *ngIf="editMode"
    />
    <div *ngIf="!editMode" (click)="editMode = true">
      {{getOptionLabel(selectedItem)}}
    </div>
  </div>
  <div class="autocomplete" *ngIf="autocompleteVisible">
    <div class="option" style="text-align: center" *ngIf="loadingItems">
      <img src="assets/img/loader.gif" class='loader' alt="loading...">
    </div>
    <div *ngIf="selectedItem" class="option" (click)="clear()">
      clear selection
    </div>
    <div
      class="option"
      *ngFor="let item of items"
      (click)="onItemSelected(item)"
    >
      {{getOptionLabel(item)}}
    </div>
  </div>
</div>
