import { Injectable }       from '@angular/core';
import { CanLoad, Route, Router } from '@angular/router';
import { UserProvider } from "./providers/user.provider"
@Injectable()
export class AdminGuard implements CanLoad {

  constructor(private router: Router, private userProvider: UserProvider) {
  }

  canLoad(route: Route): Promise<boolean> {
    return new Promise((resolve, reject) => {
      if (route.path == 'admin') {
        this.userProvider.loggedInUserObservable.subscribe((user) => {
          if (!user || !user.roles) {
            alert('You are not authorised to visit this page');
            return reject(false);
          }
          const superAdminRole = user.roles.filter((role) => {
            return role.code === 'SUPER_ADMIN';
          });
          if (!superAdminRole.length) {
            alert('You do not have enough permissions to view this page');
            return reject(false);
          }
        });
      }
      resolve(true);
    });
  }
}
