import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AdminInspectionSettingsComponent } from './components/inspection-settings.component';
import { AdminInspectionQuestionComponent } from './components/inspection-question.component';
import { AdminInspectionRecommendationComponent } from './components/inspection-recommendation.component';
import { AdminAffidavitComponent } from './components/admin-affidavit.component';
import { AdminSettingsComponent } from './components/admin-settings.component';
import { AdminPlansComponent } from './components/admin-plans.component';
import { CompaniesComponent } from './pages/companies/companies.component';
import { ConfigurationComponent } from './pages/configuration/configuration.component';
import { NotificationTemplatesComponent } from './components/notification-templates.component';
import { DashboardComponent } from './pages/dashboard/dashboard.component';
import { FormsModule } from '@angular/forms';
import { AdminRoutingModule } from './admin-routing.module';
import { NotificationTemplateProvider } from './providers/notification-template.provider';


@NgModule({
  declarations: [
    AdminInspectionSettingsComponent,
    AdminInspectionQuestionComponent,
    AdminInspectionRecommendationComponent,
    AdminAffidavitComponent,
    ConfigurationComponent,
    CompaniesComponent,
    AdminSettingsComponent,
    DashboardComponent,
    AdminPlansComponent,
    NotificationTemplatesComponent
  ],
  imports: [
    FormsModule,
    CommonModule,
    AdminRoutingModule
  ],
  providers: [NotificationTemplateProvider]
})
export class AdminModule { }
