<section class="app-configuration">
  <div class="row">
    <div class="col-12">
      <h1>Configuration</h1>
    </div>
  </div>


  <app-admin-inspection-settings></app-admin-inspection-settings>
  <app-admin-affidavit></app-admin-affidavit>
  <app-admin-settings></app-admin-settings>
  <app-admin-plans></app-admin-plans>
  <app-admin-notification-templates></app-admin-notification-templates>
</section>
