<section class="templates">
  <h4>
    Email and SMS Templates
  </h4>
  <p>
    Click on table cell to view/edit template.
    Click on tags to provide test data.
  </p>
  <div style="text-align: center" *ngIf="loading">
    <img class="loader" src="assets/img/loader.gif" alt="loading...">
  </div>

  <div class="form-group test-inputs">
    If test email or phone is provided then "updating" a template will send a test email/sms to given contact.
    <br>
    <label for="test-email">Test Email: </label>
    <input
      type="email"
      class="form-control"
      id="test-email"
      placeholder="template changes will be emailed to this email"
      [(ngModel)]="testEmail"
      ngModel
    >
    <br>
    <label for="test-phone">Test Phone:</label>
    <input
      type="tel"
      class="form-control"
      id="test-phone"
      placeholder="template changes will be messaged to this number"
      [(ngModel)]="testPhone"
      ngModel
    >
  </div>


  <table class="table">
    <thead class="thead-dark">
    <tr>
      <th>Name</th>
      <th>Subject</th>
      <th>Email</th>
      <th>SMS</th>
      <th>Available Tags</th>
      <th></th>
    </tr>
    </thead>
    <tbody>
    <tr *ngFor="let template of templates">

      <td>
        <span *ngIf="!template._editingLabel" (click)="stopEditing(template); template._editingLabel = true">
           {{template.label}}
        </span>
        <input
          *ngIf="template._editingLabel"
          required
          title="enter template label"
          class="form-control"
          name="template-{{template.id}}-label"
          maxlength="255"
          [(ngModel)]="template.label" ngModel
        />
      </td>
      <td>
        <span *ngIf="!template._editingSubject" (click)="stopEditing(template); template._editingSubject = true">
          {{template.subject}}
        </span>
        <input
          *ngIf="template._editingSubject"
          required
          title="enter subject"
          placeholder="email subject"
          class="form-control"
          name="template-{{template.id}}-subject"
          maxlength="255"
          [(ngModel)]="template.subject" ngModel
        />
      </td>
      <td>
          <span *ngIf="!template._editingEmail" (click)="stopEditing(template); template._editingEmail = true">
            click to edit
          </span>
        <textarea
          *ngIf="template._editingEmail"
          class="form-control"
          name="template-{{template.id}}-email"
          id="template-{{template.id}}-email"
          [(ngModel)]="template.email"
          placeholder="enter your email text. Tags can be used as [[firstName]]"
          ngModel
        >
          </textarea>
      </td>
      <td>
          <span *ngIf="!template._editingSms" (click)="stopEditing(template); template._editingSms = true">
            click to edit
          </span>
        <textarea
          *ngIf="template._editingSms"
          class="form-control"
          name="template-{{template.id}}-sms"
          id="template-{{template.id}}-sms"
          [(ngModel)]="template.sms"
          placeholder="enter your sms text. Tags can be used as [[firstName]]"
          ngModel
        >
          </textarea>
      </td>
      <td>
        <div class="tag" *ngFor="let tag of template.tagValues">
            <span *ngIf="!template._editingTags" (click)="template._editingTags = true">
              {{tag.name}}
            </span>
          <span *ngIf="template._editingTags">
              {{tag.name}}:
              <input
                class="form-control"
                id="tag-{{tag.name}}-value"
                placeholder="test value for {{tag.name}}"
                [(ngModel)]="tag.value"
                ngModel
              >
            </span>
        </div>
      </td>
      <td class="buttons">
        <button class="btn btn-slick small light" (click)="stopEditing(template)">
          cancel
        </button>
        <button class="btn btn-slick small" (click)="updateTemplate(template)">
          update
          <img *ngIf="template._saving" src="assets/img/loader.gif" class='loader' alt="loading...">
        </button>
      </td>

    </tr>
    </tbody>
  </table>
</section>
