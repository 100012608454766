import { Injectable }       from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot } from '@angular/router';
import { UserProvider } from "./providers/user.provider"
@Injectable()
export class CompanyGuard implements CanActivate {

  private rejectTimeout;
  constructor(
    private userProvider: UserProvider
  ) {
  }

  canActivate(route: ActivatedRouteSnapshot): Promise<boolean> {
    return new Promise((resolve, reject) => {
      const params: any = route.params;
      const companyId = parseInt(params.companyId, 10);
      const customerId = parseInt(params.customerId, 10);
      const allow = () => {
        if (this.rejectTimeout) {
          clearTimeout(this.rejectTimeout);
        }
        return resolve(true);
      };
      if (params.companyId) {
        this.userProvider.loggedInUserObservable.subscribe((user) => {
          console.log('loggedInUserObservable:', user);
          if (!user) {
            // give time for userProvider to resolve
            this.rejectTimeout = setTimeout(() => {
              reject(false);
            }, 30000);
            return ;
          }

          if (
            route.routeConfig.path === 'company/:companyId/customer/:customerId/inspection/process' &&
            user.companyId === companyId &&
            user.id === customerId
          ) {
            // allow customer to access company inspection route
            return allow();
          }

          if (!user || !user.roles || !user.roles.length) {
            alert('You are not authorised to visit this page');
            return reject(false);
          }

          if (user.roles.filter(r => r.code === 'SUPER_ADMIN').length) {
            // slick admin can see everything
            return allow();
          }
          if (user.companyId === companyId) {
            // company users can see only their own company paths
            return allow();
          } else {
            alert('You are not authorised to visit this page');
            reject(false);
          }
        });
      }
      return allow();
    });
  }
}
