import { Injectable } from '@angular/core';
import { APIProvider } from './api.provider';

@Injectable()
export class PlanProvider {

  constructor(private api: APIProvider) {

  }
  get() {
    return this.api.get('plan');
  }
  update(planId, payload) {
    return this.api.put('plan/' + planId, payload);
  }
}
