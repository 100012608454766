<section class="plans">
  <h4>
    Plans
  </h4>

  <div style="text-align: center" *ngIf="loading">
    <img class="loader" src="assets/img/loader.gif" alt="loading...">
  </div>

  <table class="table">
    <thead class="thead-dark">
      <tr>
        <th>Name</th>
        <th>Technicians Allowed</th>
        <th>Monthly Inspections</th>
        <th></th>
      </tr>
    </thead>
    <tbody>
      <tr *ngFor="let plan of plans">
        <ng-container *ngIf="plan._editing">
          <td>
            <input
              required
              title="enter plan name, e.g expert, pro"
              class="form-control"
              name="plan-{{plan.id}}-name"
              maxlength="255"
              [(ngModel)]="plan.name" ngModel
            />
          </td>
          <td>
            <input
              required
              type="number"
              title="How many technicians does this plan allow to add"
              class="form-control"
              name="plan-{{plan.id}}-technicians"
              [(ngModel)]="plan.technicians" ngModel
            />
          </td>
          <td>
            <input
              required
              type="number"
              title="How many inspections does this plan allow to perform per month"
              class="form-control"
              name="plan-{{plan.id}}-monthlyInspections"
              [(ngModel)]="plan.monthlyInspections" ngModel
            />
          </td>
          <td class="buttons">
            <button class="btn btn-slick small light" (click)="plan._editing = false">
              cancel
            </button>
            <button class="btn btn-slick small" (click)="updatePlan(plan)">
              update
              <img *ngIf="plan._saving" src="assets/img/loader.gif" class='loader' alt="loading...">
            </button>
          </td>
        </ng-container>

        <ng-container *ngIf="!plan._editing">
          <td>{{plan.name}}</td>
          <td>{{plan.technicians}}</td>
          <td>{{plan.monthlyInspections}}</td>
          <td class="buttons">
            <button class="btn btn-slick small" (click)="plan._editing = true">
              edit
            </button>
          </td>
        </ng-container>
      </tr>
    </tbody>
  </table>
</section>
