import { Injectable } from '@angular/core';
import { APIProvider } from './api.provider';

@Injectable()
export class SettingProvider {

  constructor(private api: APIProvider) {

  }

  getAdminSettings() {
    return this.api.get('admin/setting');
  }
  updateAdminSetting(id: number, payload: any) {
    return this.api.put('admin/setting/' + id, payload);
  }
}
