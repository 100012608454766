<section class="customer-inspection step-{{currentStep}}">
  <section class="company-header" *ngIf="company">
    <div
      class="logo-wrapper bg-cover"
      [ngStyle]="{'background-image': 'url(' + getLogoUrl() + ')'}"
    >
    </div>
    <div class="name">{{company.name}}</div>
  </section>
  <img *ngIf="loading" src="assets/img/loader.gif" class='loader' alt="loading...">

  <section class="inspection-step-1" *ngIf="currentStep === 1 && customer">
    <div class="content-box">
      <p>
        Hi {{customer.firstName}} {{customer.lastName}}!<br><br>

        Please follow the instructions to complete your tank inspection.
        These inspections help ensure a safe delivery.
        They are 100% free and it should only take a couple of minutes.
        Ready?
      </p>
    </div>

    <button class="btn btn-slick block small" (click)="nextStep()" style="text-align: center; margin-top: 2em;">
      Start my inspection.
    </button>
  </section>


  <section class="inspection-step-2" *ngIf="currentStep === 2">
    <div class="content-box">
      <h3>Please confirm your information</h3>

      <app-customer-information
        *ngIf="customer && customer.id"
        [editMode]="editCustomer"
        [customer]="customer"
        [validationErrors]="validationErrors"
        [showInspectionType]="!!customer.staffId"
      >
      </app-customer-information>
    </div>
    <div class="row" style="margin-top: 2em;" *ngIf="!editCustomer">
      <div class="col-6">
        <button class="btn btn-slick block small dark" (click)="editCustomer = true">
          No, something is wrong.
        </button>
      </div>
      <div class="col-6">
        <button class="btn btn-slick block small" (click)="nextStep()">
          Yes, this is correct
        </button>
      </div>
    </div>
    <div class="row" style="margin-top: 2em;" *ngIf="editCustomer">
      <div class="col-6">
        <button class="btn btn-slick btn-light block small" (click)="editCustomer = false">
          Cancel
        </button>
      </div>
      <div class="col-6">
        <button class="btn btn-slick block small" (click)="updateCustomerInfo()">
          Submit Corrections
          <img *ngIf="savingCustomer" src="assets/img/loader.gif" class='loader' alt="loading...">
        </button>
      </div>
    </div>
  </section>

  <section class="inspection-step-3" *ngIf="currentStep === 3">
    <div class="content-box">
      <div class="row">
        <div class="col-7">
          <label for="nrTanks">How many tanks do you have?</label>
        </div>
        <div class="col-5">
          <select
            class="custom-select"
            required id="nrTanks"
            name="nrTanks"
            type="number"
            [disabled]="externalTankInfo?.tankId"
            [(ngModel)]="nrTanks"
            (ngModelChange)="onNrTanksChange()"
          >
            <option *ngFor="let nr of [1,2,3,4,5]" value="{{nr}}">
              {{nr}}
            </option>
          </select>
        </div>
      </div>

      <section class="customer-tank" *ngFor="let tankInspection of tankInspections; index as i">
        <div class="tank-index" *ngIf="tankInspections.length > 1">
          Tank {{i + 1}}
        </div>
        <div class="row">
          <div class="col-7">
            <label for="tankSize">What size is the tank? (Gallons)</label>
          </div>
          <div class="col-5">
            <div class="error" *ngIf="tankInspection._errors.tankSize">{{tankInspection._errors.tankSize}}</div>
            <select
              class="custom-select"
              id="tankSize"
              name="tankSize"
              [(ngModel)]="tankInspection.tankSize"
              [disabled]="externalTankInfo?.tankSize"
            >
              <option *ngFor="let tankSize of tankSizes" [ngValue]="tankSize">
                {{tankSize.value}}
              </option>
            </select>
          </div>
        </div>
        <div class="row">
          <div class="col-7">
            <label for="tankAge">How old is the tank? (Years)</label>
          </div>
          <div class="col-5">
            <div class="error" *ngIf="tankInspection._errors.tankAge">{{tankInspection._errors.tankAge}}</div>

            <select
              class="custom-select"
              required id="tankAge"
              name="tankAge"
              type="number"
              [(ngModel)]="tankInspection.tankAge"
            >
              <option *ngFor="let tankAge of tankAges; index as i" value="{{tankAge}}" [attr.selected]="i === 0">
                {{tankAge}}
              </option>
            </select>

          </div>
        </div>
      </section>
    </div>

    <div *ngIf="tankInspections.length" style="margin-top: 1em;">
      <button class="btn btn-slick block" (click)="createTankInspections()">
        Next
        <img *ngIf="creatingTankInspections" src="assets/img/loader.gif" class='loader' alt="loading...">
      </button>
    </div>

  </section>
  <section class="inspection-step-4" *ngIf="currentStep === 4">
    <!-- repeating step -->
    <app-customer-inspection-step
      *ngIf="currentCustomerInspectionStep"
      [customerInspectionStep]="currentCustomerInspectionStep"
      [tankInspection]="currentTankInspection"
      [customer]="customer"
      (onComplete)="inspectionStepCompleted($event)"
    >
    </app-customer-inspection-step>
  </section>

  <section class="inspection-step-5" *ngIf="currentStep === 5">
    <app-affidavit *ngIf="company && customer && currentTankInspection"
      [tankInspection]="currentTankInspection"
      [customer]="customer"
      [company]="company"
      (onComplete)="affidavitComplete($event)"
    >
    </app-affidavit>
  </section>
  <section class="inspection-step-6" *ngIf="currentStep === 6">
    <h1>You have completed your tank inspection.</h1>
    <div style="margin-top: 2em">
      If {{company.name}} needs any additional information you will be notified.
      You will also be notified once your inspection has been accepted and your delivery is scheduled!
    </div>
  </section>
</section>
