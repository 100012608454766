import { Input } from '@angular/core';
import { Component } from '@angular/core';
import { CustomerProvider } from '../providers/customer.provider';
import { Customer } from '../models/customer';
import { ToastrService } from 'ngx-toastr';
import { environment } from '../../environments/environment';
import * as moment from 'moment';

@Component({
  selector: 'app-certificate',
  templateUrl: './certificate.component.html',
  styleUrls: ['./certificate.component.scss']
})
export class CertificateComponent {
  @Input() tankInspection: any;
  @Input() customer: Customer;
  apiUrl = environment.apiUrl;
  constructor(
    private toastr: ToastrService,
    private customerProvider: CustomerProvider) {

  }
  ngOnInit() {}
  getFileUrl(file) {
    return this.apiUrl + 'file/' + file.uuid;
  }
  getCustomerImages() {
    let images = [];
    const imageSteps = this.tankInspection.customerInspectionSteps.filter(step => step.inspectionStep.type === 'image');
    imageSteps.forEach((step) => {
      if (step.files) {
        images = images.concat(step.files);
      }
    });
    return images;
  }
  getCertificateImageBgUrl(index) {
    const images = this.getCustomerImages();
    const file = images[index];
    if (!file || !file.uuid) {
      return '';
    }
    return `url(${this.getFileUrl(file)})`;
  }
  get dateOfInspection() {
    if (!this.tankInspection || !this.tankInspection.affidavits) {
      return '';
    }
    return moment(this.tankInspection.affidavits[0].updatedAt).format('MMM DD, YYYY.');
  }
}
