import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { DashboardComponent } from './pages/dashboard/dashboard.component';
import { InspectionAddComponent } from './pages/inspection-add/inspection-add.component';
import { CustomerInspectionComponent } from './pages/customer-inspection/customer-inspection.component';
import { UserPasswordSetupComponent } from './pages/user-password-setup/user-password-setup.component';
import { CompanySettingsComponent } from './pages/company-settings/company-settings.component';
import { LoginComponent } from './pages/login/login.component';
import { CompanyInformationComponent } from './pages/company-information/company-information.component';
import { CompanyInspectionListComponent } from './pages/company-inspection-list/company-inspection-list.component';
import { CompanyCustomerListComponent } from './pages/company-customer-list/company-customer-list.component';
import { InspectionCertifyComponent } from './pages/inspection-certify/inspection-certify.component';
import { InspectionDetailComponent } from './pages/inspection-detail/inspection-detail.component';
import { CustomerInformationComponent } from './components/customer-information.component';
import { AutocompleteInputComponent } from './components/autocomplete-input.component';
import { QuestionAnswerComponent } from './components/question-answer.component';
import { RecommendationComponent } from './components/recommendation.component';
import { AffidavitComponent } from './components/affidavit.component';
import { CustomerInspectionStepComponent } from './components/customer-inspection-step.component';
import { ScrollIntoViewDirective } from './directives/scroll-into-view.directive';
import { CertificateComponent } from './components/certificate.component';
import { HttpClientModule } from '@angular/common/http';
import { CompanyProvider } from './providers/company.provider';
import { UserProvider } from './providers/user.provider';
import { CustomerProvider } from './providers/customer.provider';
import { CustomerInspectionStepProvider } from './providers/customer-inspection-step.provider';
import { AffidavitProvider } from './providers/affidavit.provider';
import { CustomerAffidavitProvider } from './providers/customer-affidavit.provider';
import { RoleProvider } from './providers/role.provider';
import { TankInspectionProvider } from './providers/tank-inspection.provider';
import { TankSizeProvider } from './providers/tank-size.provider';
import { APIProvider } from './providers/api.provider';
import { InspectionStepProvider } from './providers/inspection-step.provider';
import { CompanyInspectionStepProvider } from './providers/company-inspection-step.provider';
import { StateListProvider } from './providers/state-list.provider';
import { AdminModule } from './admin/admin.module';
import { AdminGuard } from './admin-can-load.guard';
import { AdminCanActivateGuard } from './admin-can-activate.guard';
import { CompanyGuard } from './company-can-activate.guard';
import { SettingProvider } from './providers/setting.provider';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ToastrModule } from 'ngx-toastr';
import { PendingChangesGuard } from './pending-changes.guard';
import { NgbDatepickerModule, NgbTimepickerModule, NgbModalModule } from '@ng-bootstrap/ng-bootstrap';
import { PlanProvider } from './providers/plan.provider';

@NgModule({
  declarations: [
    AppComponent,
    DashboardComponent,
    CompanyInformationComponent,
    CompanySettingsComponent,
    CompanyInspectionListComponent,
    CompanyCustomerListComponent,
    CustomerInspectionStepComponent,
    InspectionAddComponent,
    InspectionCertifyComponent,
    InspectionDetailComponent,
    CustomerInspectionComponent,
    CustomerInformationComponent,
    AffidavitComponent,
    AutocompleteInputComponent,
    UserPasswordSetupComponent,
    LoginComponent,
    CertificateComponent,
    QuestionAnswerComponent,
    RecommendationComponent,
    ScrollIntoViewDirective
  ],
  imports: [
    BrowserModule,
    HttpClientModule,
    AppRoutingModule,
    FormsModule,
    BrowserAnimationsModule,
    ToastrModule.forRoot(),
    AdminModule,
    NgbDatepickerModule,
    NgbTimepickerModule,
    NgbModalModule
  ],
  providers: [
    UserProvider,
    CompanyProvider,
    CompanyInspectionStepProvider,
    CustomerProvider,
    CustomerInspectionStepProvider,
    RoleProvider,
    APIProvider,
    InspectionStepProvider,
    AdminGuard,
    AdminCanActivateGuard,
    PendingChangesGuard,
    CompanyGuard,
    TankSizeProvider,
    TankInspectionProvider,
    AffidavitProvider,
    CustomerAffidavitProvider,
    SettingProvider,
    StateListProvider,
    PlanProvider
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
