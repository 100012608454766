import { Component, OnInit } from '@angular/core';
import { UserProvider } from '../../providers/user.provider';
import { ToastrService } from 'ngx-toastr';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'app-user-password-setup',
  templateUrl: './user-password-setup.component.html',
  styleUrls: ['./user-password-setup.component.scss'],
  providers: []
})
export class UserPasswordSetupComponent implements OnInit {
  password = '';
  passwordConfirm = '';
  token: string;
  loading = false;
  constructor(
    private userProvider: UserProvider,
    private toastr: ToastrService,
    private route: ActivatedRoute,
    private router: Router
  ) {}

  ngOnInit() {
    this.token = this.route.snapshot.queryParamMap.get('token');
    localStorage.setItem('jwt', this.token);
  }
  setPassword() {
    if (this.password !== this.passwordConfirm) {
      return this.toastr.error('Passwords do not match', 'Error');
    }
    this.loading = true;
    this.userProvider.setPassword(this.password).subscribe({
      next: (response: any) => {
        // ToDo: auto-login and redirect to company dashboard
        const {token, user } = response.data;
        this.toastr.success('Your password has been set successfully', 'Success');
        this.loading = false;
        localStorage.setItem('jwt', token);
        localStorage.setItem('user', JSON.stringify(user));
        localStorage.setItem('companyId', String(user.companyId));
        // this.userProvider.loggedInUser = user;
        this.userProvider.loggedInUserSubject.next(user);
        setTimeout(() => {
          this.router.navigate([`company/${user.companyId}/dashboard`]);
        }, 2000);
      },
      error: () => {
        this.loading = false;
      }
    });
  }
  passwordValid() {
    return this.password.length > 4 &&
      this.password === this.passwordConfirm && this.password.match(/\d/);
  }
}
