<section class="inspection-list">
  <h1>{{title}}</h1>
  <img *ngIf="loading" src="assets/img/loader.gif" class='loader' alt="loading...">

  <table class="table">
    <thead class="thead-dark">
      <tr>
        <th>First Name</th>
        <th>Last Name</th>
        <th>City</th>
        <th>State</th>
        <th>Actions</th>
      </tr>
    </thead>

    <tr *ngFor="let customer of customers">
      <td>{{customer.firstName}}</td>
      <td>{{customer.lastName}}</td>
      <td>{{customer.town}}</td>
      <td>{{customer.state}}</td>
      <td>
        <button
          *ngFor="let tankInspection of customer.tankInspections"
          title="tank size: {{tankInspection.tankSize.value}}"
          class="btn btn-slick small certify"
          [ngClass]="{'warning': tankInspection.status === 'rejected'}"
          (click)="openInspection(customer.id, tankInspection.id, tankInspection)"
          >
          Inspection Details
        </button>
      </td>
    </tr>
  </table>
  <div style="text-align: center">
    <button
      *ngIf="canLoadMore"
      class="btn btn-slick"
      (click)="loadInspections()"
    >
      Load More
      <img *ngIf="loading" src="assets/img/loader.gif" class='loader' alt="loading...">
    </button>
  </div>
</section>
