import { Component, OnInit } from '@angular/core';
import { UserProvider } from '../../providers/user.provider';
import { ToastrService } from 'ngx-toastr';
import { Router } from '@angular/router';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
  providers: []
})
export class LoginComponent implements OnInit {
  password = '';
  email = '';
  loading = false;
  passwordReset = false;
  passwordLinkSent = false;
  constructor(
    private userProvider: UserProvider,
    private toastr: ToastrService,
    private router: Router
  ) {}

  ngOnInit() {

  }
  login() {
    this.loading = true;
    this.userProvider.login(this.email, this.password).subscribe({
      next: (response: any) => {
        this.loading = false;
        if (!response.data || !response.data.token) {
          return this.toastr.error('Login failed', 'Error');
        }
        const {token, user} = response.data;
        this.toastr.success('Logged in', 'Success', {timeOut: 1000});
        localStorage.setItem('jwt', token);
        localStorage.setItem('user', JSON.stringify(user));
        localStorage.setItem('companyId', String(user.companyId));
        // this.userProvider.loggedInUser = user;
        this.userProvider.loggedInUserSubject.next(user);
        setTimeout(() => {
          this.router.navigate([`company/${user.companyId}/dashboard`]);
        }, 1000);

      },
      error: () => {
        this.loading = false;
      }
    });
  }
  resetPassword() {
    if (this.loading || this.passwordLinkSent) {
      return ;
    }
    this.loading = true;
    this.userProvider.sendPasswordResetLink(this.email).subscribe({
      next: (response: any) => {
        this.loading = false;
        this.passwordLinkSent = true;
        this.toastr.success('Password reset link has been sent to your email', 'Success');
        setTimeout(() => {
          this.router.navigate(['login']);
        }, 2000);
      },
      error: () => {
        this.loading = false;
      }
    });
  }
  onKeyDown(event) {
    if (event.keyCode === 13) {
      this.login();
    }
  }
}
