<section class="question-answer">
  <label class="question-text" for="question-{{question.id}}-input">
    {{question.text}}
  </label>
  <div class="question-answer-box">
    <ng-container *ngIf="question.answerType === 'bool'">
      <div class="row bool">
        <div class="col-6">
          Yes
          <div class="custom-checkbox" (click)="setBooleanAnswer(true)">
            <div *ngIf="isTrue() && !saving" class="custom-checkbox-check"></div>
            <img *ngIf="isTrue() && saving" src="assets/img/loader.gif" class="loader" alt="loading...">
          </div>
        </div>
        <div class="col-6">
          No
          <div class="custom-checkbox" (click)="setBooleanAnswer(false)">
            <div *ngIf="questionAnswer!== undefined && !isTrue() && !saving" class="custom-checkbox-check"></div>
            <img *ngIf="!isTrue() && saving" src="assets/img/loader.gif" class="loader" alt="loading...">
          </div>
        </div>
      </div>
    </ng-container>
    <ng-container *ngIf="question.answerType === 'string'">
      <div class="row string">
        <div class="col-9">
          <input
            (ngModelChange)="modelChange()"
            id="question-{{question.id}}-input"
            class="form-control"
            [(ngModel)]="questionAnswer"
            ngModel
          >
        </div>
        <div class="col-3">
          <button class="btn btn-slick small save {{saveBtnClass}}" (click)="setTextAnswer()">
            {{saveBtnText}}
            <img *ngIf="saving" src="assets/img/loader.gif" class='loader' alt="loading...">
          </button>
        </div>
      </div>
    </ng-container>
    <ng-container *ngIf="question.answerType === 'text'">
      <div class="row string">
        <div class="col-12">
          <textarea
            id="question-{{question.id}}-input"
            class="form-control"
            [(ngModel)]="questionAnswer"
            ngModel
            (ngModelChange)="modelChange()"
          >
          </textarea>
          <button class="btn btn-slick small save {{saveBtnClass}}" (click)="setTextAnswer()">
            {{saveBtnText}}
            <img *ngIf="saving" src="assets/img/loader.gif" class='loader' alt="loading...">
          </button>
        </div>
      </div>
    </ng-container>
    <!-- ToDo: number -->
  </div>
</section>
