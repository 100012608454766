import { Component, OnInit } from '@angular/core';
import { Company } from '../../models/company';
import { User } from '../../models/user';
import { Role } from '../../models/role';
import { RoleProvider } from '../../providers/role.provider';
import { CompanyProvider } from '../../providers/company.provider';
import { UserProvider } from '../../providers/user.provider';
import { ToastrService } from 'ngx-toastr';
import { environment } from '../../../environments/environment';
import { ActivatedRoute } from '@angular/router';
import { ViewEncapsulation } from '@angular/core';
import { StateListProvider } from '../../providers/state-list.provider';

const jQuery = window['jQuery'];

/*
* After signing up and paying for subscription, a company rep
* is redirected to this page. Collect basic info about the company
* and create the first admin user which they can use from there on.
* Later, this component can be used to edit/manage users of a company.
* */
@Component({
  selector: 'app-company-information',
  templateUrl: './company-information.component.html',
  styleUrls: ['./company-information.component.scss'],
  providers: [],
  encapsulation: ViewEncapsulation.None
})
export class CompanyInformationComponent implements OnInit {
  showThankyou = false;
  company: Company = {
    name: '',
    streetAddress: '',
    city: '',
    state: '',
    phone: '',
    zip: '',
    email: '',
    website: '',
    logoId: undefined,
    logo: {
      uuid: ''
    },
    status: '',
    users: []
  };
  newUser: User = {
    firstName: '',
    lastName: '',
    cellPhone: '',
    email: '',
    roles: []
  };
  passwordConfirm: string = '';
  newUserRole: Role;
  editMode = false;
  logoDataUrl: string = '';
  roles = [];
  logoFile: any;
  validationErrors: any = {};
  apiUrl = environment.apiUrl;
  companyId: number;
  loadingCompany = false;
  loadingUser = false;
  constructor(
    private roleProvider: RoleProvider,
    private companyProvider: CompanyProvider,
    private userProvider: UserProvider,
    private toastr: ToastrService,
    private route: ActivatedRoute,
    public stateListProvider: StateListProvider
  ) {}

  ngOnInit() {

    this.route.queryParamMap.subscribe((paramMap: any) => {
      const jwt = paramMap.params.token ? paramMap.params.token : localStorage.getItem('jwt');
      if (jwt) {
        localStorage.setItem('jwt', jwt);
      } else {
        localStorage.removeItem('jwt');
      }

      this.route.paramMap.subscribe((params: any) => {
        this.companyId = params.get('companyId');
        if (!this.companyId) {
          // setting up a new company
          this.editMode = true;
          if (jwt) {
            // in case user closed the company setup window and returned
            this.companyProvider.getUsingToken(jwt).subscribe((response: {data: Company}) => {
              this.company = response.data;
            });
          }
        } else {
          // editing existing company.
          if (!jwt) {
            return this.toastr.error('Please login to view company information', 'Error');
          }
          this.companyProvider.get(this.companyId).subscribe((response: {data: Company}) => {
            this.company = response.data;
          });
        }
        this.roleProvider.get().subscribe((response: any) => {
          this.roles = response.data;
        });
      });
    });
  }
  submitCompanyInformation() {
    this.validationErrors = {};
    const formData = new FormData();
    Object.keys(this.company).forEach((field) => {
      if (field === 'logo') {
        if (this.logoFile) {
          formData.set('file', this.logoFile);
        } else {
          formData.set('file', null);
        }
      } else {
        if (this.company[field] === undefined) {
          formData.set(field, null);
        } else {
          formData.set(field, this.company[field]);
        }
      }

    });
    this.loadingCompany = true;
    if (!this.company.id) {
      this.companyProvider.create(formData).subscribe({
        next: (response: {data: Company, token: string}) => {
          const company: Company = response.data;
          const token = response.token;
          if (!token) {
            this.toastr.error('Could not create access token', 'Error');
          } else {
            localStorage.setItem('jwt', token);
            localStorage.setItem('companyId', String(company.id));
          }

          this.company.id = company.id;
          this.editMode = false;
          this.toastr.success('Company information saved', 'Success');
          this.loadingCompany = false;
          this.showThankyou = true;
        },
        error: (error) => {
          if (error && error.errors) {
            error.errors.forEach((e) => {
              this.validationErrors['company-' + e.param] = e.msg;
            });
          }
          this.loadingCompany = false;
        }
      });
    } else {
      this.companyProvider.update(this.company.id, formData).subscribe({
        next: (response: {data: Company}) => {
          const company: Company = response.data;
          this.editMode = false;
          this.toastr.success('Company information updated', 'Success');
          this.loadingCompany = false;
        },
        error: (error) => {
          if (error && error.errors) {
            error.errors.forEach((e) => {
              this.validationErrors['company-' + e.param] = e.msg;
            });
          }
          this.loadingCompany = false;
        }
      });
    }

  }
  getError(id) {
    return this.validationErrors[id]
  }
  logoSelected(event) {
    if (event.target.files && event.target.files[0]) {
      this.company.logoId = undefined;
      this.company.logo = {
        uuid: ''
      };
      this.logoFile = event.target.files[0];
      const reader = new FileReader();
      reader.onload = (event: any) => {
        this.logoDataUrl  = event.target.result;
      };
      reader.readAsDataURL(event.target.files[0]);
    }
  }
  userHasRole(user, roleCode) {
    if (!user || !user.roles) {
      return false;
    }
    const isSuperadmin = user.roles.filter(r => r.code === 'SUPER_ADMIN')[0];
    if (isSuperadmin) {
      return true;
    }
    return !!user.roles.filter(r => r.code === roleCode)[0];
  }
  userRoles(user) {
    if (!user || !user.roles) {
      return 'none';
    }
    return user.roles.map(r => r.name).join(', ');
  }
  toggleUserRole(user, role) {
    if (!Array.isArray(user.roles)) {
      user.roles = [role];
      return ;
    }
    let roleIndex = -1;
    user.roles.forEach((r, index) => {
      if (r.code === role.code) {
        roleIndex = index;
      }
    });
    if (roleIndex !== -1) {
      user.roles.splice(roleIndex, 1);
    } else {
      user.roles.push(role);
    }
  }
  addUser() {
    this.validationErrors = {};
    if (this.newUserRole && this.newUser.roles.indexOf(this.newUserRole) === -1) {
      this.newUser.roles.push(this.newUserRole);
    }
    if (!this.newUser.roles.length) {
      return this.toastr.error('Please give this user a role', 'Error');
    }
    this.newUser.companyId = this.company.id;
    this.loadingUser = true;
    this.userProvider.create(this.company.id, this.newUser).subscribe({
      next: (response: {data: User}) => {
        this.company.users.push(this.newUser);
        this.toastr.success('User added', 'Success');
        this.newUser = {
          firstName: '',
          lastName: '',
          cellPhone: '',
          email: '',
          companyId: this.company.id,
          roles: []
        };
        this.passwordConfirm = '';
        jQuery('.company-users input').removeClass('ng-dirty').addClass('ng-pristine');
        this.loadingUser = false;
      },
      error: (error) => {
        if (error && error.errors) {
          error.errors.forEach((e) => {
            this.validationErrors['user-' + e.param] = e.msg;
          });
        }
        this.loadingUser = false;
      }
    });
  }

  updateUser(user) {
    if (user._saving) {
      return ;
    }
    user._saving = true;
    this.validationErrors = {};
    this.userProvider.update(this.company.id, user.id, user).subscribe({
      next: (response: {data: User}) => {
        this.toastr.success('User updated', 'Success');
        jQuery('.company-users input').removeClass('ng-dirty').addClass('ng-pristine');
        user._saving = false;
        user._editing = false;
      },
      error: (error) => {
        if (error && error.errors) {
          error.errors.forEach((e) => {
            this.validationErrors['user-' + user.id + '-' + e.param] = e.msg;
          });
        }
        user._saving = false;
      }
    });
  }

  deleteUser(user) {
    if (user._deleting) {
      return ;
    }
    user._deleting = true;
    this.userProvider.delete(this.company.id, user.id).subscribe({
      next: () => {
        this.toastr.success('User deleted', 'Success');
        this.company.users.splice(this.company.users.indexOf(user), 1);
      },
      error: (error) => {
        user._deleting = false;
      }
    });
  }
}
