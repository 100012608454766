import { Component, OnInit } from '@angular/core';
import { Company } from '../../models/company';
import { Customer } from '../../models/customer';
import { CompanyProvider } from '../../providers/company.provider';
import { TankInspectionProvider } from '../../providers/tank-inspection.provider';
import { UserProvider } from '../../providers/user.provider';
import { ToastrService } from 'ngx-toastr';
import { ActivatedRoute, Router } from '@angular/router';
@Component({
  selector: 'app-company-inspection-list',
  templateUrl: './company-inspection-list.component.html',
  styleUrls: ['./company-inspection-list.component.scss'],
  providers: []
})
export class CompanyInspectionListComponent implements OnInit {
  company: Company;
  inspections: any = [];
  inspectionFilters: any;
  loading = false;
  customers: Customer[] = [];
  title: string;
  canLoadMore: boolean;
  limit = 25;
  constructor(
    private companyProvider: CompanyProvider,
    private tankInspectionProvider: TankInspectionProvider,
    private router: Router,
    private toastr: ToastrService,
    private route: ActivatedRoute
  ) {}

  ngOnInit() {
    this.route.paramMap.subscribe((params: any) => {
      const companyId = params.get('companyId');
      this.companyProvider.get(companyId).subscribe((response: {data: Company}) => {
        this.company = response.data;
        this.route.data.subscribe((data: any) => {
          this.inspectionFilters = data.inspectionFilters;
          this.title = data.title || 'Customer Inspections';
        });
        this.loadInspections();
      });
    });
  }
  loadInspections(append = true) {
    this.loading = true;
    this.tankInspectionProvider.list(this.company.id, undefined, {
      inspectionFilters: Object.assign({
            limit: this.limit,
            offset: this.inspections.length
          }, this.inspectionFilters
        )
    }).subscribe({
      next: (response: any) => {
        if (response.data && response.data) {
          if (append) {
            this.inspections = this.inspections.concat(response.data);
          } else {
            this.inspections = response.data;
          }
        }
        this.canLoadMore = response.data.length === this.limit;
        // group inspections by customer.
        const customersById = {};
        this.inspections.forEach((inspection) => {
          const customer = inspection.customer;
          if (!customer) {
            console.log('Error: Customer has been deleted');
            return ;
          }
          if (!customersById[customer.id]) {
            customersById[customer.id] = customer;
            customer.tankInspections = [inspection];
          } else {
            customersById[customer.id].tankInspections.push(inspection);
          }
        });
        this.customers = Object.keys(customersById).map(customerId => customersById[customerId]);
        this.loading = false;
      },
      error: (error) => {
        this.loading = false;
        this.toastr.error('Could not load inspections: ' + error.message, 'Error');
      }
    });
  }
  openInspection(customerId, inspectionId, tankInspection) {
    if (tankInspection.status === 'submitted') {
      this.router.navigate([`company/${this.company.id}/customer/${customerId}/inspection/${inspectionId}/certify`]);
    } else {
      this.router.navigate([`company/${this.company.id}/customer/${customerId}/inspection/${inspectionId}`]);
    }
  }
}
