import { Component, OnInit } from '@angular/core';
import { CompanyProvider } from '../../providers/company.provider';
import { CompanyInspectionStepProvider } from '../../providers/company-inspection-step.provider';
import { InspectionStepProvider } from '../../providers/inspection-step.provider';
import { InspectionStep } from '../../models/inspection-step';
import { Company } from '../../models/company';
import { environment } from '../../../environments/environment';

import { ToastrService } from 'ngx-toastr';
import { Router, ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-company-settings',
  templateUrl: './company-settings.component.html',
  styleUrls: ['./company-settings.component.scss'],
  providers: []
})
export class CompanySettingsComponent implements OnInit {
  company: Company;
  apiUrl = environment.apiUrl;
  inspectionSettings = [
    {
      inspectionStepId: 1,
      required: false,
      inspectionStep: {
        name: 'Full Tank'
      }
    },
    {
      inspectionStepId: 2,
      required: true,
      inspectionStep: {
        name: 'Bottom Tank'
      }
    }
  ];
  loadingInspectionSteps = false;
  loadingCompanyInspectionSteps = false;
  loadingCompany = true;
  inspectionSteps: InspectionStep;
  availableInspectionSteps: InspectionStep[] = [];
  companyInspectionSteps: any[] = [];
  selectedInspectionStep: InspectionStep;
  constructor(
    private companyProvider: CompanyProvider,
    private inspectionStepProvider: InspectionStepProvider,
    private companyInspectionStepProvider: CompanyInspectionStepProvider,
    private toastr: ToastrService,
    private router: Router,
    private route: ActivatedRoute
  ) {}

  ngOnInit() {
    this.route.paramMap.subscribe(params => {
      const companyId = parseInt(params.get('companyId'), 10);
      this.companyProvider.get(companyId).subscribe((response: any) => {
        this.company = response.data;
        this.loadingCompany = false;

        this.loadingCompanyInspectionSteps = true;
        this.loadInspectionSteps();
        this.companyInspectionStepProvider.list(this.company.id).subscribe({
          next: (response: any) => {
            this.loadingCompanyInspectionSteps = false;
            this.companyInspectionSteps = response.data;
          },
          error: () => {
            this.toastr.error('Could not load inspection steps', 'Error');
            this.loadingCompanyInspectionSteps = false;
          }
        })
      });
    });
  }
  selectInspectionStep(step) {
    if (!step || step === this.selectedInspectionStep) {
      this.selectedInspectionStep = undefined;
    } else {
      this.selectedInspectionStep = step;
    }
  }
  loadInspectionSteps() {
    this.loadingInspectionSteps = true;
    this.inspectionStepProvider.list(this.company.id).subscribe({
      next: (response: any) => {
        this.loadingInspectionSteps = false;
        this.availableInspectionSteps = response.data;
      },
      error: () => {
        this.toastr.error('Could not load inspection steps', 'Error');
        this.loadingInspectionSteps = false;
      }
    });
  }
  setInspectionFile(inspectionStep, file) {
    inspectionStep._saving = true;
    const companyStep: any = this.getCompanyInspectionStep(inspectionStep.id);
    if (!companyStep) {
      return this.toastr.error('Inspection step not found', 'Error');
    }
    this.companyInspectionStepProvider.update(this.company.id, companyStep.id, {
      inspectionFileId: file.id
    }).subscribe({
      next: (response: any) => {
        companyStep.inspectionFileId = file.id;
        inspectionStep._saving = false;
        inspectionStep._showFiles = false;
      },
      error: (error) => {
        inspectionStep._saving = false;
        this.toastr.error('Could not select file:' + error.message, 'Error');
      }
    });
  }
  inspectionFileSelected(inspectionStep, file) {
    const companyStep: any = this.getCompanyInspectionStep(inspectionStep.id);
    return companyStep.inspectionFileId === file.id;
  }
  toggleStepRequired(step: InspectionStep) {
    const companyStep = this.getCompanyInspectionStep(step.id);
    const newValue = !(companyStep && companyStep.required);
    step._saving = true;
    if (!companyStep) {
      // create new entry for this setting
      this.companyInspectionStepProvider.create(
        this.company.id, {
        required: newValue,
        inspectionStepId: step.id
      }).subscribe({
        next: (response: any) => {
          step._saving = false;
          this.companyInspectionSteps.push(response.data);
        },
        error: (error) => {
          step._saving = false;
          this.toastr.error('Could not save setting: ' + error.message, 'Error');
        }
      })
    } else {
      // update existing entry for this setting
      this.companyInspectionStepProvider.update(this.company.id, companyStep.id, {
        required: newValue
      }).subscribe({
        next: (response: any) => {
          step._saving = false;
          companyStep.required = newValue;
        },
        error: (error) => {
          step._saving = false;
          this.toastr.error('Could not save setting:' + error.message, 'Error');
        }
      })
    }

  }
  stepRequired(step: InspectionStep) {
    const companyStep = this.getCompanyInspectionStep(step.id);
    return companyStep && companyStep.required;
  }
  getCompanyInspectionStep(inspectionId) {
    let i;
    for (i = 0; i < this.companyInspectionSteps.length; i++) {
      if (this.companyInspectionSteps[i].inspectionStepId === inspectionId) {
        return this.companyInspectionSteps[i];
      }
    }
    return false;
  }
  getInstructionFileUuid(step) {
    const companyStep = this.getCompanyInspectionStep(step.id);
    let file;
    if (companyStep.inspectionFileId) {
      file = step.files.filter(f => f.id === companyStep.inspectionFileId)[0];
    }
    if (!file) {
      // if company has not selected a preferred image, use the first one.
      file = step.files[0] ? step.files[0]: '';
    }
    return file.uuid;
  }
  getInstructionFileUrl(step) {
    const uuid = this.getInstructionFileUuid(step);
    return this.apiUrl + 'file/' + uuid;
  }
}
