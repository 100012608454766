import { Component, OnInit } from '@angular/core';
import { TankInspectionProvider } from '../../providers/tank-inspection.provider';
import { CompanyProvider } from '../../providers/company.provider';
import { UserProvider } from '../../providers/user.provider';
import { ActivatedRoute } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { Company } from '../../models/company';
import { User } from '../../models/user';


@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss'],
  providers: []
})
export class DashboardComponent implements OnInit {
  inspectionStats: {
    openCustomers: number,
    activeCustomers: number,
    completedInspections: number,
    inspectionsNeedingAttention: number,
    inspectionsReadyToCertify: number,
    totalTimeSaved: number,
    totalMoneySaved: number,
    nrInspectionsThisMonth?: number
  };
  companyId: number;
  loading = false;
  company: Company;
  nrInspectionsAllowed: number;
  user: User;
  tiles: Array<{label: string, value: any, routerLink?: string}> = [];
  constructor(
    private route: ActivatedRoute,
    private tankInspectionProvider: TankInspectionProvider,
    private companyProvider: CompanyProvider,
    private userProvider: UserProvider,
    private toastr: ToastrService
  ) {}

  ngOnInit() {
    this.route.paramMap.subscribe(params => {
      this.companyId = parseInt(params.get('companyId'), 10);
      this.userProvider.loggedInUserObservable.subscribe((user) => {
        if (user) {
          this.user = user;
        }
      });
      this.loadCompany();
      this.loadInspectionStats();
    });
  }
  loadInspectionStats() {
    this.loading = true;
    this.tankInspectionProvider.getInspectionStats(this.companyId)
      .subscribe({
        next: (response: any) => {
          this.inspectionStats = response.data;
          this.loading = false;
          this.createTiles();
        },
        error: (error) => {
          this.loading = false;
          this.toastr.error('Could not load dashboard data: ' + error.message, 'Error');
        }
      });
  }
  loadCompany() {
    this.companyProvider.get(this.companyId).subscribe((response: any) => {
      this.company = response.data;
      this.nrInspectionsAllowed = (this.company.plan) ? this.company.plan.technicians : 0;
    });
  }
  createTiles() {
    const stats = this.inspectionStats;
    this.tiles = [
      {
        label: 'Un-Tracked Inspections',
        value: stats.openCustomers,
        routerLink: `/company/${this.companyId}/customer/untracked`
      }, {
        label: 'Waiting For More Information',
        value: stats.activeCustomers,
        routerLink: `/company/${this.companyId}/customer/attention`
      }, {
        label: 'Ready To Review',
        value: stats.inspectionsReadyToCertify,
        routerLink: `/company/${this.companyId}/inspection/ready`
      }, {
        label: 'Tracked Inspections',
        value: stats.completedInspections,
        routerLink: `/company/${this.companyId}/inspection/completed`
      },
      /*
      {label: 'Tracking Inspections'  , value: stats.activeCustomers},
      {label: 'Total time saved (mins)' , value: stats.totalTimeSaved},
      {label: 'Total money saved'       , value: stats.totalMoneySaved}
      */
    ];
    /*if (this.company.plan) {
      this.tiles.push(
        {
          label: 'Number of Inspections Per ' + this.company.plan.name + ' Plan',
          value: this.nrInspectionsAllowed + '/' + stats.nrInspectionsThisMonth
        }
      );
    }*/
  }
}
