import { Component } from '@angular/core';
import { UserProvider } from './providers/user.provider';
import {Router, NavigationEnd, ActivatedRoute} from '@angular/router';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  showLoader = true;
  topMenuEnabled = false;
  sideMenuEnabled = false;
  topMenuLinks: Array<{href?: string, label: string, classes?: string, icon?: string}> = [];
  sideMenuLinks: Array<{href?: string, label: string, classes?: string, icon?: string}> = [];
  logoutEnabled = false;
  user: any;
  adminMenuLinks = [
    {href: 'admin/dashboard', label: 'Admin Dashboard', classes: 'admin'},
    {href: 'admin/companies', label: 'Companies', classes: 'admin'},
    {href: 'admin/configuration', label: 'Configuration', classes: 'admin'},
  ];
  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private toastr: ToastrService,
    public userProvider: UserProvider
  ) {
    console.log('Tank Tracker v0.0.8');
    this.router.events.subscribe(e => {
      if(e instanceof NavigationEnd){
        this.setNavLinks();
      }
    });

    // hide all menus for customer inspection page
    this.topMenuEnabled = this.sideMenuEnabled = !window.location.pathname.match(/^\/i\//gi);

    this.userProvider.loggedInUserObservable.subscribe((user) => {
      this.logoutEnabled = !!user;
      this.user = user;
      this.setNavLinks();
    });
  }
  setNavLinks() {
    const user = this.user;
    if (user && user.roles && user.roles.length) {
      let companyId;
      if (user.roles.filter(r => r.code === 'SUPER_ADMIN').length) {
        this.topMenuLinks = this.adminMenuLinks;
        const companyUrlMatch = window.location.pathname.match(/\/*company\/([0-9]+)/);
        if (companyUrlMatch && companyUrlMatch[1]) {
          // let slick admin see the company menu as well
          companyId = companyUrlMatch[1];
        }
      } else {
        // ToDo: split technician menu from admin menu
        companyId = user.companyId;
      }
      if (companyId) {
        this.sideMenuLinks = this.getCompanyMenuLinks(user.companyId);
      }
    } else {
      this.topMenuLinks = [];
      this.sideMenuLinks = [];
    }
  }
  getCompanyMenuLinks(companyId) {
    return [
      {href: `company/${companyId}/dashboard`, label: 'Dashboard', icon: 'dashboard-icon.png'},
      {href: `company/${companyId}/settings`, label: 'Inspection Details', icon: 'inspection-details-icon.png'},
      {href: `company/${companyId}/customer`, label: 'Customers', icon: 'customers-icon.png'},
      {href: `company/${companyId}`, label: 'Company', icon: 'company-icon.png'},
    ];
  }
  ngOnInit() {
    this.showLoader = false;
  }
  logout() {
    // navigate to login page before logging out fn to avoid auth guard errors.
    this.router.navigate(['/login']);
    setTimeout(() => {
      this.userProvider.loggedInUserSubject.next(undefined);
    }, 500);
    this.toastr.success('You have logged out', 'Success');
    // this.userProvider.loggedInUser = undefined;
    localStorage.removeItem('jwt');
    localStorage.removeItem('user');
    localStorage.removeItem('companyId');
  }

}
