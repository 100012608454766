import { Injectable } from '@angular/core';
import { APIProvider } from './api.provider';

@Injectable()
export class RoleProvider {

  constructor(private api: APIProvider) {

  }

  get() {
    return this.api.get('role');
  }
}
