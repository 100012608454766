<section class="companies">
  <h1>Companies</h1>
  <table class="table">
    <thead class="thead-dark">
    <tr>
      <th *ngIf="showNameFilter">
        <label for="company-name-keyword">Name</label>
        <input
          class="form-control"
          id="company-name-keyword"
          placeholder="filter by name"
          [(ngModel)]="companyNameFilter"
          (ngModelChange)="searchByName()"
          (blur)="onFilterBlur()"
          ngModel
        >
      </th>
      <th *ngIf="!showNameFilter" (click)="showNameFilter = true;" style="cursor: pointer">Name</th>

      <th style="cursor: pointer" *ngIf="!showStatusFilter" (click)="showStatusFilter = true;">Status</th>
      <th *ngIf="showStatusFilter">
        <label for="company-status">Status</label>
        <select
          class="custom-select"
          id="company-status"
          name="company-status"
          [(ngModel)]="companyStatusFilter"
          (ngModelChange)="loadCompanies()"
          (blur)="onFilterBlur()"
        >
          <option
            *ngFor="let filter of statusFilters"
            value="{{filter.value}}">
            {{filter.label}}
          </option>
        </select>
      </th>
      <th>Plan</th>
      <th>Actions</th>
    </tr>
    </thead>
    <tr *ngFor="let company of companies">
      <td>{{company.name}}</td>
      <td>{{company.status}}</td>
      <td>{{company.plan ? company.plan.name : '-'}}</td>
      <td *ngIf="!company._confirmReject && !company._settingPlan && !company._confirmDelete">
        <button class="btn btn-slick small" (click)="approve(company)" *ngIf="company.status !== 'approved'">
          approve
          <img *ngIf="company._approving" src="assets/img/loader.gif" class='loader' alt="loading...">
        </button>
        <button
          class="btn btn-slick small warning"
          (click)="company._confirmReject = true"
          *ngIf="company.status === 'approved' || company.status === 'pending'"
        >
          reject
        </button>
        <button class="btn btn-slick small warning" (click)="company._confirmDelete = true">
          delete
        </button>
        <button class="btn btn-slick small" (click)="company._settingPlan = true">
          {{company.planId ? 'change': 'set'}} plan
        </button>
        <button class="btn btn-slick small light" routerLink="/company/{{company.id}}/dashboard">
          dashboard
        </button>
        <button class="btn btn-slick small light" routerLink="/company/{{company.id}}/settings">
          settings
        </button>
        <button class="btn btn-slick small light" routerLink="/company/{{company.id}}">
          info
        </button>
      </td>
      <td *ngIf="company._settingPlan">
        <div class="form-group">
          <label for="planId">Select Plan</label>
          <select class="custom-select plan" id="planId" name="planId" [(ngModel)]="company.planId">
            <option selected value="null">No Plan</option>
            <option *ngFor="let plan of plans" [ngValue]="plan.id">
              {{plan.name}}
            </option>
          </select>
          <button class="btn btn-slick small" (click)="setPlan(company)">
            save
            <img *ngIf="company._saving" src="assets/img/loader.gif" class='loader' alt="loading...">
          </button>
        </div>
      </td>
      <td *ngIf="company._confirmReject">
        <label for="reject-reason">Reject reason</label>
        <textarea
          style="margin: 0.4em; min-height: 4em;"
          class="form-control"
          name="reject-reason"
          id="reject-reason"
          [(ngModel)]="company.rejectReason"
          placeholder="reason for rejecting - this is included in email notification to the customer"
          ngModel
        >
        </textarea>
        <button class="btn btn-slick small warning float-right" (click)="reject(company)">
          confirm reject
          <img *ngIf="company._rejecting" src="assets/img/loader.gif" class='loader' alt="loading...">
        </button>
      </td>
      <td *ngIf="company._confirmDelete">
        <span>
          Are you sure you want to delete this company?
        </span>
        <button class="btn btn-slick small light" (click)="company._confirmDelete = false">
          cancel
        </button>
        <button class="btn btn-slick small warning" (click)="deleteCompany(company)">
          delete
          <img *ngIf="company._deleting" src="assets/img/loader.gif" class='loader' alt="loading...">
        </button>
      </td>
    </tr>
  </table>

  <img *ngIf="loadingCompanies"  class="loader" src="assets/img/loader.gif" alt="loading...">

</section>
