import { Component, ViewChild, ViewEncapsulation } from '@angular/core';
import { NgForm } from '@angular/forms';
import { Customer } from '../../models/customer';
import { CustomerProvider } from '../../providers/customer.provider';
import { CompanyProvider } from '../../providers/company.provider';
import { UserProvider } from '../../providers/user.provider';
import { ToastrService } from 'ngx-toastr';
import { ActivatedRoute } from '@angular/router';
import * as moment from 'moment';

@Component({
  selector: 'app-inspection-add',
  templateUrl: './inspection-add.component.html',
  styleUrls: ['./inspection-add.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class InspectionAddComponent {
  @ViewChild('form') public form: NgForm;
  showLoader = true;
  inputType = 'newCustomer';
  selectedStaff: any = {
    firstName: '',
    lastName: '',
    email: ''
  };
  customer: Customer = Customer();
  customers: Customer[] = []; // imported customers
  companyId: number;
  validationErrors: any = {};
  savingCustomer = false;
  assigningStaff = false;
  editCustomer = true;
  inspectionStep = 'customer';// customer | staff | confirm
  startingInspection = false;
  inspectionStarted = false;
  staffInspectionStartDate: any;
  staffInspectionEndDate: any;
  staffInspectionStartTime: any;
  staffInspectionEndTime: any;
  upgradingPlan = false;
  showStaffSearch = false;
  showUpgradePlan = false;
  uploadingCSV = false;
  upgradeRequestSent = false;
  loadingCustomer = false;
  externalInfo: {
    customerId: number;
    tankId: number;
    tankSize?: number;
    location?: string;
    description?: string;
    inspectionId?: number;
  };
  constructor(
    private customerProvider: CustomerProvider,
    private companyProvider: CompanyProvider,
    private userProvider: UserProvider,
    private toastr: ToastrService,
    private route: ActivatedRoute
    ) {
  }
  ngOnInit() {
    this.showLoader = false;
    this.route.paramMap.subscribe(params => {
      this.companyId = parseInt(params.get('companyId'), 10);
      const customerId = parseInt(this.route.snapshot.queryParamMap.get('cid'), 10);
      if (customerId > 0) {
        this.loadingCustomer = true;
        this.customerProvider.get(this.companyId, customerId).subscribe({
          next: (response: {data: Customer}) => {
            this.customer = response.data;
            const params = this.route.snapshot.queryParamMap;
            this.externalInfo = {
              /*
              * @Param {int} ext_cid - customer id on external(Current) site
              * */
              customerId: parseInt(params.get('ext_cid'), 10),
              tankId: parseInt(params.get('tid'), 10),
              tankSize: parseInt(params.get('size'), 10),
              location: params.get('location'),
              description: params.get('description'),
              inspectionId: parseInt(params.get('iid'), 10),
            }
            console.log('external info:', this.externalInfo);
          },
          error: (error) => {
            this.toastr.error('Could not load customer info: ' + error.message, 'Error');
          },
          complete: () => {
            this.loadingCustomer = false;
          }
        })
      }
    });
  }
  setInputType(type) {
    if (type === 'newCustomer') {
      this.inputType = 'newCustomer';
      this.editCustomer = true;
      this.customer = Customer();
    } else {
      this.inputType = type;
      this.editCustomer = false;
    }
  }
  searchCustomers(keyword) {
    return this.customerProvider.list(this.companyId, {
      keyword: keyword,
      limit: 50
    });
  }
  getCustomerOptionLabel(option) {
    if (!option) {
      return 'no customer selected';
    }
    return `${option.firstName} ${option.lastName}, ${option.state}, ${option.streetAddress}`;
  }
  getStaffOptionLabel(option) {
    if (!option) {
      return 'no staff selected';
    }
    return option.firstName + ' ' +option.lastName + ', ' + option.email;
  }
  searchStaff(keyword) {
    return this.userProvider.list(this.companyId, {
      keyword,
      limit: 50
    });
  }
  onCustomerSelect(customer: Customer) {
    this.customer = customer;
    this.customer.staffId = null;
  }
  onStaffSelect(staff) {
    this.selectedStaff = staff;
  }

  assignStaff() {
    // staff is optional. if none is selected,
    // move on to next inspection step
    if (this.customers.length) {
      // clear staffId from unselected customers
      const notSelectedIds: any = this.customers.filter(c => !c._selected).map(c => c.id);
      if (notSelectedIds.length) {
        this.customerProvider.assignStaff(
          this.companyId,
          notSelectedIds,
          null,
          {
            staffInspectionStartDate: this.getInspectionStartDateTime(),
            staffInspectionEndDate: this.getInspectionEndDateTime()
          }
        ).subscribe({
          next: () => {
            console.log('Staff cleared from not selected customers', notSelectedIds);
          }
        });
      }
    }


    this.inspectionStarted = false;
    if (this.selectedStaff && this.selectedStaff.id) {
      let customerId: any = this.customer.id;
      if (this.customers.length) {
        customerId = this.customers.filter(c => c._selected).map(c => c.id);
      }
      this.customerProvider.assignStaff(
        this.companyId,
        customerId,
        this.selectedStaff.id,
        {
          staffInspectionStartDate: this.getInspectionStartDateTime(),
          staffInspectionEndDate: this.getInspectionEndDateTime()
        }
      ).subscribe({
        next: (response: any) => {
          const successMsg = this.selectedStaff.firstName + ' ' + this.selectedStaff.lastName + ' has been assigned to customer';
          this.toastr.success(successMsg, 'Success');
          this.assigningStaff = false;
          this.inspectionStep = 'confirm';
        },
        error: (error) => {
          this.assigningStaff = false;
        }
      });
    } else {
      this.inspectionStep = 'confirm';
    }
  }
  saveCustomer() {
    // if customer was uploaded using csv then they are already saved.
    if (this.customers.length) {
      this.inspectionStep = 'staff';
      return ;
    }
    if (this.customer.id) {
      //edit existing customer
      this.updateCustomer();
    } else {
      this.createCustomer();
    }
  }
  createCustomer() {
    this.validationErrors = {};
    this.customer.companyId = this.companyId;
    this.savingCustomer = true;
    this.customerProvider.create(this.companyId, this.customer).subscribe({
      next: (response: any) => {
        this.toastr.success('Customer information saved', 'Success');
        this.customer = response.data;
        this.savingCustomer = false;
        this.editCustomer = false;
        this.inspectionStep = 'staff';
      },
      error: (error) => {
        console.log('Error creating customer', error);
        this.savingCustomer = false;
        if (error && error.errors) {
          error.errors.forEach((e) => {
            this.validationErrors['customer-' + e.param] = e.msg;
          });
        }
      }
    });
  }
  updateCustomer() {
    this.savingCustomer = true;
    console.log('UpdatingCustomer at inspection add');
    this.customerProvider.update(this.customer.companyId, this.customer.id, this.customer).subscribe({
      next: (response: any) => {
        this.toastr.success('Customer information updated', 'Success');
        this.savingCustomer = false;
        this.editCustomer = false;
        this.inspectionStep = 'staff';
      },
      error: (error) => {
        console.log('Error updating customers', error);
        this.savingCustomer = false;
        if (error && error.errors) {
          error.errors.forEach((e) => {
            this.validationErrors['customer-' + e.param] = e.msg;
          });
        }
      }
    });
  }

  resetCustomer() {
    this.customer = Customer();
  }
  resetStaff() {
    if (this.customer) {
      this.customer.staffId = null;
    }
    this.customers.forEach((customer) => {
      customer.staffId = null;
    });
    this.selectedStaff = {
      firstName: '',
      lastName: '',
      email: ''
    }
  }
  startAnotherInspection() {
    this.customer = Customer();
    this.resetStaff();
    this.inspectionStep = 'customer';
    this.setInputType('newCustomer');
  }
  startInspection() {
    this.companyProvider.getNrRemainingInspections(this.companyId).subscribe((response: any) => {
      if (!response.data || response.data < 1 || (response.data < this.customers.length) ) {
        // Monthly limit exceeded
        this.toastr.error('You have reached your monthly inspection limit', 'Error');
        this.showUpgradePlan = true;
      } else {
        // Monthly limit not exceeded
        if (this.startingInspection) {
          return ;
        }
        this.startingInspection = true;
        const customerId: any = this.customers.length ? this.customers.filter(c => c._selected).map(c => c.id): this.customer.id;
        this.customerProvider.startInspection(this.companyId, customerId, this.externalInfo).subscribe({
          next: (response: any) => {
            this.startingInspection = false;
            if (response.data && response.data.length) {
              this.inspectionStarted = true;
              this.toastr.success('Your inspection has been started.', 'Success');
            } else {
              this.toastr.error('Could not generate customer access token', 'Error');
            }
          },
          error: () => {
            this.startingInspection = false;
          }
        });
      }
    });
  }
  upgradePlan() {
    this.upgradingPlan = true;
    this.companyProvider.upgradePlan(this.companyId).subscribe({
      next: (response: any) => {
        this.upgradingPlan = false;
        this.upgradeRequestSent = true;
      },
      error: () => {
        this.upgradingPlan = false;
      }
    });
  }
  customerCSVSelected(event) {
    if (event.target.files && event.target.files[0]) {
      this.uploadingCSV = true;
      const formData = new FormData();
      formData.set('customers', event.target.files[0]);
      this.customerProvider.import(this.companyId, formData).subscribe({
        next: (response: any) => {
          this.uploadingCSV = false;
          this.customers = response.data || [];
          this.customers.forEach((customer) => {
            customer._selected = true;
          });
        },
        error: (error) => {
          this.uploadingCSV = false;
        }
      });
    }
  }
  toggleCustomerSelection() {
    if (!this.customers.length) {
      return;
    }
    const newValue = !this.customers[0]._selected;
    this.customers.forEach((customer) => {
      customer._selected = newValue;
    });
  }
  get customerSelectionDefined() {
    if (this.customer && this.customer.id || (this.customer.cellPhone && this.inputType === 'newCustomer')) {
      return true;
    }
    let i;
    for (i = 0; i < this.customers.length; i++) {
      if (this.customers[i]._selected) {
        return true;
      }
    }
    return false;
  }
  getInspectionEndDateTime() {
    const endDate = this.staffInspectionEndDate ? this.staffInspectionEndDate : this.staffInspectionStartDate;
    const endTime = this.staffInspectionEndTime ? this.staffInspectionEndTime : this.staffInspectionStartTime;
    if (!endDate || !endTime) {
      return '';
    }
    const m = moment.utc();
    m.set('year', endDate.year);
    m.set('month', endDate.month - 1);
    m.set('date', endDate.day);

    m.set('hour', endTime.hour);
    m.set('minute', endTime.minute);
    m.set('second', 0);
    return m.toISOString();
  }
  getInspectionStartDateTime() {
    if (!this.staffInspectionStartDate || !this.staffInspectionStartTime) {
      return '';
    }
    const m = moment.utc();
    m.set('year', this.staffInspectionStartDate.year);
    m.set('month', this.staffInspectionStartDate.month - 1);
    m.set('date', this.staffInspectionStartDate.day);

    m.set('hour', this.staffInspectionStartTime.hour);
    m.set('minute', this.staffInspectionStartTime.minute);
    m.set('second', 0);
    return m.toISOString();
  }
}
