import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { InspectionStepProvider } from '../../providers/inspection-step.provider';
import { StateListProvider } from '../../providers/state-list.provider';
import { InspectionStep } from '../../models/inspection-step';
import { Question } from '../../models/question';

@Component({
  selector: 'app-admin-inspection-question',
  templateUrl: './inspection-question.component.html',
  styleUrls: ['./inspection-question.component.scss'],
  providers: []
})
export class AdminInspectionQuestionComponent implements OnInit {
  @Input() inspectionStep: InspectionStep;
  @Output() onClose = new EventEmitter<any>();
  savingQuestions = false;
  inspectionStepQuestion: Question = Question();
  answerTypes = [
    {label: 'Yes/No(checkbox)', value: 'bool'},
    {label: 'Short text', value: 'string'},
    {label: 'Long text(text area)', value: 'text'},
  ];
  stateFilter: Array<{state: string; checked: boolean}> = [];
  constructor(
    private toastr: ToastrService,
    private inspectionStepProvider: InspectionStepProvider,
    public stateListProvider: StateListProvider
  ) {
  }

  ngOnInit() {
    this.stateFilter = this.stateListProvider.states.map((state: string) => {
      return {state, checked: false};
    });
  }

  getQuestionPayload(question) {
    const filters: {states?: string[]} = {};
    const selectedStates: string[] = this.stateFilter.filter(filter => filter.checked).map(filter => filter.state);
    if (selectedStates.length) {
      filters.states = selectedStates;
    }
    if (!question.answeredByCustomer && !question.answeredByCompany) {
      alert('Can the question be answered by customer, company or both?');
      return;
    }
    return {
      inspectionStepId: this.inspectionStep.id,
      parentQuestionId: question.parentQuestionId,
      text: question.text,
      label: question.label,
      answerType: question.answerType,
      optional: question.optional,
      filters,
      answeredByCustomer: question.answeredByCustomer ? 1: 0,
      answeredByCompany: question.answeredByCompany ? 1: 0,
      parentAnswer: question.parentAnswer ? 1: 0,
    }
  }
  saveQuestion(step) {
    this.savingQuestions = true;
    let saveFn;

    const parentId = this.inspectionStepQuestion.parentQuestion ? this.inspectionStepQuestion.parentQuestion.id: null;
    this.inspectionStepQuestion.parentQuestionId = parentId;

    if (this.inspectionStepQuestion.id) {
      saveFn = this.inspectionStepProvider.updateQuestion(
        step.id,
        this.inspectionStepQuestion.id,
        this.getQuestionPayload(this.inspectionStepQuestion)
      );
    } else {
      saveFn = this.inspectionStepProvider.addQuestion(
        step.id,
        this.getQuestionPayload(this.inspectionStepQuestion)
      );
    }
    saveFn.subscribe({
      next: (response: any) => {
        if (response && response.data && !this.inspectionStepQuestion.id) {
          if (!step.questions) {
            step.questions = [];
          }
          step.questions.push(response.data);
          if (parentId) {
            const parentQuestion: any = this.inspectionStep.questions.filter(q => q.id === parentId)[0];
            if (!parentQuestion.subQuestions) {
              parentQuestion.subQuestions = [];
            }
            parentQuestion.subQuestions.push(response.data);
          }
        }
        this.toastr.success('Question saved successfully', 'Success');
        this.savingQuestions = false;
      },
      error: (error) => {
        console.log('Saving Question Error:', error);
        this.toastr.error('Could not save question', 'Error');
        this.savingQuestions = false;
      }
    });
  }
  deleteQuestion(step, question, parentQuestion?: any) {
    if (!confirm('Are you sure you want to delete this question?')) {
      return;
    }
    step._deletingQuestion = true;
    this.inspectionStepProvider.deleteQuestion(
      step.id,
      question.id
    ).subscribe({
      next: (response: any) => {
        const questionIndex = step.questions.indexOf(question);
        if (questionIndex !== -1) {
          step.questions.splice(questionIndex, 1);
        }
        if (question.parentQuestionId && parentQuestion) {
          const index = parentQuestion.subQuestions.indexOf(question);
          if (index !== -1) {
            parentQuestion.subQuestions.splice(index, 1);
          }
        }
        this.toastr.success('Question deleted successfully', 'Success');
        step._deletingQuestion = false;
      },
      error: (error) => {
        this.toastr.error('Could not delete question:' + error.message, 'Error');
        step._deletingQuestion = false;
      }
    });
  }
  startEditingQuestion(question) {
    // clear and replace state filters.
    this.stateFilter.forEach((filter) => {
      filter.checked = false;
    });
    if (question.filters) {
      if (Array.isArray(question.filters.states)) {
        const selectedStates = question.filters.states.join(',');
        this.stateFilter.forEach((filter) => {
          filter.checked = !!selectedStates.match(new RegExp(filter.state, 'i'));
        });
      }
    }
    if (question.parentQuestionId && !question.parentQuestion) {
      question.parentQuestion = this.inspectionStep.questions.filter(q => q.id === question.parentQuestionId)[0];
    }
    this.inspectionStepQuestion = question;
    if (window['jQuery']) {
      setTimeout(() => {
        window['jQuery']('html, body').animate({
          scrollTop: window['jQuery']("#edit-question-section").offset().top - 80
        }, 300);
      }, 32);
    }
  }
}
