<section class="settings-list admin">
  <h3>Settings</h3>
  <img *ngIf="loading" src="assets/img/loader.gif" class='loader' alt="loading...">
  <div class="setting row" *ngFor="let setting of settings; index as i">
    <div class="col-7">
      <label for="setting-{{i}}">{{setting.label}}</label>
    </div>
    <div class="col-3">
      <input id="setting-{{i}}" class="form-control" [(ngModel)]="setting.value" ngModel>
    </div>
    <div class="col-2">
      <button class="btn btn-slick small" (click)="saveSetting(setting)">
        Save
        <img *ngIf="setting._saving" src="assets/img/loader.gif" class='loader' alt="loading...">
      </button>
    </div>
  </div>
</section>
