import { Component, OnInit } from '@angular/core';
import { Company } from '../../models/company';
import { Customer } from '../../models/customer';
import { TankInspectionProvider } from '../../providers/tank-inspection.provider';
import { CustomerProvider } from '../../providers/customer.provider';
import { ToastrService } from 'ngx-toastr';
import { ActivatedRoute, Router } from '@angular/router';
import * as moment from 'moment';

@Component({
  selector: 'app-company-customer-list',
  templateUrl: './company-customer-list.component.html',
  styleUrls: ['./company-customer-list.component.scss'],
  providers: []
})
export class CompanyCustomerListComponent implements OnInit {
  company: Company;
  customers: Customer[] = [];
  customerFilters: any;
  loading = false;
  title: string;
  description: string;
  companyId: number;
  limit = 25;
  canLoadMore: boolean;
  validationErrors = {};
  updatingCustomer: boolean;
  moment: any;
  now = moment();
  keyword: string = '';
  keywordTimeout: any;
  hideColumns: any = {};
  hiddenColumns = 0;
  constructor(
    private tankInspectionProvider: TankInspectionProvider,
    private customerProvider: CustomerProvider,
    private router: Router,
    private toastr: ToastrService,
    private route: ActivatedRoute
  ) {
    this.moment = moment;
  }

  ngOnInit() {
    this.route.data.subscribe((data: any) => {
      this.customerFilters = data.customerFilters || {};
      this.title = data.title || 'Customer List';
      this.description = data.description;
      if (data.hideColumns) {
        this.hideColumns = data.hideColumns;
        this.hiddenColumns = Object.keys(data.hideColumns).length
      }
      this.route.paramMap.subscribe((params: any) => {
        this.companyId = parseInt(params.get('companyId'), 10);
        this.loadCustomers();
      });
    });
  }
  loadCustomers(append = true) {
    this.loading = true;
    if (!append) {
      this.customers = [];
    }
    return this.customerProvider.list(
      this.companyId,
      Object.assign({},
        this.customerFilters,
        {
          limit: this.limit,
          offset: this.customers.length,
          keyword: this.keyword.trim()
        })
    ).subscribe({
      next: (response: any) => {
        if (!append) {
          this.customers = response.data;
        } else {
          this.customers = this.customers.concat(response.data);
        }
        this.canLoadMore = response.data.length === this.limit;
        this.loading = false;
      },
      error: (error: any) => {
        this.loading = false;
      }
    });
  }
  updateCustomer(customer) {
    this.validationErrors = {};
    this.updatingCustomer = true;
    console.log('UpdatingCustomer at customer list');
    this.customerProvider.update(customer.companyId, customer.id, customer).subscribe({
      next: (response: any) => {
        this.toastr.success('Customer information updated', 'Success');
        this.updatingCustomer = false;
        customer._editing = false;
      },
      error: (error) => {
        this.updatingCustomer = false;
        if (error && error.errors) {
          error.errors.forEach((e) => {
            this.validationErrors['customer-' + e.param] = e.msg;
          });
        }
      }
    });
  }
  loadCustomerInspections(customer, filters, onSuccess?) {
    if (filters.status === 'completed') {
      customer._loadingInspectionsCompleted = true;
    } else {
      customer._loadingInspectionsInProgress = true;
    }
    this.tankInspectionProvider.list(customer.companyId, customer.id, {inspectionFilters: filters}).subscribe({
      next: (response: any) => {
        if (filters.status === 'completed') {
          customer._tankInspectionsCompleted = response.data;
          customer._loadingInspectionsCompleted = false;
        } else {
          customer._tankInspectionsInProgress = response.data;
          customer._loadingInspectionsInProgress = false;
        }
        if (onSuccess) {
          onSuccess(response.data);
        }
      },
      error: (error) => {
        this.toastr.error('Could not load customer inspections: ' + error.message, 'Error');
      }
    });
  }
  reviewTankInspection(customer) {
    // open the first tank inspection that is in progress.
    this.loadCustomerInspections(customer, {status: ['pending', 'inprogress', 'submitted']}, (inspections) => {
      if (inspections && inspections[0]) {
        this.router.navigate([`/company/${customer.companyId}/customer/${customer.id}/inspection/${inspections[0].id}/certify`]);
      }
    });
  }
  resendInspectionRequest(customer) {
    if (customer._sendingRequest) {
      return ;
    }
    customer._sendingRequest = true;
    this.customerProvider.resendInspectionRequest(customer.companyId, customer.id).subscribe({
      next: () => {
        this.toastr.success('Inspection request sent', 'Success');
        customer._sendingRequest = false;
      },
      error: () => {
        customer._sendingRequest = false;
      }
    });
  }
  deleteCustomer(customer) {
    if (customer._deleting) {
      return ;
    }
    customer._deleting = true;
    this.customerProvider.delete(customer.companyId, customer.id).subscribe({
      next: () => {
        this.toastr.success('Customer has been deleted', 'Success');
        customer._deleting = false;
        const index = this.customers.indexOf(customer);
        if (index !== -1) {
          this.customers.splice(index, 1);
        }
      },
      error: () => {
        customer._deleting = false;
      }
    });
  }
  onKeywordChange() {
    if (this.keywordTimeout) {
      clearTimeout(this.keywordTimeout);
    }
    this.keywordTimeout = setTimeout(() => {
      this.loadCustomers(false);
    }, 1000);
  }
  daysSinceLogin(customer: Customer) {
    const lastLogin: any = moment(customer.lastLogin);
    const days: any = moment.duration((Date.now() - lastLogin._d)).asDays();
    return Math.round(days);
  }
}
