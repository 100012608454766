import { Component } from '@angular/core';
import { CustomerInspectionStep } from '../../models/customer-inspection-step';
import { TankInspectionProvider } from '../../providers/tank-inspection.provider';
import { CustomerInspectionStepProvider } from '../../providers/customer-inspection-step.provider';
import { CustomerProvider } from '../../providers/customer.provider';
import { ToastrService } from 'ngx-toastr';
import { ActivatedRoute, Router } from '@angular/router';
import { ComponentCanDeactivate } from '../../pending-changes.guard';
import { HostListener } from '@angular/core';
import { Observable } from 'rxjs';
import { InspectionDetailComponent } from '../inspection-detail/inspection-detail.component';

@Component({
  selector: 'app-inspection-certify',
  templateUrl: './inspection-certify.component.html',
  styleUrls: [
    '../inspection-detail/inspection-detail.component.scss',
    './inspection-certify.component.scss'
  ],
  providers: []
})
export class InspectionCertifyComponent extends InspectionDetailComponent implements ComponentCanDeactivate {
  loading = false;
  submittingTankInspection = false;
  signingTankInspection = false;
  rejectTank = false;
  rejectingTank = false;
  constructor(
    tankInspectionProvider: TankInspectionProvider,
    public customerInspectionStepProvider: CustomerInspectionStepProvider,
    customerProvider: CustomerProvider,
    toastr: ToastrService,
    route: ActivatedRoute,
    router: Router
  ) {
    super(tankInspectionProvider, customerInspectionStepProvider, customerProvider, toastr, route, router);
  }


  @HostListener('window:beforeunload')
  canDeactivate(): Observable<boolean> | boolean {
    if (!this.tankInspection || !this.tankInspection.customerInspectionSteps || this.signingTankInspection) {
      return true;
    }
    let canDeactivate = true;
    this.tankInspection.customerInspectionSteps.forEach((step: CustomerInspectionStep) => {
      step.files.forEach((file) => {
        if (file._unsaved) {
          canDeactivate = false;
        }
      });
    });
    return canDeactivate;
  }

  get canSubmitTankInspection() {
    // tank inspection can be submitted if all inspection files have:
    // 1) been accepted or rejected already
    // 2) are in an unsaved state
    if (!this.tankInspection || !this.tankInspection.customerInspectionSteps.length) {
      return false;
    }
    let canSubmit = true;
    this.tankInspection.customerInspectionSteps.forEach((step: CustomerInspectionStep) => {
      step.files.forEach((file) => {
        if (!file._unsaved && file.status !== 'rejected' && file.status !== 'accepted') {
          canSubmit = false;
        }
      });
    });
    return canSubmit;
  }
  generateChecklist() {
    if (!this.checklistAvailable) {
      return alert('Checklist is not available');
    }
    this.tankInspectionProvider.generateChecklist(
      this.customer.companyId,
      this.customer.id,
      this.tankInspection.id
    ).subscribe({
      next: (response: any) => {
        this.checklistUuid = response.checklistUuid;
      },
      error: (error: any) => {
        console.log('error::', error);
      }
    });
  }
  beginSigningChecklist() {
    if (this.signingTankInspection) {
      return ;
    }
    this.signingTankInspection = true;
    this.tankInspectionProvider.beginSigningChecklist(
      this.customer.companyId,
      this.customer.id,
      this.tankInspection.id
    ).subscribe({
      next: (response: any) => {
        if (response && response.redirectUrl) {
          window.open(response.redirectUrl, '_blank')
        }
        setTimeout(() => {
          this.router.navigate([`/company/${this.customer.companyId}/dashboard`]);
        }, 2000);
        this.signingTankInspection = false;
      },
      error: (error: any) => {
        console.log('error::', error);
        this.signingTankInspection = false;
      }
    });
  }
  rejectTankInspection() {
    if (this.rejectingTank) {
      return ;
    }
    this.rejectingTank = true;

    this.submitTankInspection(true, () => {
      this.tankInspectionProvider.rejectTank(
        this.customer.companyId,
        this.customer.id,
        this.tankInspection.id,
        {statusNotes: this.tankInspection.statusNotes}
      ).subscribe({
        next: (response: any) => {
          this.toastr.success('Tank has been rejected', 'Success');
          this.tankInspection.status = 'rejected';
          this.rejectingTank = false;
          this.rejectTank = false;
        },
        error: (error) => {
          console.log('Tank inspection reject error:', error);
          this.rejectingTank = false;
        },
      });
    });
  }
  submitTankInspection(tankRejected, onSuccess) {
    if (this.submittingTankInspection) {
      return ;
    }
    if (!this.canSubmitTankInspection) {
      this.rejectingTank = false;
      return this.toastr.error('Please accept or reject all customer files before submitting', 'Error');
    }
    const payload: any = {
      inspectionSteps: []
    };
    let unsavedFiles = [];
    this.tankInspection.customerInspectionSteps.forEach((inspectionStep: CustomerInspectionStep) => {
      if (!inspectionStep._unsaved) {
        return ;
      }
      unsavedFiles = inspectionStep.files.filter(f => f._unsaved);
      if (!unsavedFiles.length && !inspectionStep.rejectNote && inspectionStep.rejectNote !== null) {
        // allow sending null to remove rejection
        return ;
      }
      const stepPayload = {id: inspectionStep.id, files: [], rejectNote: inspectionStep.rejectNote};
      unsavedFiles.forEach((file) => {
        stepPayload.files.push({id: file.id, status: file.status, statusNotes: file.statusNotes});
      });
      payload.inspectionSteps.push(stepPayload);
      payload.tankRejected = tankRejected;
    });

    this.submittingTankInspection = true;
    this.tankInspectionProvider.updateInspectionStepStatus(
      this.customer.companyId,
      this.customer.id,
      this.tankInspection.id,
      payload
    ).subscribe({
      next: (response: any) => {
        unsavedFiles.forEach((file) => {
          delete file._unsaved;
        });
        this.tankInspection.status = response.data.status;

        if (this.checklistAvailable && !this.checklistUuid) {
          // final step - generate a checklist and sign it digitally.
          setTimeout(() => {
            this.generateChecklist();
          }, 500);
        } else if (!this.checklistUuid) {
          // all done, redirect back
          setTimeout(() => {
            this.router.navigate([`/company/${this.customer.companyId}/dashboard`]);
          }, 2000);
        }

        this.submittingTankInspection = false;
        if (typeof onSuccess === 'function') {
          onSuccess();
        }
        if (!tankRejected) {
          if (response.data.status === 'completed') {
            this.toastr.success('Tank inspection has been completed', 'Success');
          } else if (response.data.status === 'rejected') {
            this.toastr.success('Tank inspection has been rejected', 'Success');
          } else {
            this.toastr.success('Tank inspection updated', 'Success');
          }
        }
      },
      error: (error) => {
        console.log('Tank inspection update error:', error);
        this.submittingTankInspection = false;
        this.rejectingTank = false;
      },
    });
  }
  onReject(customerStep, file) {
    // rejecting 1 file out of many will reject the whole inspection step
    if (!file && customerStep.files) {
      file = customerStep.files[0];
    }
    customerStep._rejecting = false;
    if (file) {
      file.status = 'rejected';
      customerStep.status = 'rejected';
      file._unsaved = true;
      customerStep._unsaved = true;
    } else if (customerStep.customerNote) {
      // customer could not take an image
      customerStep.status = 'rejected';
      customerStep._unsaved = true;
    }
    if (file && this.fullScreenFile === file) {
      this.nextFullScreenFile();
    }
  }
  onAccept(customerStep, file) {
    // accepting all customer files will accept the inspection step.
    if (!file && customerStep.files) {
      file = customerStep.files[0];
    }
    customerStep._rejecting = false;
    if (file) {
      file.status = 'accepted';
      file._unsaved = true;
      customerStep._unsaved = true;
      if (customerStep.files.every(f => f.status === 'accepted')) {
        customerStep.status = 'completed';
      }
    } else if (customerStep.customerNote) {
      // customer could not take an image
      customerStep._unsaved = true;
      customerStep.status = 'completed';
      customerStep.rejectNote = null;
    }
    if (file && this.fullScreenFile === file) {
      this.nextFullScreenFile();
    }
  }

}
