<section class="certificate">
  <div class="left-col">
    <img src="assets/img/token.png" class="token" alt="token">
  </div>
  <div class="right-col">
    <h1 class="tracked-token-header">
      TRACKED TOKEN
    </h1>
    <hr>
    <div class="tracked-token-text">
      This token acknowledges that the heating oil tank located at: {{customer.streetAddress}}
      has completed the Tank Tracker process and has been deemed acceptable for delivery.
    </div>
    <div class="tracked-token-inspector">
      <div>Inspection Performed By: {{customer.firstName}} {{customer.lastName}}</div>
      <div>Date of Inspection: {{dateOfInspection}}</div>
      <div>Tank Size (Gal): {{tankInspection.tankSize.value}}</div>
      <div>Tank Age (Years): {{tankInspection.tankAge}}</div>
    </div>
  </div>

  <section class="images" *ngIf="tankInspection">
    <div>
      <div class="bg-cover" [ngStyle]="{'background-image': getCertificateImageBgUrl(0)}"></div>
      <div class="name">Tank Bottom</div>
    </div>
    <div>
      <div class="bg-cover" [ngStyle]="{'background-image': getCertificateImageBgUrl(1)}"></div>
      <div class="name">Tank Top</div>
    </div>
  </section>
  <footer>
    <span style="position: relative; top: 3.6em; left: 2em;">
      *Please see our full terms and conditions regarding the Tracked Token by Tank Tracker.
    </span>
    <img class="float-right" src="assets/img/tank-tracker-logo.png" alt="Tank Tracker">
  </footer>
</section>
