import { Component, OnInit } from '@angular/core';
import { NotificationTemplateProvider } from '../providers/notification-template.provider';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-admin-notification-templates',
  templateUrl: './notification-templates.component.html',
  styleUrls: ['./notification-templates.component.scss'],
  providers: []
})
export class NotificationTemplatesComponent implements OnInit {
  loading = false;
  templates: Array<{
    id: number,
    name: string,
    label: string,
    email: string,
    subject: string,
    sms: string,
    tags: string[]
  }> = [];
  testEmail: string;
  testPhone: string;
  constructor(
    private toastr: ToastrService,
    private notificationSettingsProvider: NotificationTemplateProvider
  ) {}

  ngOnInit() {
    this.loadTemplates();
  }
  loadTemplates() {
    this.loading = true;
    this.notificationSettingsProvider.get()
      .subscribe({
        next: (response: any) => {
          response.data.forEach((template) => {
            if (!template.tags) {
              template.tags = [];
              template.tagValues = [];
            } else {
              template.tags = JSON.parse(template.tags);
              template.tagValues = template.tags.map((tag) => {
                return {name: tag, value: ''}
              });

              this.br2nl(template);
            }
          });
          this.templates = response.data;
          this.loading = false;
        },
        error: (error) => {
          this.loading = false;
          this.toastr.error('Could not load email/sms templates: ' + error.message, 'Error');
        }
      });
  }
  updateTemplate(template) {
    if (template._saving) {
      return ;
    }
    const payload: any = Object.assign({}, {
      label: template.label,
      email: template.email,
      sms: template.sms,
      subject: template.subject
    });
    template._saving = true;
    if (this.testEmail || this.testPhone) {
      payload.testEmail = this.testEmail;
      payload.testPhone = this.testPhone;
      payload.testData = {};
      template.tagValues.forEach((tag) => {
        payload.testData[tag.name] = tag.value;
      });
    }
    this.nl2br(payload);
    this.notificationSettingsProvider.update(template.id, payload)
      .subscribe({
        next: (response: any) => {
          template._saving = false;
          this.stopEditing(template);
          this.toastr.success('Template updated', 'Success');
        },
        error: (error) => {
          template._saving = false;
        }
      });
  }
  stopEditing(template) {
    template._editingSms = false;
    template._editingEmail = false;
    template._editingSubject = false;
    template._editingLabel = false;
    template._editingTags = false;
  }
  br2nl(template) {
    if (template.email) {
      template.email = template.email.replaceAll('<br>', '\n');
    }
    if (template.sms) {
      template.sms = template.sms.replace('<br>', '\n');
    }
  }
  nl2br(template) {
    if (template.email) {
      template.email = template.email.replaceAll('\n', '<br>');
    }
  }
}
