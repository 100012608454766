<section class="inspection-certify">
  <h2>Review Your Customer’s Tank Inspection</h2>

  <section class="customer-info" *ngIf="customer">
    <div class="row">
      <div class="col-12 col-sm-6">
        <table>
          <tbody>
          <tr>
            <td><label>Name</label></td>
            <td>{{customer.firstName}} {{customer.lastName}}</td>
          </tr>
          <tr>
            <td><label>Email</label></td>
            <td>{{customer.email}}</td>
          </tr>
          <tr>
            <td><label>Phone</label></td>
            <td>{{customer.cellPhone}}</td>
          </tr>
          <tr>
            <td><label>Street</label></td>
            <td>{{customer.streetAddress}}</td>
          </tr>
          <tr>
            <td><label>Town</label></td>
            <td>{{customer.town}}</td>
          </tr>
          <tr>
            <td><label>State</label></td>
            <td>{{customer.state}}</td>
          </tr>
          <tr>
            <td><label>Zip</label></td>
            <td>{{customer.zip}}</td>
          </tr>
          </tbody>
        </table>
      </div>
      <div class="col-12 col-sm-6 review-text">
        Review {{customer.firstName}} {{customer.lastName}}'s inspection.<br>
        If you reject an image, {{customer.firstName}} will notified and asked to resubmit.
      </div>
    </div>

    <div *ngIf="customer.fieldsChangedByCustomer && customer.fieldsChangedByCustomer.length" class="changed-fields">
      <div class="title">Customer has changed their info</div>
      <div class="row headers">
        <div class="col-4">
          Field
        </div>
        <div class="col-4">
          Previous Value
        </div>
        <div class="col-4">
          New Value
        </div>
      </div>
      <div class="row" *ngFor="let field of customer.fieldsChangedByCustomer">
        <div class="col-4">
          {{customerFields[field.field]}}
        </div>
        <div class="col-4">
          {{field.oldValue}}
        </div>
        <div class="col-4">
          {{field.newValue}}
        </div>
      </div>
      <div class="row">
        <div class="col-12">
          <button
            class="btn btn-slick small float-right"
            (click)="dismissCustomerChanges()"
          >
            Dismiss
            <img *ngIf="updatingCustomer" src="assets/img/loader.gif" class='loader' alt="loading...">
          </button>
        </div>
      </div>
    </div>

  </section>

  <img *ngIf="loading" src="assets/img/loader.gif" class='loader' alt="loading...">


  <section class="inspection-steps" *ngIf="tankInspection">
    <div
      class="inspection-step"
      *ngFor="let customerStep of tankInspection.customerInspectionSteps; index as i"
      [ngClass]="{
        'has-2-columns': ((checklistAvailable && customerStep.inspectionStep.questions.length) || customerStep._addingRecommendations)
      }"
    >
      <div class="col-left">
        <!-- Inspection file review begin -->
        <div class="title">
          {{customerStep.inspectionStep.name}}
        </div>
        <div *ngIf="customerStep._rejecting">
          <div class="form-group inspection-file">
            <label for="reject-reason-{{i}}">
              Reason you are rejecting this file.
            </label>
            <textarea
              *ngIf="customerStep.files[0]"
              id="reject-reason-{{i}}"
              placeholder="Use this field to tell the customer why you are rejecting their file.  Be as specific as possible.  They will see what you write here."
              class="form-control"
              [(ngModel)]="customerStep.files[0].statusNotes"
            ></textarea>
            <textarea
              *ngIf="!customerStep.files[0]"
              id="reject-reason-{{i}}"
              placeholder="Use this field to tell the customer why you are rejecting their file.  Be as specific as possible.  They will see what you write here."
              class="form-control"
              [(ngModel)]="customerStep.rejectNote"
            ></textarea>

            <button
              style="margin-top: 0.4em;"
              class="btn btn-slick small dark"
              (click)="customerStep._addingRecommendations = true;customerStep._activeTab = 'recommendations'"
              *ngIf="!customerStep._addingRecommendations && customerStep.inspectionStep.recommendations.length"
            >
              Add Recommendation
            </button>
          </div>
        </div>
        <div *ngIf="!customerStep._rejecting">
          <div
            *ngIf="customerStep.files[0] && customerStep.inspectionStep.type === 'image'"
            class="inspection-file bg-cover"
            [ngStyle]="{'background-image': getBgUrl(customerStep.files[0])}"
            (click)="enterFullscreenReview(customerStep);"
          >
          </div>

          <div
            *ngIf="customerStep.files[0] && customerStep.inspectionStep.type === 'video'"
            class="inspection-file"
          >
            <video width="100%" style="max-height: 100%;" controls>
              <source src="{{getFileUrl(customerStep.files[0])}}">
              Your browser does not support HTML5 video.
            </video>
          </div>
          <div *ngIf="!customerStep.files[0]" class="inspection-file">
            <div class="note">
              {{customerStep.customerNote ? customerStep.customerNote: 'file removed'}}
            </div>
          </div>
        </div>

        <!--accept/reject/show files buttons before user has clicked anything -->
        <div
          class="buttons"
          *ngIf="customerStep.status !== 'rejected' && customerStep.status !== 'completed' && !customerStep._rejecting"
        >
          <hr>
          <div class="row" *ngIf="customerStep.files.length < 2">
            <div class="col-6">
              <button
                class="btn btn-slick small warning"
                (click)="customerStep._rejecting = true"
              >
                Reject
              </button>
            </div>
            <div class="col-6">
              <button
                class="btn btn-slick small dark"
                *ngIf="!customerStep._rejecting"
                (click)="onAccept(customerStep)"
              >
                Accept
              </button>
            </div>
          </div>
          <div class="row" *ngIf="customerStep.files.length > 1">
            <div class="col-12">
              <button
                class="btn btn-slick small"
                (click)="enterFullscreenReview(customerStep);"
              >
                Review Image
              </button>
            </div>
          </div>
        </div>

        <!--reject/cancel buttons after user has clicked "reject" -->
        <div
          class="buttons"
          *ngIf="customerStep.status !== 'rejected' && customerStep.status !== 'completed' && customerStep._rejecting"
        >
          <hr>
          <div class="row">
            <div class="col-6">
              <button
                class="btn btn-slick small warning"
                (click)="onReject(customerStep)"
              >
                confirm reject
              </button>
            </div>
            <div class="col-6">
              <button
                class="btn btn-slick small"
                (click)="customerStep._rejecting = false;"
              >
                cancel
              </button>
            </div>
          </div>
        </div>

        <!-- show inspection status after user has rejected or accepted -->
        <div
          class="buttons"
          *ngIf="customerStep.status === 'rejected' || customerStep.status === 'completed'"
        >
          <hr>
          <div class="row">
            <div class="col-12">
              <div
                class="btn btn-slick small warning"
                *ngIf="customerStep.status === 'rejected'"
              >
                rejected
              </div>
              <div
                class="btn btn-slick small dark"
                *ngIf="customerStep.status === 'completed'"
              >
                accepted
              </div>
            </div>
          </div>
        </div>

        <!-- Inspection file review end -->
      </div>
      <div class="col-right">
        <div class="col-right-tabs">
          <div
            class="tab"
            [ngClass]="{'active': customerStep._activeTab !== 'recommendations'}"
            (click)="customerStep._activeTab = 'qa'"
          >
            Q/A
          </div>
          <div
            class="tab"
            [ngClass]="{'active': customerStep._activeTab === 'recommendations'}"
            (click)="customerStep._activeTab = 'recommendations'"
          >
            Recommendations
          </div>
        </div>
        <div class="content-wrapper">
          <div class="question-answer-wrapper" *ngIf="customerStep._activeTab !== 'recommendations'">
            <ng-container *ngFor="let question of customerStep.inspectionStep.questions">
              <app-question-answer
                [question]="question"
                [questionAnswer]="question.answer ? question.answer.value: undefined"
                [saveBtnText]="question.answer && question.answer._saved ? 'saved': 'save'"
                *ngIf="isQuestionVisible(question, customerStep.inspectionStep.questions)"
                [customer]="customer"
                [customerInspectionStep]="customerStep"
              >
              </app-question-answer>
            </ng-container>
          </div>
          <div class="recommendation-wrapper" *ngIf="customerStep._activeTab === 'recommendations'">
            <app-recommendation
              [customerStep]="customerStep"
              [customer]="customer"
            >
            </app-recommendation>
          </div>
        </div>
      </div>

    </div>
  </section>

  <div style="text-align: center;" *ngIf="rejectTank">
    <div id="confirm-reject-box" style="max-width: 45em;padding: 1em;">
      <label for="reject-tank-reason">
        Reason for rejecting
      </label>
      <textarea
        style="margin: 0.4em; min-height: 4em;"
        class="form-control"
        name="reject-tank-reason"
        id="reject-tank-reason"
        [(ngModel)]="tankInspection.statusNotes"
        placeholder="enter your reasons"
        ngModel
      >
    </textarea>
      <button
        style="margin-right: 1em;"
        *ngIf="!loading && tankInspection.status !== 'completed'"
        class="btn btn-slick warning"
        (click)="rejectTankInspection()"
        [appScrollIntoView]
      >
        Confirm Reject
        <img *ngIf="rejectingTank" src="assets/img/loader.gif" class='loader' alt="loading...">
      </button>
    </div>
  </div>
  <div style="text-align: center" *ngIf="!rejectTank">
    <button
      style="margin-right: 1em;"
      *ngIf="!loading && tankInspection.status !== 'completed' && tankInspection.status !== 'rejected'"
      class="btn btn-slick warning"
      (click)="rejectTank = true"
    >
      Reject Tank
    </button>
    <button
      *ngIf="!loading && !checklistUuid"
      class="btn btn-slick"
      (click)="submitTankInspection()"
    >
      Submit All Changes
      <img *ngIf="submittingTankInspection" src="assets/img/loader.gif" class='loader' alt="loading...">
    </button>
    <button
      *ngIf="!loading && checklistUuid && tankInspection.status !== 'inprogress'"
      class="btn btn-slick"
      (click)="beginSigningChecklist()"
    >
      Sign Inspection Checklist
      <img *ngIf="signingTankInspection" src="assets/img/loader.gif" class='loader' alt="loading...">
    </button>
  </div>

  <!-- full screen image view shown when user clicks on any of the customer images -->
  <section class="fullscreen-review" *ngIf="selectedCustomerStep">
    <!-- top right buttons on full screen -->
    <div class="review-actions" *ngIf="!selectedCustomerStep._rejecting">
      <button
        *ngIf="fullScreenFile.status !== 'rejected'"
        class="btn btn-slick small dark"
        (click)="onAccept(selectedCustomerStep, fullScreenFile)"
      >
        {{fullScreenFile.status === 'accepted' ? 'Accepted': 'Accept'}}
      </button>
      <button
        *ngIf="fullScreenFile.status !== 'accepted'"
        class="btn btn-slick small warning"
        (click)="selectedCustomerStep._rejecting = true;"
      >
        {{fullScreenFile.status === 'rejected' ? 'Rejected': 'Reject'}}
      </button>
      <button
        class="btn btn-slick small light"
        (click)="exitFullscreenReview()"
      >
        Close
      </button>
    </div>
    <!-- reject/cancel buttons shown after user has clicked "reject" button and is expected to enter notes -->
    <div *ngIf="selectedCustomerStep._rejecting" class="review-actions notes">
      <label for="reject-reason-selected">
        Reason you are rejecting this file.
      </label>
      <textarea
        id="reject-reason-selected"
        placeholder="Use this field to tell the customer why you are rejecting their file.  Be as specific as possible.  They will see what you write here."
        class="form-control"
        [(ngModel)]="fullScreenFile.statusNotes"
        ngModel
      ></textarea>
      <div style="margin-top: 1em; text-align: right;">
        <button
          class="btn btn-slick small warning"
          (click)="onReject(selectedCustomerStep, fullScreenFile)"
        >
          Reject
        </button>
        <button
          class="btn btn-slick small light"
          (click)="selectedCustomerStep._rejecting = false;"
        >
          Cancel
        </button>
      </div>
    </div>

    <div
      class="review-file bg-cover"
      [ngStyle]="{'background-image': getBgUrl(fullScreenFile)}"
    >
    </div>
    <div class="review-thumbnails">
      <div
        [ngClass]="{selected: fullScreenFile === file}"
        class="thumbnail bg-cover"
        *ngFor="let file of selectedCustomerStep.files"
        [ngStyle]="{'background-image': getBgUrl(file)}"
        (click)="fullScreenFile = file;"
      >
      </div>
    </div>
  </section>
</section>
