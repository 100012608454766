import { Component, OnInit } from '@angular/core';
import { CompanyProvider } from '../../../providers/company.provider';
import { PlanProvider } from '../../../providers/plan.provider';
import { ToastrService } from 'ngx-toastr';
import { Router, ActivatedRoute } from '@angular/router';
import { Company } from '../../../models/company';
import { Plan } from '../../../models/plan';

@Component({
  selector: 'app-companies',
  templateUrl: './companies.component.html',
  styleUrls: ['./companies.component.scss'],
  providers: []
})
export class CompaniesComponent implements OnInit {
  companies: Company[] = [];
  loadingCompanies = false;
  showNameFilter = false;
  showStatusFilter = false;
  companyNameFilter: string = '';
  companyStatusFilter: string = '';
  searchTimeout: any;
  statusFilters = [
    {label: 'None', value: '', selected: true},
    {label: 'Pending', value: 'pending'},
    {label: 'Approved', value: 'approved'},
    {label: 'Rejected', value: 'rejected'},
  ];
  plans: Plan[] = [];
  constructor(
    private companyProvider: CompanyProvider,
    private planProvider: PlanProvider,
    private toastr: ToastrService,
    private router: Router,
    private route: ActivatedRoute
  ) {}

  ngOnInit() {
    this.loadPlans();
    this.route.params.subscribe((data: any) => {
      if (data.status) {
        if (Object.keys(this.statusFilters).map(f => this.statusFilters[f].value).indexOf(data.status) === -1) {
          this.toastr.error('Invalid status filter: ' + data.status, 'Error');
        } else {
          this.companyStatusFilter = data.status;
        }
      }
    });
    this.loadCompanies();
  }
  loadCompanies() {
    this.loadingCompanies = true;
    this.companies = [];
    this.companyProvider.list({
      keyword: this.companyNameFilter,
      status: this.companyStatusFilter
    }).subscribe({
      next: (response: any) => {
        this.companies = response.data;
        this.loadingCompanies = false;
      },
      error: (error: any) => {
        this.companies = [];
        this.loadingCompanies = false;
      }
    });
  }
  loadPlans() {
    this.plans = [];
    this.planProvider.get().subscribe({
      next: (response: any) => {
        this.plans = response.data;
      }
    });
  }

  approve(company) {
    company._approving = true;
    this.companyProvider.approve(company.id).subscribe({
      next: (response: any) => {
        Object.assign(company, response.data);
        company._approving = false;
        this.toastr.success(company.name + ' has been approved', 'Success');
      },
      error: (error: any) => {
        company._approving = false;
      }
    });
  }
  setPlan(company) {
    if (company.status === 'rejected' && company.planId != 'null') {
      return this.toastr.error('This company has been rejected.', 'Error');
    }
    company._saving = true;
    this.companyProvider.update(company.id, company).subscribe({
      next: (response: {data: Company}) => {
        company.plan = response.data.plan;
        this.toastr.success('Company plan updated', 'Success');
        company._saving = false;
        company._settingPlan = false;
      },
      error: (error) => {
        company._saving = false;
      }
    });
  }
  reject(company) {
    company._rejecting = true;
    this.companyProvider.reject(company.id, {
      rejectReason: company.rejectReason
    }).subscribe({
      next: (response: any) => {
        Object.assign(company, response.data);
        company._rejecting = false;
        company._confirmReject = false;
        this.toastr.success(company.name + ' has been rejected', 'Success');
      },
      error: (error: any) => {
        company._rejecting = false;
      }
    });
  }
  deleteCompany(company) {
    company._deleting = true;
    this.companyProvider.delete(company.id).subscribe({
      next: (response: any) => {
        company._deleting = false;
        company._confirmDelete = false;
        const index = this.companies.indexOf(company);
        this.companies.splice(index, 1);
        this.toastr.success(company.name + ' has been deleted', 'Success');
      },
      error: (error: any) => {
        company._deleting = false;
      }
    });
  }

  onFilterBlur() {
    if (!this.companyNameFilter) {
      this.showNameFilter = false;
    }
    if (!this.companyStatusFilter) {
      this.showStatusFilter = false;
    }
    this.loadCompanies();
  }
  searchByName() {
    if (this.searchTimeout) {
      clearTimeout(this.searchTimeout);
    }

    this.searchTimeout = setTimeout(() => {
      if (this.companyNameFilter) {
        this.companyNameFilter = this.companyNameFilter.trim();
        this.loadCompanies();
      }
    }, 500);
  }
}
