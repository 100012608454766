import { Output, Input, EventEmitter } from '@angular/core';
import { Component } from '@angular/core';
import { Customer } from '../models/customer';
import { User } from '../models/user';
import { Question } from '../models/question';
import { CustomerInspectionStep } from '../models/customer-inspection-step';
import { QuestionAnswer } from '../models/question-answer';
import {CustomerInspectionStepProvider} from "../providers/customer-inspection-step.provider";

@Component({
  selector: 'app-question-answer',
  templateUrl: './question-answer.component.html',
  styleUrls: ['./question-answer.component.scss']
})
export class QuestionAnswerComponent {
  @Input() question: Question;
  @Input() customerInspectionStep: CustomerInspectionStep;
  @Input() customer: Customer;
  @Input() user: User;
  @Output() onAnswer = new EventEmitter<any>();
  @Output() onChange = new EventEmitter<any>();
  @Input() questionAnswer: any;
  @Input() saveBtnText = 'save';
  @Input() saveBtnClass = ' light ';

  constructor(private customerInspectionStepProvider: CustomerInspectionStepProvider) {

  }
  ngOnInit() {
  }
  setBooleanAnswer(value) {
    this.questionAnswer = value;
    this.saveQuestionAnswer(this.questionAnswer);
  }
  parseAnswer() {
    if (this.question.answerType === 'number') {
      if (!this.question.optional && Number.isNaN(this.questionAnswer)) {
        alert('Please enter a valid number');
        return;
      }
      return parseFloat(this.questionAnswer);
    } else if (this.question.answerType === 'bool') {
      return [true, 1].join().includes(this.questionAnswer) ? 1: 0;
    } else if (this.question.answerType === 'string' || this.question.answerType === 'text') {
      if (!this.questionAnswer) {
        if (!this.question.optional) {
          alert('Question "'+ this.question.text +'" is not optional');
        }
        this.questionAnswer = '';
      }
      return this.questionAnswer.trim();
    } else {
      alert('Unhandled answer type');
    }
  }
  setTextAnswer() {
    this.saveQuestionAnswer(this.parseAnswer());
  }
  setNumberAnswer() {
    this.saveQuestionAnswer(this.parseAnswer());
  }
  modelChange() {
    this.onChange.emit(this.parseAnswer());
  }
  isTrue() {
    return [1, true].join().includes(this.questionAnswer)
  }
  saveQuestionAnswer(answer: any) {
    const step = this.customerInspectionStep;
    const question = this.question;
    if (question.answer) {
      question.answer.value = answer;
    } else {
      question.answer = {
        value: answer,
        questionId: question.id
      };
    }
    if (question.answerType === 'bool') {
      question.answer.value = [true, 1].join().includes(question.answer.value) ? 1: 0;
    }
    const saveFn = question.answer.id ? 'updateQuestionAnswer': 'answerQuestion';
    question.answer._saving = true;
    const payload = {
      value: question.answer.value,
      id: question.answer.id
    };
    delete question.answer._saved;
    this.customerInspectionStepProvider[saveFn](
      this.customer.companyId,
      this.customer.id,
      step.id,
      question.id,
      payload
    ).subscribe({
      next: (response: any) => {
        question.answer._saving = false;
        question.answer = response.data;
        question.answer._saved = true;
        setTimeout(() => {
          // slight delay so that user can see their check mark before
          // any ngIf will hide the element.
          this.onAnswer.emit(response.data);
        }, 100);
      },
      error: (error) => {
        question.answer._saving = false;
      }
    });
  }
  get saving() {
    if (this.question && this.question.answer) {
      return this.question.answer._saving;
    }
    return false;
  }
}
