<section class="company-setup">
  <div class="row">
    <div class="col-12">
      <h1>Inspection Request Details</h1>
    </div>
  </div>

  <section class="inspection-settings">
    <p class="description">
      This screen shows you what information your customer or technician will be asked to collect.<br>
      You can turn them on and off simply by clicking on the checkbox.<br>
      If it’s green, your customer will be asked to submit that image/video.<br>
    </p>
    <div class="setting" *ngFor="let step of availableInspectionSteps">
      <table>
        <tbody>
          <tr>
            <td>
              <img
                class="checkbox"
                (click)="toggleStepRequired(step)"
                [src]="
              step._saving ? 'assets/img/loader.gif':
              stepRequired(step) ? '/assets/img/check-full-2.png' : '/assets/img/check-empty.png'"
                [alt]="stepRequired(step) ? 'checked' : 'not checked'"
              >
            </td>
            <td>
              <div class="label" (click)="selectInspectionStep(step)">{{step.name}}</div>
            </td>
          </tr>
        </tbody>
      </table>

      <div class="instructions">
        {{step.instructions ? step.instructions : 'No text instructions provided'}}
      </div>


      <video
        *ngIf="step.type === 'video'"
        class="instruction-file"
        width="100%" controls
        (click)="step._showFiles = true;"
      >
        <source [src]="getInstructionFileUrl(step)">
        Your browser does not support HTML5 video.
      </video>

      <div
        *ngIf="step.type !== 'video'"
        (click)="step._showFiles = true;"
        class="instruction-file bg-cover"
        [ngStyle]="{'background-image': 'url('+ getInstructionFileUrl(step) +')'}"
      >
      </div>

      <section class="file-list"  *ngIf="step._showFiles">
        <h4>Pick {{step.type === 'video' ? 'a video': 'an image'}} for this step</h4>
        <button
          style="position: absolute; top: 0.4em; right: 0.4em;"
          class="btn btn-slick small light"
          (click)="step._showFiles = false;"
        >
          close
        </button>
        <ng-container *ngFor="let file of step.files">
          <div
            *ngIf="step.type === 'image'"
            class="file bg-cover"
            [ngStyle]="{'background-image': 'url('+ apiUrl + 'file/' + file.uuid +')'}"
            [ngClass]="{selected: inspectionFileSelected(step, file)}"
            (click)="setInspectionFile(step, file)"
          >
          </div>
          <div
            *ngIf="step.type === 'video'"
            class="file"
            [ngClass]="{selected: inspectionFileSelected(step, file)}"
            (click)="setInspectionFile(step, file)"
          >
            <video width="100%" style="max-height: 100%;" controls>
              <source [src]="apiUrl + 'file/' + file.uuid">
              Your browser does not support HTML5 video.
            </video>

          </div>
        </ng-container>

      </section>

      <!--
      <img (click)="toggleStepRequired(step)"
           [src]="
              step._saving ? 'assets/img/loader.gif':
              stepRequired(step) ? '/assets/img/check-full-2.png' : '/assets/img/check-empty.png'"
           [alt]="stepRequired(step) ? 'checked' : 'not checked'"
      >
      <div class="label" (click)="selectInspectionStep(step)">{{step.name}}</div>

      <div class="available-files" *ngIf="step === selectedInspectionStep && step.files.length">
        <div
          class="image-wrapper"
          *ngFor="let file of step.files"
          (click)="setInspectionFile(step, file)"
          [ngClass]="{selected: inspectionFileSelected(step, file)}"
        >
          <img [src]="apiUrl + 'file/' + file.uuid">
        </div>
      </div>-->
    </div>

  </section>
</section>
