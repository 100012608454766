import { Output, Input, EventEmitter } from '@angular/core';
import { Component } from '@angular/core';
import { CustomerAffidavitProvider } from '../providers/customer-affidavit.provider';
import { Company } from '../models/company';
import { Customer } from '../models/customer';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-affidavit',
  templateUrl: './affidavit.component.html',
  styleUrls: ['./affidavit.component.scss']
})
export class AffidavitComponent {
  @Input() tankInspection: any;
  @Input() company: Company;
  @Input() customer: Customer;
  @Output() onComplete = new EventEmitter<any>();
  loadingAffidavitList = false;
  submitting = false;
  customerAffidavits: Array<{
    id: number,
    certified: number,
    _loading?: boolean,
    affidavit: {
      id?: number,
      text: string
    }
  }> = [];
  constructor(
    private toastr: ToastrService,
    private customerAffidavitProvider: CustomerAffidavitProvider) {

  }
  ngOnInit() {
    window['affidavit'] = this;

    this.loadingAffidavitList = true;
    this.customerAffidavitProvider.list(this.company.id, this.customer.id, this.tankInspection.id).subscribe({
      next: (response: any) => {
        this.customerAffidavits = response.data;
        if (
          this.customerAffidavits.length &&
          this.customerAffidavits.every((a: any) => {return a.certified})
        ) {
          this.onComplete.emit(this.customerAffidavits);
        }
        // give onComplete some time to redirect if needed
        setTimeout(() => {
          this.loadingAffidavitList = false;
        }, 250);
      },
      error: () => {
        this.toastr.error('Could not load affidavit', 'Error');
        this.loadingAffidavitList = false;
      }
    });
  }
  submitAffidavit() {
    const notCertified = this.customerAffidavits.filter((affidavit) => {
      return !affidavit.certified;
    });
    if (notCertified.length) {
      return this.toastr.error('You must check all the boxes in order to continue', 'Error');
    }
    if (this.submitting) {
      return ;
    }
    this.submitting = true;
    let i;
    for (i = 0; i < this.customerAffidavits.length; i++) {
      const affidavit = this.customerAffidavits[i];
      const certified = affidavit.certified ? 1 : 0;
      affidavit._loading = true;
      this.customerAffidavitProvider.update(
        this.company.id,
        this.customer.id,
        this.tankInspection.id,
        affidavit.id,
        {certified}
        ).subscribe({
          next: (response: any) => {
            affidavit._loading = false;
            const notCertified = this.customerAffidavits.filter((affidavit) => {
              return !affidavit.certified || affidavit._loading;
            });
            const loadingAffidavits = this.customerAffidavits.filter((affidavit) => {
              return affidavit._loading;
            });
            if (!notCertified.length) {
              // ToDo: move on to the next step( maybe reload page )
              this.toastr.success('Inspection step completed', 'Success');
              this.onComplete.emit(this.customerAffidavits);
            }
            if (!loadingAffidavits.length) {
              this.submitting = false;
            }
          },
          error: () => {
            this.toastr.error('Could not certify', 'Error');
            affidavit._loading = false;
            this.submitting = false;
          }
      });
    }
  }
}
