import { Component, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import { Output, Input, EventEmitter } from '@angular/core';
import { Customer } from '../models/customer';
import { StateListProvider } from '../providers/state-list.provider';

/*
* Displays and allows editing of customer information
* */
@Component({
  selector: 'app-customer-information',
  templateUrl: './customer-information.component.html',
  styleUrls: ['./customer-information.component.scss']
})
export class CustomerInformationComponent {
  @ViewChild('form') public form: NgForm;
  @Input() editMode = true;
  @Input() validationErrors: any = [];

  @Input() showInspectionType = true;
  @Input() customer: Customer = {
    firstName: '',
    lastName: '',
    cellPhone: '',
    email: '',
    streetAddress: '',
    town: '',
    state: '',
    zip: '',
    inspectionType: ''
  };
  @Output() onSubmit = new EventEmitter<any>();

  constructor(public stateListProvider: StateListProvider) {
  }
  ngOnInit() {}
  getError(id) {
    return this.validationErrors[id]
  }
}
