<section class="inspection-certify">
  <h2>Customer Tank Inspection
    <span *ngIf="tankInspection">: {{toCamelCase(tankInspection.status)}}</span>
  </h2>

  <section class="customer-info" *ngIf="customer">
    <div class="row">
      <div class="col-12">
        <table>
          <tbody>
          <tr>
            <td><label>Name</label></td>
            <td>{{customer.firstName}} {{customer.lastName}}</td>
          </tr>
          <tr>
            <td><label>Email</label></td>
            <td>{{customer.email}}</td>
          </tr>
          <tr>
            <td><label>Phone</label></td>
            <td>{{customer.cellPhone}}</td>
          </tr>
          <tr>
            <td><label>Street</label></td>
            <td>{{customer.streetAddress}}</td>
          </tr>
          <tr>
            <td><label>Town</label></td>
            <td>{{customer.town}}</td>
          </tr>
          <tr>
            <td><label>State</label></td>
            <td>{{customer.state}}</td>
          </tr>
          <tr>
            <td><label>Zip</label></td>
            <td>{{customer.zip}}</td>
          </tr>
          </tbody>
        </table>
      </div>
    </div>

    <div *ngIf="customer.fieldsChangedByCustomer && customer.fieldsChangedByCustomer.length" class="changed-fields">
      <div class="title">Customer has changed their info</div>
      <div class="row headers">
        <div class="col-4">
          Field
        </div>
        <div class="col-4">
          Previous Value
        </div>
        <div class="col-4">
          New Value
        </div>
      </div>
      <div class="row" *ngFor="let field of customer.fieldsChangedByCustomer">
        <div class="col-4">
          {{customerFields[field.field]}}
        </div>
        <div class="col-4">
          {{field.oldValue}}
        </div>
        <div class="col-4">
          {{field.newValue}}
        </div>
      </div>
      <div class="row">
        <div class="col-12">
          <button
            class="btn btn-slick small float-right"
            (click)="dismissCustomerChanges()"
          >
            ok
            <img *ngIf="updatingCustomer" src="assets/img/loader.gif" class='loader' alt="loading...">
          </button>
        </div>
      </div>
    </div>
  </section>
  <img *ngIf="loading" src="assets/img/loader.gif" class='loader' alt="loading...">

  <div class="certificate-buttons" *ngIf="tankInspection">
    <ng-container *ngIf="tankInspection.status === 'completed'">
      <button
        class="btn btn-slick light"
        (click)="showCertificate = !showCertificate"
      >
        {{showCertificate ? 'Hide': 'Show'}} Tracking Token
      </button>
      <button
        class="btn btn-slick light"
        (click)="downloadCertificate()"
        style="margin-left: 1em;"
      >
        Download Tracking Token
      </button>
    </ng-container>

    <button
      *ngIf="tankInspection.checklistFileId"
      class="btn btn-slick light"
      (click)="downloadChecklist()"
      style="margin-left: 1em;"
    >
      Show Checklist
    </button>
  </div>

  <section class="inspection-steps" *ngIf="tankInspection && !showCertificate">
    <div class="inspection-step" *ngFor="let customerStep of tankInspection.customerInspectionSteps; index as i">
      <div class="title">
        {{customerStep.inspectionStep.name}}
      </div>
      <div
        *ngIf="customerStep.files[0] && customerStep.inspectionStep.type === 'image'"
        class="inspection-file bg-cover"
        [ngStyle]="{'background-image': getBgUrl(customerStep.files[0])}"
        (click)="enterFullscreenReview(customerStep);"
      >
      </div>

      <div
        *ngIf="customerStep.files[0] && customerStep.inspectionStep.type === 'video'"
        class="inspection-file"
      >
        <video width="100%" style="max-height: 100%;" controls>
          <source src="{{getFileUrl(customerStep.files[0])}}">
          Your browser does not support HTML5 video.
        </video>
      </div>
      <div *ngIf="!customerStep.files[0]" class="inspection-file">
        <div class="note">
          {{customerStep.customerNote ? customerStep.customerNote: 'file removed'}}
        </div>
      </div>

      <div class="buttons">
        <hr>
        <div class="row">
          <div class="col-12">
            <button
              class="btn btn-slick small"
              (click)="enterFullscreenReview(customerStep);"
            >
              Review Image
            </button>
          </div>
        </div>
      </div>
    </div>
  </section>

  <app-certificate
    *ngIf="showCertificate"
    [customer]="customer"
    [tankInspection]="tankInspection"
  >

  </app-certificate>

  <!-- full screen image view shown when user clicks on any of the customer images -->
  <section class="fullscreen-review" *ngIf="selectedCustomerStep">
    <!-- top right buttons on full screen -->
    <div class="review-actions">
      <div
        *ngIf="fullScreenFile.status === 'accepted'"
        class="btn btn-slick small dark"
      >
        accepted
      </div>
      <div
        *ngIf="fullScreenFile.status === 'rejected'"
        class="btn btn-slick small warning"
      >
        rejected
      </div>
      <button
        class="btn btn-slick small light"
        (click)="exitFullscreenReview()"
      >
        close
      </button>
    </div>

    <div
      class="review-file bg-cover"
      [ngStyle]="{'background-image': getBgUrl(fullScreenFile)}"
    >
    </div>
    <div class="review-thumbnails">
      <div
        [ngClass]="{selected: fullScreenFile === file}"
        class="thumbnail bg-cover"
        *ngFor="let file of selectedCustomerStep.files"
        [ngStyle]="{'background-image': getBgUrl(file)}"
        (click)="fullScreenFile = file;"
      >
      </div>
    </div>
  </section>
</section>
