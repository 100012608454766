import { Injectable } from '@angular/core';
import { APIProvider } from './api.provider';

@Injectable()
export class InspectionStepProvider {

  constructor(private api: APIProvider) {

  }
  get(id: number) {
    return this.api.get('inspection-step/' + id);
  }
  list(companyId?: number, params?) {
    if (companyId) {
      return this.api.get(`company/${companyId}/inspection-step`, params);
    } else {
      return this.api.get('inspection-step', params);
    }
  }
  create(payload) {
    return this.api.post('inspection-step', payload);
  }
  update(id: number, payload, multipart = false) {
    return this.api.put('inspection-step/' + id, payload, multipart);
  }
  deleteFiles(id: number, fileIds: Number[]) {
    return this.api.put('inspection-step/' + id + '/deleteFiles', {files: fileIds});
  }
  addQuestion(stepId, payload) {
    return this.api.post(`inspection-step/${stepId}/question`, payload);
  }
  updateQuestion(stepId, questionId, payload) {
    return this.api.put(`inspection-step/${stepId}/question/${questionId}`, payload);
  }
  deleteQuestion(stepId, questionId) {
    return this.api.delete(`inspection-step/${stepId}/question/${questionId}`);
  }

  addRecommendation(stepId, payload) {
    return this.api.post(`inspection-step/${stepId}/recommendation`, payload);
  }
  updateRecommendation(stepId, recommendationId, payload) {
    return this.api.put(`inspection-step/${stepId}/recommendation/${recommendationId}`, payload);
  }
  deleteRecommendation(stepId, recommendationId) {
    return this.api.delete(`inspection-step/${stepId}/recommendation/${recommendationId}`);
  }
}
