import { QuestionAnswer } from './question-answer';
import { InspectionStep } from './inspection-step';

export interface Question {
  id?: number;
  label: number;
  text: string;
  filters?: any[];
  answerType?: string;
  optional?: number;
  inspectionStepId: number;
  inspectionStep?: InspectionStep;
  answers: QuestionAnswer[];
  answer: QuestionAnswer;
  answeredByCustomer: number;
  answeredByCompany: number;
  parentQuestion?: Question;
  parentQuestionId?: number;
}
export const Question = (fieldValues?): Question => {
  const question: any = {
    label: '',
    text: '',
    filters: [],
    answerType: 'bool',
    optional: 0,
    answers: [],
    answeredByCustomer: 0,
    answeredByCompany: 1
  };
  if (fieldValues) {
    Object.assign(question, fieldValues)
  }
  return question;
};
