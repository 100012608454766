import { Component, OnInit } from '@angular/core';
import { SettingProvider } from '../../providers/setting.provider';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-admin-settings',
  templateUrl: './admin-settings.component.html',
  styleUrls: ['./admin-settings.component.scss'],
  providers: []
})
export class AdminSettingsComponent implements OnInit {
  loading = false;
  settings: Array<{
    id: number,
    name: string,
    label: string,
    value: string,
    type?: string
  }> = [];
  constructor(
    private toastr: ToastrService,
    private settingProvider: SettingProvider
  ) {}

  ngOnInit() {
    this.loadSettings();
  }
  loadSettings() {
    this.loading = true;
    this.settingProvider.getAdminSettings()
      .subscribe({
        next: (response: any) => {
          this.settings = response.data;
          this.loading = false;
        },
        error: (error) => {
          this.loading = false;
          this.toastr.error('Could not load admin settings: ' + error.message, 'Error');
        }
      });
  }
  saveSetting(setting) {
    if (setting._saving) {
      return ;
    }
    setting._saving = true;
    this.settingProvider.updateAdminSetting(setting.id, {value: setting.value})
      .subscribe({
        next: (response: any) => {
          setting._saving = false;
          this.toastr.success('Setting updated', 'Success');
        },
        error: (error) => {
          setting._saving = false;
        }
      });
  }
}
