import { Component } from '@angular/core';
import { Output, Input, EventEmitter } from '@angular/core';
import { InspectionStep } from '../models/inspection-step';
import { UserProvider } from '../providers/user.provider';
import { InspectionStepProvider } from '../providers/inspection-step.provider';
import { environment } from '../../environments/environment';
import {CustomerInspectionStepProvider} from "../providers/customer-inspection-step.provider";
import { Customer } from '../models/customer';
import { Question } from '../models/question';
import { CustomerInspectionStep } from '../models/customer-inspection-step';
import { trigger, state, style, animate, transition } from '@angular/animations';

/*
* Displays and allows editing of customer information
* */
@Component({
  selector: 'app-customer-inspection-step',
  templateUrl: './customer-inspection-step.component.html',
  styleUrls: ['./customer-inspection-step.component.scss'],
  animations: [
    trigger(
      'inOutAnimation',
      [
        transition(
          ':enter',
          [
            style({ position: 'absolute', right: '-100%', opacity: 0 }),
            animate('0.5s ease-out',
              style({ right: '0%', opacity: 1 })),
            style({ position: 'inherit' }),
          ]
        ),
        transition(
          ':leave',
          [
            style({ position: 'absolute', left: '0%', opacity: 1 }),
            animate('0.5s ease-in',
              style({ left: '-100%', opacity: 0.2 }))
          ]
        )
      ]
    )
  ]
})
export class CustomerInspectionStepComponent{
  loadingInspectionStep = false;
  inspectionStep: InspectionStep;
  apiUrl = environment.apiUrl;
  fileDataUrl: any;
  fileObjUrl: any;
  selectedFile: any;
  submitting = false;
  uploadNextFile = false;
  fileIsUnsaved = false;
  _customerInspectionStep: any;
  showCantTakeImage = false;
  cantTakeImageReason: string;
  currentQuestion: Question;
  currentQuestionIndex = 0;
  @Input() tankInspection: any;
  @Input() customer: Customer;
  @Output() onComplete = new EventEmitter<any>();
  constructor(
    private inspectionStepProvider: InspectionStepProvider,
    private customerInspectionStepProvider: CustomerInspectionStepProvider,
    private userProvider: UserProvider
  ) {

  }
  ngOnInit() {
    this.loadInspectionStep();
  }

  @Input() set customerInspectionStep(step: any) {
    const prevStep = this._customerInspectionStep;
    this._customerInspectionStep = step;
    if (
      prevStep && step &&
      step.inspectionStepId !== prevStep.inspectionStepId
    ) {
      // step changed, load new inspection step info
      this.uploadNextFile = false;
      this.loadInspectionStep();
    } else {
      this.setInspectionQuestion(0);
    }
  }
  setInspectionQuestion(index) {
    if (
      !this._customerInspectionStep ||
      !this._customerInspectionStep.inspectionStep ||
      !this._customerInspectionStep.inspectionStep.questions
    ) {
      return ;
    }
    this.currentQuestionIndex = index;
    const question = this._customerInspectionStep.inspectionStep.questions[this.currentQuestionIndex];
    if (!question) {
      this.currentQuestion = undefined;
      return ;
    }
    if (!this.isQuestionVisible(question, this._customerInspectionStep.inspectionStep.questions)) {
      // skip over hidden questions
      this.setInspectionQuestion(index + 1);
    } else {
      // set timeout for ngIf animation.
      this.currentQuestion = undefined;
      setTimeout(() => {
        this.currentQuestion = question;
      }, 64);
    }
  }
  get customerInspectionStep() {
    return this._customerInspectionStep;
  }
  get instructionFileUuid() {
    // return file preferred by company - if no preffered file, show first file as default.
    if (!this.customerInspectionStep || !this.inspectionStep) {
      return '';
    }
    if (this.customerInspectionStep.inspectionFile) {
      return this.customerInspectionStep.inspectionFile.uuid;
    }
    if (this.inspectionStep.files && this.inspectionStep.files.length) {
      return this.inspectionStep.files[0].uuid;
    }
    return '';
  }
  ready() {
    this.triggerUpload();
  }
  loadInspectionStep() {
    this.loadingInspectionStep = true;
    this.inspectionStepProvider.get(this._customerInspectionStep.inspectionStepId).subscribe({
      next: (response: any) => {
        this.inspectionStep = response.data;
        this.loadingInspectionStep = false;
        this.setInspectionQuestion(0);
      },
      error: (error) => {
        this.loadingInspectionStep = false;
      }
    });
  }
  fileSelected(event) {
    // ToDo: handle video
    this.fileObjUrl = undefined;
    this.fileDataUrl = undefined;
    if (event.target.files && event.target.files[0]) {
      this.selectedFile = event.target.files[0];
      const reader = new FileReader();
      reader.onload = (event: any) => {
        this.fileDataUrl  = event.target.result;
        this.fileObjUrl =  window.URL.createObjectURL(this.selectedFile);
        this.uploadNextFile = true;
        this.fileIsUnsaved = true;
      };
      reader.readAsDataURL(event.target.files[0]);
    }
  }
  retake() {
    this.clearFile();
    this.triggerUpload();
  }
  clearFile() {
    this.fileObjUrl = undefined;
    this.fileDataUrl = undefined;
    this.selectedFile = undefined;
    this.fileIsUnsaved = false;
  }
  submit() {
    if (this.submitting || !this.selectedFile) {
      return ;
    }
    this.submitting = true;
    // save and emit new file
    const formData = new FormData();
    formData.set('file', this.selectedFile);
    if (this.rejectedFile) {
      formData.set('fileId', this.rejectedFile.id);
    }
    this.customerInspectionStepProvider.update(
      this.customer.companyId,
      this.customer.id,
      this._customerInspectionStep.id,
      formData,
      true
    ).subscribe({
        next: (response: any) => {
          // ToDo: handle taking multiple files
          this.clearFile();
          Object.assign(this._customerInspectionStep, response.data);
          this.submitting = false;
          if (!this.nrFilesNeeded) {
            // all files received - emit step completed
            this.onComplete.emit(response.data);
          } else if (this.rejectedFile) {
            // show the user the first step again( with their submitted image and reject reason )
            this.uploadNextFile = false;
          }
        },
        error: (error) => {
          this.submitting = false;
        },
      });
  }
  submitCantTakeImageReason() {
    if (this.submitting) {
      return ;
    }
    this.submitting = true;
    this.customerInspectionStepProvider.update(
      this.customer.companyId,
      this.customer.id,
      this._customerInspectionStep.id,
      {customerNote: this.cantTakeImageReason}
    ).subscribe({
      next: (response: any) => {
        // skip this step
        this.cantTakeImageReason = undefined;
        this.showCantTakeImage = false;
        Object.assign(this._customerInspectionStep, response.data);
        this.onComplete.emit(response.data);
        this.submitting = false;
      },
      error: (error) => {
        this.submitting = false;
      },
    });
  }
  completeInspectionStep() {
    // Inspection step is completed if all the requested files have been uploaded
    // and no steps/files are "pending" or "rejected"
    // ToDo: handle rejection
    this.onComplete.next(this.customerInspectionStep);
  }
  toCamelCase(str) {
    if (!str) {
      return ;
    }
    return str.slice(0, 1).toUpperCase() + str.slice(1);
  }

  triggerUpload() {
    const input = document.getElementById('customer-file');
    input.click();
  }
  getFileUrl(fileUuid) {
    return `${this.apiUrl}file/${fileUuid}`;
  }
  getBgUrl(fileUuid) {
    return `url(${this.apiUrl}file/${fileUuid})`;
  }
  isQuestionVisible(question, questions) {
    if (question.parentQuestionId) {
      const parentQuestion: Question = questions.filter(q => q.id === question.parentQuestionId)[0];
      if (question.parentAnswer) {
        if (!parentQuestion.answer) {
          return false;
        }
        if (parentQuestion.answerType === 'bool') {
          return parseInt(question.parentAnswer) === parseInt(parentQuestion.answer.value);
        } else {
          return question.parentAnswer === parentQuestion.answer.value;
        }
      }
    }
    return true;
  }
  questionAnswered(evt) {
    if (evt && evt.id) {
      this.currentQuestion.answer = evt;
      // update parentQuestion reference
      this._customerInspectionStep.inspectionStep.questions
        .filter(q => q.parentQuestionId === this.currentQuestion.id)
        .forEach((question) => {
          question.parentQuestion = this.currentQuestion;
        }
      );
    }
    this.setInspectionQuestion(this.currentQuestionIndex + 1);
  }

  get rejectedFile() {
    if (!this.customerInspectionStep || !this.customerInspectionStep.files) {
      return ;
    }
    return this.customerInspectionStep.files.filter(f => f.status === 'rejected')[0];
  }
  get confirmBtnText() {
    if (this.fileIsUnsaved) {
      return 'Submit ' + this.inspectionStep.type;
    }
    if (this.nrFilesNeeded) {
      return 'Upload ' + this.inspectionStep.type + ' ' + (this.nrFilesUploaded + 1) + ' of ' + this.inspectionStep.nrFiles;
    }
    return 'Next';
  }
  get nrFilesUploaded() {
    if (!this.customerInspectionStep || !this.customerInspectionStep.files) {
      return 0;
    }
    return this.customerInspectionStep.files.filter(f => f.status !== 'rejected').length;
  }
  get nrFilesNeeded() {
    if (!this.customerInspectionStep || !this.inspectionStep) {
      return 0;
    }
    if (!Array.isArray(this.customerInspectionStep.files)) {
      console.log('Error: Inspection steps must include files array');
      return 0;
    }
    const nrValidFilesUploaded = this.customerInspectionStep.files.filter(f => f.status !== 'rejected');
    const filesNeeded  = this.inspectionStep.nrFiles - nrValidFilesUploaded.length;
    return filesNeeded < 0 ? 0 : filesNeeded;
  }
}
