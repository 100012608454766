import { Component, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { Customer } from '../../models/customer';
import { User } from '../../models/user';
import { Company } from '../../models/company';
import { CustomerProvider } from '../../providers/customer.provider';
import { TankInspectionProvider } from '../../providers/tank-inspection.provider';
import { UserProvider } from "../../providers/user.provider"
import { TankSizeProvider } from "../../providers/tank-size.provider"
import { CompanyProvider } from '../../providers/company.provider';
import { environment } from '../../../environments/environment';

@Component({
  selector: 'app-customer-inspection',
  templateUrl: './customer-inspection.component.html',
  styleUrls: ['./customer-inspection.component.scss']
})
export class CustomerInspectionComponent {
  @ViewChild('form') public form: NgForm;
  apiUrl = environment.apiUrl;
  customer: Customer;
  company: Company;
  loading = true;
  /*
  * inspection steps:
  * 1) read welcome message about the inspection
  * 2) verify and confirm customer information
  * 3) enter info about customer tanks
  * 4) [step repeats until all files have been uploaded] - upload requested file for step N
  *    Customer returns to step 4 if company has rejected any files.
  * 5) Certify affidavit
  * 6) All done - thank you notice
  * */
  currentStep = 1;
  validationErrors: any = {};
  editCustomer = false;
  savingCustomer = false;
  creatingTankInspections = false;
  tankInspections: Array<{
    id?: number,
    status: string,
    tankSize?: {
      id: number,
      value: string
    },
    tankAge?: string,
    _errors?: any
  }> = [];
  nrTanks: number = 1;
  tankSizes: Array<{id: number, value: string}> = [];
  tankAges: string[] = [
    '< 10',
    '10-20',
    '20+'
  ];
  currentCustomerInspectionStep: any;
  currentTankInspection: any;
  customerInfoLoaded = false;
  externalTankInfo: any;
  constructor(
    private tankInspectionProvider: TankInspectionProvider,
    private tankSizeProvider: TankSizeProvider,
    private userProvider: UserProvider,
    private companyProvider: CompanyProvider,
    private customerProvider: CustomerProvider,
    private toastr: ToastrService,
    private route: ActivatedRoute) {
    localStorage.clear();
    this.route.paramMap.subscribe(params => {
      const customerId = parseInt(params.get('customerId'), 10);
      const companyId = parseInt(params.get('companyId'), 10);
      //const token = this.route.snapshot.queryParamMap.get('token');
      const token = params.get('token');

      if (!token) {
        return this.toastr.error('Inspection link is invalid', 'Error');
      }

      // get jwt and customer info using inspection link token
      // then load company info followed by tank inspections that the user is about to perform.
      this.customerProvider.tokenLogin(token).subscribe({
        next: (response: any) => {
          const {token, customer} = response.data;
          if (!token || !customer) {
            return this.toastr.error('Inspection link is invalid', 'Error');
          }
          window['cust'] = this;
          this.customer = customer;
          localStorage.setItem('user', JSON.stringify(customer));
          localStorage.setItem('jwt', token);
          this.userProvider.loggedInUserSubject.next(customer);
          this.customerInfoLoaded = true;
          this.companyProvider.get(customer.companyId).subscribe({
            next: (response: any) => {
              this.company = response.data;
              this.loading = false;
              this.loadExternalTankInfo();
              this.loadTankInspections(() => {
                const pendingTankInspections = this.tankInspections.filter((inspection) => {
                  return inspection.status === 'pending' || inspection.status === 'inprogress';
                });
                if (pendingTankInspections.length) {
                  // user has pending steps - continue from step 4
                  // note rejected steps must be retaken and are also considered pending
                  // ToDo: handle error state
                  this.currentStep = 4;
                  this.startNextInspectionStep();
                } else if(this.tankInspections.length) {
                  // inspection is already completed
                  this.completeInspection();
                } else {

                }
                // else - currentStep remains 1 and user starts entering their tank information
              });
            },
            error: () => {
              this.customerInfoLoaded = true;
              this.loading = false;
            }
          });
        },
        error: (error) => {
          return this.toastr.error('Could not log in:' + error.message, 'Error');
        }
      });
    });
  }
  ngOnInit() {
    this.tankSizeProvider.get().subscribe((response: any) => {
      this.tankSizes = response.data;
    });
  }
  loadExternalTankInfo () {
    /*
    * Check if url contains a reference to additional tank info that was provided by "Current" platform
    * */
    const extIdMatch = window.location.hash.match(/extId=([0-9]+)/i);
    if (extIdMatch && extIdMatch[1]) {
      // load external info about this inspection( info is provided by "Current" integration )
      this.tankInspectionProvider.getExternalInfo(
        this.customer.companyId,
        this.customer.id,
        parseInt(extIdMatch[1], 10)
      ).subscribe({
        next: (response: any) => {
          if (response && response.data && response.data.tankId) {
            this.externalTankInfo = response.data;
          }
        }
      });
    }
  }
  nextStep() {
    this.currentStep += 1;
    if (this.currentStep === 3) {
      this.onNrTanksChange();
    }
    if (this.currentStep === 4) {
      // user starts taking pictures or retakes rejected pictures
      this.startNextInspectionStep();
    }
  }

  getNextTankInspection() {
    if (!this.tankInspections) {
      return ;
    }
    const pendingTankInspections = this.tankInspections.filter((inspection) => {
      return inspection.status === 'pending' || inspection.status === 'inprogress';
    });
    return pendingTankInspections[0];
  }
  getInspectionStepsNeedingAttention(tankInspection: any) {
    return tankInspection.customerInspectionSteps.filter((inspection) => {
      return inspection.status === 'pending' || inspection.status === 'rejected';
    });
  }
  getInspectionStepsNotNeedingAttention(tankInspection: any) {
    return tankInspection.customerInspectionSteps.filter((inspection) => {
      return ['submitted', 'completed', 'canceled'].indexOf(inspection.status) !== -1;
    });
  }
  startNextInspectionStep() {
    /*
    * Scenarios:
    * Pending tank inspection with pending inspection steps:
    *   Set current tank inspection and inspection step. User starts uploading images
    * Pending tank inspection with all inspection steps submitted:
    *   User signs affidavit, submitting tank inspection to Admins
    * ToDo: Pending tank inspection with inspection steps having errors
    * Submitted tank inspection with all steps submitted:
    *   Customer sees a thank you notice( he has nothing to do for now )
    * */
    const pendingTankInspection: any = this.getNextTankInspection();
    if (!pendingTankInspection) {
      // show thank you
      this.currentStep = 6;
      return ;
    }
    this.currentTankInspection = pendingTankInspection;
    const pendingCustomerSteps = this.getInspectionStepsNeedingAttention(pendingTankInspection);
    const stepsNotNeedingAttention = this.getInspectionStepsNotNeedingAttention(pendingTankInspection);
    const pendingCustomerStep = pendingCustomerSteps[0];

    if (
      stepsNotNeedingAttention.length &&
      stepsNotNeedingAttention.length === pendingTankInspection.customerInspectionSteps.length
    ) {
      // all tank inspection steps have been submitted/completed by the customer.
      // show affidavit
      this.currentStep = 5;
      this.currentCustomerInspectionStep = undefined;
      return ;
    }


    if (pendingCustomerStep) {
      this.currentCustomerInspectionStep = pendingCustomerStep;
      if (this.currentStep > 4) {
        // if previous tank inspection was completed
        // and customer has another tank, start pending steps again
        this.currentStep = 4;
      }
      return ;
    } else {
      // ToDo: inspection step error states
      throw new Error('Unhandled scenario');
    }

  }
  completeInspection() {
    this.currentCustomerInspectionStep = undefined;
    this.currentTankInspection = undefined;
    this.currentStep = 6;
  }
  loadTankInspections(onData?: any) {
    this.tankInspectionProvider.list(this.company.id, this.customer.id, {}).subscribe({
      next: (response: any) => {
        if (response.data && response.data.length) {
          this.tankInspections = response.data;
          if (typeof onData === 'function') {
            onData();
          }
        }
      },
      error: (error) => {
        this.toastr.error('Could not load inspection data: ' + error.message, 'Error');
      }
    });
  }
  updateCustomerInfo() {
    if (this.savingCustomer) {
      return ;
    }
    this.savingCustomer = true;
    this.validationErrors = {};
    this.customerProvider.update(this.customer.companyId, this.customer.id, this.customer).subscribe({
      next: (response: any) => {
        this.toastr.success('Information updated', 'Success');
        this.savingCustomer = false;
        this.editCustomer = false;
        this.nextStep();
      },
      error: (error) => {
        this.savingCustomer = false;
        if (error && error.errors) {
          error.errors.forEach((e) => {
            this.validationErrors['customer-' + e.param] = e.msg;
          });
        }
      }
    });
  }
  mapExternalTank () {
    // returns this.tankSizes option based on external tank info given
    const extSize = parseInt(this.externalTankInfo.tankSize, 10);
    const exactMatch = this.tankSizes.filter(size => parseInt(size.value, 10) === extSize)[0];
    if (exactMatch) {
      return exactMatch;
    }
    const matchLtGt = this.tankSizes.filter(size => {
      const matchInfo = size.value.match(/(ess|arger) than ([0-9]+)/i);
      if (matchInfo && matchInfo[1]) {
        const value = parseInt(matchInfo[2]);
        if (matchInfo[1] === 'ess' && extSize < value) {
          return true;
        } else if (matchInfo[1] === 'arger' && extSize > value) {
          return true;
        }
      }
    })[0];
    if (matchLtGt) {
      return matchLtGt;
    } else {
      const dontKnow = this.tankSizes.filter(size => size.value.match('know'))[0];
      if (!dontKnow) {
        console.warn('Missing tank size option "I don\'t know" ');
      }
      return dontKnow || this.tankSizes[0];
    }
  }
  onNrTanksChange() {
    const diff = this.nrTanks - this.tankInspections.length;
    const absDiff = Math.abs(diff);
    let i;
    if (diff < 0) {
      // remove tanks
      this.tankInspections.splice(this.nrTanks);
    } else if (diff > 0 ) {
      // add tanks
      for (i = 0; i < absDiff; i++) {
        this.tankInspections.push({
          status: 'pending',
          tankSize: this.mapExternalTank(),
          tankAge: this.tankAges[0],
          _errors: {},
        });
      }
    }
  }
  inspectionStepCompleted(updatedInspectionStep) {
    this.startNextInspectionStep();
  }
  createTankInspections() {
    if (this.creatingTankInspections) {
      return ;
    }
    this.validationErrors = {};
    this.creatingTankInspections = true;

    const inspectionsToCreate: any[] = this.tankInspections.slice();
    const createInspection = () => {
      const tankInspection = inspectionsToCreate.shift();
      if (!tankInspection) {
        // Saving complete
        this.creatingTankInspections = false;
        this.toastr.success('Tank information saved', 'Success');

        this.loadTankInspections(() => {
          // refresh tank inspections with includes and start completing inspection steps.
          this.nextStep();
        });

        return ;
      }
      if (tankInspection.id) {
        // already saved - move on to next inspection entry
        createInspection();
      }
      tankInspection._errors = {};
      const payload = {
        tankAge: tankInspection.tankAge,
        tankSize: tankInspection.tankSize.id,
        externalId: this.externalTankInfo ? this.externalTankInfo.id : undefined,
      };
      this.tankInspectionProvider.create(this.company.id, this.customer.id, payload).subscribe({
        next: (response: any) => {
          tankInspection.id = response.data.id;
          createInspection();
        },
        error: (error) => {
          this.creatingTankInspections = false;
          if (error && error.errors) {
            error.errors.forEach((e) => {
              tankInspection._errors[e.param] = e.msg;
            });
          }
        }
      });
    };

    createInspection();
  }
  affidavitComplete(affidavits) {
    // signing affidavit updates the tank inspection.
    this.tankInspectionProvider.get(this.company.id, this.customer.id, this.currentTankInspection.id)
      .subscribe({
        next: (response: any) => {
          Object.assign(this.currentTankInspection, response.data);
          this.startNextInspectionStep();
        },
        error: (error) => {
          this.toastr.error('Could not load inspection data: ' + error.message, 'Error');
        }
      });
  }
  getError(id) {
    return this.validationErrors[id]
  }
  getLogoUrl() {
    if (this.company && this.company.logo) {
      return this.apiUrl + 'file/' + this.company.logo.uuid;
    }
    return '/assets/img/tank-tracker-logo.png';
  }
}
