import { Injectable } from '@angular/core';
import { HttpClient, HttpParams, HttpHeaders } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { ToastrService } from 'ngx-toastr';
import { catchError } from 'rxjs/operators';
import { throwError } from 'rxjs';
import { Router } from '@angular/router';

const API_HOST = environment.apiUrl;

@Injectable()
export class APIProvider {
  defaultHeaders = {
    'Content-Type': 'application/json',
    'Cache-Control': 'no-cache, no-store, must-revalidate',
    'Pragma': 'no-cache'
  };
  constructor(
    private http: HttpClient,
    private toastr: ToastrService,
    private router: Router
  ) {}
  /*
  * let headers = new Headers({
      'Content-Type': 'application/json', 'Authorization': 'Bearer ' + this.user.token,
      'Cache-Control': 'no-cache, no-store, must-revalidate',
      'Pragma': 'no-cache',
      'Expires': '0'
    });
  * */
  get(url, params?) {

    const token = localStorage.getItem('jwt');
    let  headers = new HttpHeaders(this.defaultHeaders);
    if (token) {
      headers = headers.append('Authorization', 'Bearer ' + token);
    }
    let queryParams = new HttpParams();
    if (params) {
      Object.keys(params).forEach((key) => {
        // ignore undefined param keys.
        const value = params[key];
        if (value === undefined) {
          return ;
        }
        if (typeof value === 'object') {
          queryParams = queryParams.append(key, JSON.stringify(value));
        } else {
          queryParams = queryParams.append(key, value);
        }
      });
    }

    return this.http.get(API_HOST + url, {
      headers,
      params: queryParams
    }).pipe(
      catchError(this.handleError('GET', url, params))
    );
  }

  post(url, body?, multipart = false) {
    const token = localStorage.getItem('jwt');
    const options: {headers?:HttpHeaders } = {
      headers: new HttpHeaders()
    };
    if (token) {
      options.headers = options.headers.append('Authorization', 'Bearer ' + token);
    }
    if (!multipart) {
      options.headers = options.headers.append('Content-Type', 'application/json');
    }
    return this.http.post(API_HOST + url, body, options)
      .pipe(
      catchError(this.handleError<any>('POST', url, body))
    );
  }
  put(url, body?, multipart = false) {
    const token = localStorage.getItem('jwt');
    let  headers = new HttpHeaders(this.defaultHeaders);
    if (token) {
      headers = headers.append('Authorization', 'Bearer ' + token);
    }
    if (multipart) {
      headers = headers.delete('Content-Type');
    }
    return this.http.put(API_HOST + url, body, {
      headers
    })
      .pipe(
        catchError(this.handleError<any>('PUT', url, body))
      );
  }
  delete(url) {
    const token = localStorage.getItem('jwt');
    let  headers = new HttpHeaders(this.defaultHeaders);
    if (token) {
      headers = headers.append('Authorization', 'Bearer ' + token);
    }

    return this.http.delete(API_HOST + url, {
      headers
    }).pipe(
      catchError(this.handleError('DELETE', url, {}))
    );
  }
  handleError<T>(method, url, params) {
    return (response: any) => {
      const error = response.error;
      console.log('Error', method, url, params, error);
      if (error.message === 'TokenExpiredError') {
        this.toastr.error('Your session has expired', 'Error');
        this.router.navigate(['/login']);
      } else {
        this.toastr.error(error.message || error, 'Error');
      }
      // Let the app keep running by returning an empty result.
      return throwError(error);
    };
  }
}
