import { Injectable } from '@angular/core';
import { APIProvider } from './api.provider';

@Injectable()
export class CustomerInspectionStepProvider {

  constructor(private api: APIProvider) {

  }

  get(companyId: number, customerId: number, stepId: number, params?) {
    return this.api.get(`company/${companyId}/customer/${customerId}/inspection-step/${stepId}`, params);
  }
  list(companyId: number, customerId: number, params?) {
    return this.api.get(`company/${companyId}/customer/${customerId}/inspection-step`, params);
  }
  update(companyId: number, customerId: number, stepId: number, payload, multipart = false) {
    return this.api.put(`company/${companyId}/customer/${customerId}/inspection-step/${stepId}`, payload, multipart);
  }
  answerQuestion(companyId: number, customerId: number, stepId: number, questionId: number, payload) {
    return this.api.post(
      `company/${companyId}/customer/${customerId}/inspection-step/${stepId}/question/${questionId}/answer`,
      payload
    );
  }
  updateQuestionAnswer(companyId: number, customerId: number, stepId: number, questionId: number, payload) {
    return this.api.put(
      `company/${companyId}/customer/${customerId}/inspection-step/${stepId}/question/${questionId}/answer/${payload.id}`,
      payload
    );
  }

  getRecommendations(companyId: number, customerId: number, stepId: number) {
    return this.api.get(`company/${companyId}/customer/${customerId}/inspection-step/${stepId}/recommendation`);
  }
  addRecommendation(companyId: number, customerId: number, stepId: number, payload) {
    return this.api.post(
      `company/${companyId}/customer/${customerId}/inspection-step/${stepId}/recommendation`,
      payload
    );
  }
  deleteRecommendation(companyId: number, customerId: number, stepId: number, recommendationId: number) {
    return this.api.delete(
      `company/${companyId}/customer/${customerId}/inspection-step/${stepId}/recommendation/${recommendationId}`
    );
  }
}
