import { Component, OnInit } from '@angular/core';
import { PlanProvider } from '../../providers/plan.provider';
import { ToastrService } from 'ngx-toastr';
import { Plan } from '../../models/plan';
@Component({
  selector: 'app-admin-plans',
  templateUrl: './admin-plans.component.html',
  styleUrls: ['./admin-plans.component.scss'],
  providers: []
})
export class AdminPlansComponent implements OnInit {
  loading = false;
  plans: Plan[] = [];
  constructor(
    private toastr: ToastrService,
    private planProvider: PlanProvider
  ) {}

  ngOnInit() {
    this.loadPlans();
  }
  loadPlans() {
    this.loading = true;
    this.planProvider.get()
      .subscribe({
        next: (response: any) => {
          this.plans = response.data;
          this.loading = false;
        },
        error: (error) => {
          this.loading = false;
          this.toastr.error('Could not load plans: ' + error.message, 'Error');
        }
      });
  }
  updatePlan(plan) {
    if (plan._saving) {
      return ;
    }
    plan._saving = true;
    this.planProvider.update(plan.id, plan)
      .subscribe({
        next: (response: any) => {
          plan._saving = false;
          plan._editing = false;
          this.toastr.success('Plan updated', 'Success');
        },
        error: (error) => {
          plan._saving = false;
        }
      });
  }
}
