<section *ngIf="inspectionStep" class="questions">
  <section *ngFor="let question of inspectionStep.questions">
    <div class="question" *ngIf="!question.parentQuestionId">
      <!-- dont display questions that depend on other questions-->
      <div class="row">
        <div class="col-8">
          {{question.text}}
        </div>
        <div class="col-4">
          <button class="btn btn-slick small" (click)="startEditingQuestion(question)">edit</button>
          <button class="btn btn-slick small warning" (click)="deleteQuestion(inspectionStep, question)">
            delete
            <img *ngIf="inspectionStep._deletingQuestion" src="assets/img/loader.gif" class='loader' alt="loading...">
          </button>
        </div>
      </div>
      <!-- show 1 tier of sub questions -->
      <section *ngIf="question.subQuestions && question.subQuestions.length" class="questions-t1">
        <div class="row" *ngFor="let subQuestion of question.subQuestions">
          <div class="col-8">
            {{subQuestion.text}}
          </div>
          <div class="col-4">
            <button class="btn btn-slick small" (click)="startEditingQuestion(subQuestion)">edit</button>
            <button class="btn btn-slick small warning" (click)="deleteQuestion(inspectionStep, subQuestion, question)">
              delete
              <img *ngIf="inspectionStep._deletingQuestion" src="assets/img/loader.gif" class='loader' alt="loading...">
            </button>
          </div>
        </div>
      </section>
    </div>
  </section>

  <div class="form-group" id="edit-question-section">
    <label for="question">{{inspectionStepQuestion.id ? 'Edit': 'Enter' }} your question</label>
    <textarea
      style="margin: 0.4em; min-height: 8em;"
      class="form-control"
      name="question"
      id="question"
      [(ngModel)]="inspectionStepQuestion.text"
      placeholder="enter question"
      ngModel
    >
    </textarea>
    <div class="row">
      <div class="col-6">
        <label for="question-label">Label</label>
      </div>
      <div class="col-6">
        <input
          id="question-label"
          [(ngModel)]="inspectionStepQuestion.label"
          title="Label should describe the question and not change once it is assigned"
        >
      </div>
    </div>

    <div class="row">
      <div class="col-6">
        <label for="question-answer-type">Answer Type</label>
      </div>
      <div class="col-6">
        <select
          class="custom-select"
          id="question-answer-type"
          name="question-answer-type"
          [(ngModel)]="inspectionStepQuestion.answerType"
          title="select question answer type"
        >
          <option
            *ngFor="let type of answerTypes"
            value="{{type.value}}">
            {{type.label}}
          </option>
        </select>
      </div>
    </div>

    <div class="row" style="margin-top: 0.4em;">
      <div class="col-6">
        <label for="question-optional">Optional</label>
      </div>
      <div class="col-6">
        <input
          id="question-optional"
          type="checkbox"
          [(ngModel)]="inspectionStepQuestion.optional"
        >
      </div>
    </div>
    <div class="parent-question-box">
      <div class="row">
        <div class="col-4">
          <label for="question-parent">Visible if question</label>
        </div>
        <div class="col-8">
          <select
            class="custom-select"
            id="question-parent"
            name="question-parent"
            [(ngModel)]="inspectionStepQuestion.parentQuestion"
            title="does this question depend on another questions' answer"
          >
            <option
              *ngFor="let q of inspectionStep.questions"
              [ngValue]="q">
              {{q.text}}
            </option>
          </select>
        </div>
      </div>

      <div class="row" *ngIf="inspectionStepQuestion.parentQuestion" style="margin-top: 0.4em;">
        <div class="col-4">
          <label for="question-parent-answer-1" *ngIf="inspectionStepQuestion.parentQuestion.answerType === 'bool'">
            checkbox is
            <span *ngIf="!inspectionStepQuestion.parentAnswer">not</span>
            checked
          </label>

          <label for="question-parent-answer-2" *ngIf="inspectionStepQuestion.parentQuestion.answerType !== 'bool'">
            has an answer
          </label>
        </div>
        <div class="col-8">
          <input
            id="question-parent-answer-1"
            type="checkbox"
            [(ngModel)]="inspectionStepQuestion.parentAnswer"
            *ngIf="inspectionStepQuestion.parentQuestion.answerType === 'bool'"
          >
          <input
            id="question-parent-answer-2"
            title="type in the answer"
            placeholder="type answer"
            [(ngModel)]="inspectionStepQuestion.parentAnswer"
            *ngIf="inspectionStepQuestion.parentQuestion.answerType !== 'bool'"
          >
        </div>
      </div>
    </div>
    <section class="filters-box">
      <label>This question is visible to customers from states</label>
      <div class="state-checkboxes">
        <div class="row" *ngFor="let filter of stateFilter">
          <div class="col-10">
            <label for="checkbox-{{filter.state}}">{{filter.state}}</label>
          </div>
          <div class="col-2">
            <input
              type="checkbox"
              id="checkbox-{{filter.state}}"
              value="{{filter.state}}"
              [(ngModel)]="filter.checked"
            >
          </div>
        </div>
      </div>
    </section>
    <section class="question-target">
      <div class="row">
        <div class="col-10">
          <label for="checkbox-answer-company">Answered by company</label>
        </div>
        <div class="col-2">
          <input
            type="checkbox"
            id="checkbox-answer-company"
            value="1"
            [(ngModel)]="inspectionStepQuestion.answeredByCompany"
            title="Check this box if question will be answered by the company"
          >
        </div>
      </div>
      <div class="row">
        <div class="col-10">
          <label for="checkbox-answer-customer">Answered by customer</label>
        </div>
        <div class="col-2">
          <input
            type="checkbox"
            id="checkbox-answer-customer"
            value="1"
            [(ngModel)]="inspectionStepQuestion.answeredByCustomer"
            title="Check this box if question will be answered by the company"
          >
        </div>
      </div>
    </section>
    <br>
    <button class="btn btn-slick small float-right" (click)="saveQuestion(inspectionStep)">
      {{inspectionStepQuestion.id ? 'Edit': 'Add'}} Question
      <img *ngIf="savingQuestions" src="assets/img/loader.gif" class='loader' alt="loading...">
    </button>
    <button class="btn btn-slick light small float-right" (click)="onClose.emit(true)">
      back
    </button>
  </div>
</section>
