<section class="login" *ngIf="!passwordReset">
  <div class="row">
    <div class="col-12">
      <h1>Login</h1>
    </div>
  </div>
  <hr>
  <div class="row">
    <div class="col-12">
      <div class="form-group">
        <label for="email">Email Address</label>
        <input type="email"
               required id="email"
               class="form-control"
               name="email"
               maxlength="255"
               [(ngModel)]="email"
               ngModel
        />
      </div>
    </div>
    <div class="col-12">
      <div class="form-group">
        <label for="password">Password</label>
        <input
          type="password"
          required
          id="password"
          class="form-control"
          name="password"
          maxlength="255"
          [(ngModel)]="password"
          ngModel
          (keydown)="onKeyDown($event)"
        />
      </div>
    </div>
  </div>
  <div class="row">
    <div class="col-6">
      <span class="forgot-password" (click)="passwordReset = true;">Forgot Password</span>
    </div>
    <div class="col-6">
      <button class="btn btn-slick" [disabled]="!email || !password" (click)="login()">
        Login
        <img *ngIf="loading" src="assets/img/loader.gif" class='loader' alt="loading...">
      </button>
    </div>
  </div>
</section>


<section class="login" *ngIf="passwordReset">
  <div class="row">
    <div class="col-12">
      <h1>Password Reset</h1>
    </div>
  </div>
  <hr>
  <div class="row">
    <div class="col-12">
      <div class="form-group">
        <label for="email-reset">Email Address</label>
        <input type="email"
               required id="email-reset"
               class="form-control"
               name="email"
               maxlength="255"
               [(ngModel)]="email"
               ngModel
        />
      </div>
    </div>
  </div>
  <div class="row">
    <div class="col-3">
      <span class="forgot-password" (click)="passwordReset = false;">Cancel</span>
    </div>
    <div class="col-9">
      <button class="btn btn-slick float-right" [disabled]="!email" (click)="resetPassword()">
        Send Reset Link
        <img *ngIf="loading" src="assets/img/loader.gif" class='loader' alt="loading...">
      </button>
    </div>
  </div>
</section>
