import { Injectable } from '@angular/core';
import { APIProvider } from './api.provider';
import { Customer } from '../models/customer';

@Injectable()
export class CustomerProvider {

  constructor(private api: APIProvider) {

  }

  get(companyId: number, customerId: number) {
    return this.api.get(`company/${companyId}/customer/${customerId}`);
  }
  tokenLogin(token: string) {
    return this.api.get(`customer/token/${token}`);
  }
  list(companyId: number, params?) {
    return this.api.get(`company/${companyId}/customer`, params);
  }
  create(companyId: number, params) {
    return this.api.post(`company/${companyId}/customer`, params);
  }
  update(companyId: number, id, params) {

    return this.api.put(`company/${companyId}/customer/${id}`, params);
  }
  import(companyId: number, params) {
    return this.api.post(`company/${companyId}/customer/import`, params, true);
  }
  delete(companyId: number, customerId: number) {
    return this.api.delete(`company/${companyId}/customer/${customerId}`);
  }
  dismissCustomerChanges(companyId: number, customerId: number) {
    return this.api.put(`company/${companyId}/customer/${customerId}/dismiss-customer-changes`);
  }
  /*
  * @param {int | int[]} - customerId customer(s) who will be assigned a staff
  * */
  assignStaff(companyId: number, customerId: number, staffId: number, payload) {
    if (Array.isArray(customerId)) {
      payload.customerIds = customerId;
      return this.api.put(`company/${companyId}/customer/assign-staff/${staffId}`, payload);
    } else {
      return this.api.put(`company/${companyId}/customer/${customerId}/assign-staff/${staffId}`, payload);
    }
  }
  startInspection(
    companyId: number,
    customerId: number,
    externalInfo?: {
      customerId: number,
      tankId: number, // id of the tank on "Current" platform, related to this inspection
      tankSize?: number,
      location?: string,
      description?: string
      inspectionId?: number;
    }) {
    if (Array.isArray(customerId)) {
      return this.api.put(`company/${companyId}/customer/start-inspection`, {customerIds: customerId, externalInfo});
    } else {
      return this.api.put(`company/${companyId}/customer/${customerId}/start-inspection`, {externalInfo});
    }
  }
  resendInspectionRequest(companyId: number, customerId: number) {
    return this.api.post(`company/${companyId}/customer/${customerId}/resend-inspection-request`);
  }
}
