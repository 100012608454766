<section class="user-password-setup">
  <div class="row">
    <div class="col-12">
      <h1>Set your password</h1>
      <div style="margin: 1em 0; font-size: 0.9em;">
        Password must be at least 5 characters long
        and contain a number
      </div>
    </div>
  </div>
  <hr>
  <div class="row">
    <div class="col-12">
      <div class="form-group">
        <label for="password">Password</label>
        <input type="password" required id="password" class="form-control" name="password" maxlength="255" [(ngModel)]="password" ngModel/>
      </div>
    </div>
    <div class="col-12">
      <div class="form-group">
        <label for="passwordConfirm" [ngClass]="{'ng-invalid': (password.length && !passwordValid())}" >Confirm Password</label>
        <input type="password" required id="passwordConfirm" class="form-control" name="passwordConfirm" maxlength="255" [(ngModel)]="passwordConfirm" ngModel/>
      </div>
    </div>
  </div>
  <div class="row">
    <div class="col-6"></div>
    <div class="col-6">
      <button class="btn btn-slick" [disabled]="!passwordValid() || loading" (click)="setPassword()">
        Set Password
        <img *ngIf="loading" src="assets/img/loader.gif" class='loader' alt="loading...">
      </button>
    </div>
  </div>
</section>
