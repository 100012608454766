import { Component, OnInit } from '@angular/core';
import { Customer, CustomerFields } from '../../models/customer';
import { Question } from '../../models/question';
import { QuestionAnswer } from '../../models/question-answer';
import { CustomerInspectionStep } from '../../models/customer-inspection-step';
import { TankInspection } from '../../models/tank-inspection';
import { TankInspectionProvider } from '../../providers/tank-inspection.provider';
import { CustomerInspectionStepProvider } from '../../providers/customer-inspection-step.provider';
import { CustomerProvider } from '../../providers/customer.provider';
import { ToastrService } from 'ngx-toastr';
import { ActivatedRoute, Router } from '@angular/router';
import { environment } from '../../../environments/environment';

@Component({
  selector: 'app-inspection-detail',
  templateUrl: './inspection-detail.component.html',
  styleUrls: ['./inspection-detail.component.scss'],
  providers: []
})
export class InspectionDetailComponent implements OnInit {
  customer: Customer;
  tankInspection: TankInspection;
  loading = false;
  apiUrl = environment.apiUrl;
  selectedCustomerStep: CustomerInspectionStep;
  fullScreenFile;
  customerFields = CustomerFields;
  updatingCustomer: boolean;
  showCertificate = false;
  checklistUuid: string;
  /*
  * states like Vermont require a checklist to be filled
  * with each inspection.
  * */
  checklistAvailable = false;
  constructor(
    public tankInspectionProvider: TankInspectionProvider,
    public customerInspectionStepProvider: CustomerInspectionStepProvider,
    public customerProvider: CustomerProvider,
    public toastr: ToastrService,
    public route: ActivatedRoute,
    public router: Router,
  ) {
  }

  ngOnInit() {
    this.loading = true;
    this.route.paramMap.subscribe((params: any) => {
      const companyId = params.get('companyId');
      const tankInspectionId = params.get('inspectionId');
      const customerId = params.get('customerId');
      document.addEventListener('keydown', this.onKeyDown.bind(this));
      this.tankInspectionProvider.get(companyId, customerId, tankInspectionId)
        .subscribe({
          next: (response: any) => {
            this.tankInspection = response.data;
            this.customer = this.tankInspection.customer;

            if ('vermont'.match(new RegExp(this.customer.state, 'gi'))) {
              this.checklistAvailable = true;
            }
            if (this.customer.fieldsChangedByCustomer) {
              try {
                this.customer.fieldsChangedByCustomer = JSON.parse(this.customer.fieldsChangedByCustomer);
              } catch(err) {
                console.log('Error: could not parse fields changed by customer');
              }
            }
            this.loading = false;
          },
          error: (error) => {
            this.toastr.error('Could not load inspection data: ' + error.message, 'Error');
            this.loading = false;
          }
        });
    });
  }
  isQuestionVisible(question, questions) {
    if (question.parentQuestionId) {
      const parentQuestion: Question = questions.filter(q => q.id === question.parentQuestionId)[0];
      if (question.parentAnswer) {
        if (!parentQuestion.answer) {
          return false;
        }
        if (parentQuestion.answerType === 'bool') {
          // todo handle question answers
          return parseInt(question.parentAnswer) === parseInt(parentQuestion.answer.value);
        } else {
          return question.parentAnswer === parentQuestion.answer.value;
        }
      }
    }
    return true;
  }
  downloadChecklist() {
    window.open(`${this.apiUrl}company/${this.customer.companyId}/customer/${this.customer.id}/tank-inspection/${this.tankInspection.id}/download-checklist`);
  }
  /*
  isQuestionFilteredOut(question) {
    if (!question || typeof question.filters !== 'object') {
      return false;
    }
    if (Array.isArray(question.filters.states)) {
      // filter by customer state
      if (
        this.customer.state &&
        !question.filters.states.join(',').match(new RegExp(this.customer.state, 'i'))
      ) {
        return true;
      }
    }
    return false;
  }*/
  saveQuestionAnswer(answer: boolean, question: Question, step: CustomerInspectionStep) {
    if (question.answer) {
      question.answer.value = answer;
    } else {
      question.answer = {
        value: answer,
        questionId: question.id
      };
    }
    if (question.answerType === 'bool') {
      question.answer.value = [true, 1].join().includes(question.answer.value) ? 1: 0;
    }
    const saveFn = question.answer.id ? 'updateQuestionAnswer': 'answerQuestion';
    question.answer._saving = true;
    const payload = {
      value: question.answer.value,
      id: question.answer.id
    };
    delete question.answer._saved;
    this.customerInspectionStepProvider[saveFn](
      this.customer.companyId,
      this.customer.id,
      step.id,
      question.id,
      payload
    ).subscribe({
      next: (response: any) => {
        question.answer._saving = false;
        question.answer = response.data;
        question.answer._saved = true;
      },
      error: (error) => {
        this.toastr.error('Could not save question answer: ' + error.message, 'Error');
        question.answer._saving = false;
      }
    });
  }
  getFileUrl(file) {
    return this.apiUrl + 'file/' + file.uuid;
  }
  getBgUrl(file) {
    return `url(${this.getFileUrl(file)})`;
  }
  enterFullscreenReview(customerStep) {
    this.selectedCustomerStep = customerStep;
    this.fullScreenFile = customerStep.files[0];
  }
  exitFullscreenReview() {
    this.selectedCustomerStep = undefined;
    this.fullScreenFile = undefined;
  }
  onKeyDown(evt) {
    if (!this.fullScreenFile) {
      return ;
    }
    if (evt.keyCode === 27) {
      this.exitFullscreenReview();
    } else if (evt.keyCode === 37) {
      this.previousFullScreenFile();
    } else if (evt.keyCode === 39) {
      this.nextFullScreenFile();
    }
  }
  dismissCustomerChanges() {
    this.updatingCustomer = true;
    this.customerProvider.dismissCustomerChanges(
      this.customer.companyId,
      this.customer.id
    )
      .subscribe({
        next: () => {
          this.updatingCustomer = false;
          this.customer.fieldsChangedByCustomer = undefined;
        }
    });
  }
  nextFullScreenFile() {
    const index = this.selectedCustomerStep.files.indexOf(this.fullScreenFile);
    const nextFile = this.selectedCustomerStep.files[index + 1];
    if (!nextFile) {
      this.exitFullscreenReview();
    } else {
      setTimeout(() => {
        this.fullScreenFile = nextFile;
      }, 250);
    }
  }
  previousFullScreenFile() {
    const index = this.selectedCustomerStep.files.indexOf(this.fullScreenFile);
    const nextFile = this.selectedCustomerStep.files[index - 1];
    if (!nextFile) {
      return ;
    } else {
      setTimeout(() => {
        this.fullScreenFile = nextFile;
      }, 250);
    }
  }
  downloadCertificate() {
    window.open(`${this.apiUrl}company/${this.customer.companyId}/customer/${this.customer.id}/tank-inspection/${this.tankInspection.id}/certificate`);
  }
  downloadVermontChecklist() {
    window.open(`${this.apiUrl}company/${this.customer.companyId}/customer/${this.customer.id}/tank-inspection/${this.tankInspection.id}/vermont-checklist`);
  }
  toCamelCase(str) {
    if (!str) {
      return ;
    }
    return str.slice(0, 1).toUpperCase() + str.slice(1);
  }
}
