<section class="affidavit-section" *ngIf="!loadingAffidavitList">
  <div class="tank-label">
    Tank size: {{tankInspection.tankSize.value}}
  </div>
  <div style="text-align: center;">
    Please check on each box to acknowledge the statements.
  </div>

  <div class="row affidavit" *ngFor="let affidavit of customerAffidavits">
    <div class="col-3">
      <img class="check-image" (click)="affidavit.certified = !affidavit.certified"
           [src]="
              affidavit._loading ? 'assets/img/loader.gif':
              affidavit.certified ? '/assets/img/check-full-2.png' : '/assets/img/check-empty.png'"
           [alt]="affidavit.certified ? 'checked' : 'not checked'"
      >
    </div>
    <div class="col-9 text">
      {{affidavit.affidavit.text}}
    </div>
  </div>

  <button class="btn btn-slick" (click)="submitAffidavit()">
    Submit
    <img *ngIf="submitting" src="assets/img/loader.gif" class='loader' alt="loading...">
  </button>
</section>
<section class="affidavit-section" *ngIf="loadingAffidavitList" style="text-align: center; padding: 5em;">
  <img src="assets/img/loader.gif" class='loader' alt="loading...">
</section>
