<section class="user-information-form-wrapper">
  <!-- larger than XS -->
  <form #form="ngForm" class="d-none d-sm-block">
    <div class="row">
      <div class="col-6">
        <div class="form-group">
          <label for="firstName">First Name</label>
          <div class="form-value" *ngIf="!editMode">{{customer.firstName}}</div>
          <div class="error" *ngIf="getError('customer-firstName')">{{getError('customer-firstName')}}</div>
          <input *ngIf="editMode" required id="firstName" class="form-control" name="firstName" maxlength="255" [(ngModel)]="customer.firstName" ngModel/>
        </div>

        <div class="form-group">
          <label for="lastName">Last Name</label>
          <div class="form-value" *ngIf="!editMode">{{customer.lastName}}</div>
          <div class="error" *ngIf="getError('customer-lastName')">{{getError('customer-lastName')}}</div>
          <input *ngIf="editMode" required id="lastName" class="form-control" name="lastName" maxlength="255" [(ngModel)]="customer.lastName" ngModel/>
        </div>

        <div class="form-group">
          <label for="cellPhone">Cell Phone</label>
          <div class="form-value" *ngIf="!editMode">{{customer.cellPhone}}</div>
          <div class="error" *ngIf="getError('customer-cellPhone')">{{getError('customer-cellPhone')}}</div>
          <input *ngIf="editMode" type="tel" required id="cellPhone" class="form-control" name="cellPhone" maxlength="255" [(ngModel)]="customer.cellPhone" ngModel/>
        </div>

        <div class="form-group">
          <label for="email">Email</label>
          <div class="form-value" *ngIf="!editMode">{{customer.email}}</div>
          <div class="error" *ngIf="getError('customer-email')">{{getError('customer-email')}}</div>
          <input *ngIf="editMode" id="email" class="form-control" type="email" name="email" maxlength="255" [(ngModel)]="customer.email" ngModel/>
        </div>
      </div>
      <div class="col-6">

        <div class="form-group">
          <label for="streetAddress">Street Address</label>
          <div class="form-value" *ngIf="!editMode">{{customer.streetAddress}}</div>
          <div class="error" *ngIf="getError('customer-streetAddress')">{{getError('customer-streetAddress')}}</div>
          <input *ngIf="editMode" required id="streetAddress" class="form-control" name="streetAddress" maxlength="255" [(ngModel)]="customer.streetAddress" ngModel/>
        </div>
        <div class="form-group">
          <label for="town">Town</label>
          <div class="form-value" *ngIf="!editMode">{{customer.town}}</div>
          <div class="error" *ngIf="getError('customer-town')">{{getError('customer-town')}}</div>
          <input *ngIf="editMode" required id="town" class="form-control" name="town" maxlength="255" [(ngModel)]="customer.town" ngModel/>
        </div>
        <div class="form-group">
          <label for="state">State</label>
          <div class="form-value" *ngIf="!editMode">{{customer.state}}</div>
          <div class="error" *ngIf="getError('customer-state')">{{getError('customer-state')}}</div>
          <select
            *ngIf="editMode"
            required
            id="state"
            class="form-control"
            maxlength="255"
            [(ngModel)]="customer.state"
            name="state"
          >
            <option *ngFor="let state of stateListProvider.states" value="{{state}}">{{state}}</option>
          </select>
        </div>
        <div class="form-group">
          <label for="zip">Zip Code</label>
          <div class="form-value" *ngIf="!editMode">{{customer.zip}}</div>
          <div class="error" *ngIf="getError('customer-zip')">{{getError('customer-zip')}}</div>
          <input *ngIf="editMode" required id="zip" class="form-control" pattern="[0-9+\-]+" name="zip" maxlength="255" [(ngModel)]="customer.zip" ngModel/>
        </div>

        <div class="form-group" *ngIf="showInspectionType">
          <label>Type of Inspection:</label>
          <div class="row">
            <div class="col-8">
              <span>New Customer</span>
            </div>
            <div class="col-4">
              <div class="error" *ngIf="getError('customer-inspectionType')">
                {{getError('customer-inspectionType')}}
              </div>
              <input
                type="radio"
                required id="inspectionType"
                name="inspectionType"
                [(ngModel)]="customer.inspectionType"
                value="new"
                ngModel
              />
            </div>
          </div>

          <div class="row" title="Once every three years">
            <div class="col-8">
              <span>Routine Inspection</span>
            </div>
            <div class="col-4">
              <div class="error" *ngIf="getError('customer-inspectionType')">
                {{getError('customer-inspectionType')}}
              </div>
              <input
                type="radio"
                required id="inspectionType-2"
                name="inspectionType"
                [(ngModel)]="customer.inspectionType"
                value="routine"
                ngModel
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  </form>


  <!-- XS View -->
  <form #form="ngForm" class="d-block d-sm-none">
    <div class="row">
      <div class="col-4">
        <label for="firstName">First Name</label>
      </div>
      <div class="col-8">
        <div class="form-value" *ngIf="!editMode">{{customer.firstName}}</div>
        <div class="error" *ngIf="getError('customer-firstName')">{{getError('customer-firstName')}}</div>
        <input *ngIf="editMode" required id="firstName" class="form-control" name="firstName" maxlength="255" [(ngModel)]="customer.firstName" ngModel/>
      </div>
    </div>

    <div class="row">
      <div class="col-4">
        <label for="lastName">Last Name</label>
      </div>
      <div class="col-8">
        <div class="form-value" *ngIf="!editMode">{{customer.lastName}}</div>
        <div class="error" *ngIf="getError('customer-lastName')">{{getError('customer-lastName')}}</div>
        <input *ngIf="editMode" required id="lastName" class="form-control" name="lastName" maxlength="255" [(ngModel)]="customer.lastName" ngModel/>
      </div>
    </div>

    <div class="row">
      <div class="col-4">
        <label for="cellPhone">Cell Phone</label>
      </div>
      <div class="col-8">
        <div class="form-value" *ngIf="!editMode">{{customer.cellPhone}}</div>
        <div class="error" *ngIf="getError('customer-cellPhone')">{{getError('customer-cellPhone')}}</div>
        <input *ngIf="editMode" type="tel" required id="cellPhone" class="form-control" name="cellPhone" maxlength="255" [(ngModel)]="customer.cellPhone" ngModel/>
      </div>
    </div>

    <div class="row">
      <div class="col-4">
        <label for="email">Email</label>
      </div>
      <div class="col-8">
        <div class="form-value" *ngIf="!editMode">{{customer.email}}</div>
        <div class="error" *ngIf="getError('customer-email')">{{getError('customer-email')}}</div>
        <input *ngIf="editMode" id="email" class="form-control" type="email" name="email" maxlength="255" [(ngModel)]="customer.email" ngModel/>
      </div>
    </div>

    <div class="row">
      <div class="col-4">
        <label for="streetAddress">Street Address</label>
      </div>
      <div class="col-8">
        <div class="form-value" *ngIf="!editMode">{{customer.streetAddress}}</div>
        <div class="error" *ngIf="getError('customer-streetAddress')">{{getError('customer-streetAddress')}}</div>
        <input *ngIf="editMode" required id="streetAddress" class="form-control" name="streetAddress" maxlength="255" [(ngModel)]="customer.streetAddress" ngModel/>
      </div>
    </div>
    <div class="row">
      <div class="col-4">
        <label for="town">Town</label>
      </div>
      <div class="col-8">
        <div class="form-value" *ngIf="!editMode">{{customer.town}}</div>
        <div class="error" *ngIf="getError('customer-town')">{{getError('customer-town')}}</div>
        <input *ngIf="editMode" required id="town" class="form-control" name="town" maxlength="255" [(ngModel)]="customer.town" ngModel/>
      </div>
    </div>
    <div class="row">
      <div class="col-4">
        <label for="state">State</label>
      </div>
      <div class="col-8">
        <div class="form-value" *ngIf="!editMode">{{customer.state}}</div>
        <div class="error" *ngIf="getError('customer-state')">{{getError('customer-state')}}</div>
        <select
          *ngIf="editMode"
          required
          id="state"
          name="state"
          class="form-control"
          [(ngModel)]="customer.state"
        >
          <option *ngFor="let state of stateListProvider.states" value="{{state}}">{{state}}</option>
        </select>
      </div>
    </div>

    <div class="row">
      <div class="col-4">
        <label for="zip">Zip Code</label>
      </div>
      <div class="col-8">
        <div class="form-value" *ngIf="!editMode">{{customer.zip}}</div>
        <div class="error" *ngIf="getError('customer-zip')">{{getError('customer-zip')}}</div>
        <input *ngIf="editMode" required id="zip" class="form-control" pattern="[0-9+\-]+" name="zip" maxlength="255" [(ngModel)]="customer.zip" ngModel/>
      </div>
    </div>


    <div class="form-group" *ngIf="showInspectionType">
      <label>Type of Inspection</label>
      <div class="row">
        <div class="col-8">
          <label for="inspectionType" style="font-weight: normal;">
            New Customer
          </label>
        </div>
        <div class="col-4">
          <div class="error" *ngIf="getError('customer-inspectionType')">
            {{getError('customer-inspectionType')}}
          </div>
          <input
            type="radio"
            required id="inspectionType"
            name="inspectionType"
            [(ngModel)]="customer.inspectionType"
            value="new"
            ngModel
          />
        </div>
      </div>

      <div class="row" title="Once every three years">
        <div class="col-8">
          <label for="inspectionType-2" style="font-weight: normal;">
            Routine Inspection
          </label>
        </div>
        <div class="col-4">
          <div class="error" *ngIf="getError('customer-inspectionType')">
            {{getError('customer-inspectionType')}}
          </div>
          <input
            type="radio"
            required id="inspectionType-2"
            name="inspectionType"
            [(ngModel)]="customer.inspectionType"
            value="routine"
            ngModel
          />
        </div>
      </div>
    </div>


  </form>
</section>
