import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { InspectionStepProvider } from '../../providers/inspection-step.provider';
import { InspectionStep } from '../../models/inspection-step';
import { Recommendation } from '../../models/recommendation';

@Component({
  selector: 'app-admin-inspection-recommendation',
  templateUrl: './inspection-recommendation.component.html',
  styleUrls: ['./inspection-recommendation.component.scss'],
  providers: []
})
export class AdminInspectionRecommendationComponent implements OnInit {
  @Input() inspectionStep: InspectionStep;
  @Output() onClose = new EventEmitter<any>();
  savingRecommendation = false;
  recommendation: Recommendation = {
    label: '',
    text: '',
    inspectionStepId: 0
  } as Recommendation;

  constructor(
    private toastr: ToastrService,
    private inspectionStepProvider: InspectionStepProvider,
  ) {
  }

  ngOnInit() {
  }


  saveRecommendation(step) {
    this.savingRecommendation = true;
    let saveFn;

    if (this.recommendation.id) {
      saveFn = this.inspectionStepProvider.updateRecommendation(
        step.id,
        this.recommendation.id,
        this.recommendation
      );
    } else {
      saveFn = this.inspectionStepProvider.addRecommendation(
        step.id,
        this.recommendation
      );
    }
    saveFn.subscribe({
      next: (response: any) => {
        if (response && response.data && !this.recommendation.id) {
          if (!step.recommendations) {
            step.recommendations = [];
          }
          step.recommendations.push(response.data);
        }
        this.toastr.success('Recommendation saved successfully', 'Success');
        this.savingRecommendation = false;
      },
      error: (error) => {
        console.log('Saving Recommendation Error:', error);
        this.toastr.error('Could not save recommendation', 'Error');
        this.savingRecommendation = false;
      }
    });
  }
  deleteRecommendation(step, recommendation) {
    if (!confirm('Are you sure you want to delete this recommendation?')) {
      return;
    }
    step._deletingRecommendation = true;
    this.inspectionStepProvider.deleteRecommendation(
      step.id,
      recommendation.id
    ).subscribe({
      next: (response: any) => {
        const index = step.recommendations.indexOf(recommendation);
        if (index !== -1) {
          step.recommendations.splice(index, 1);
        }
        this.toastr.success('Recommendation deleted successfully', 'Success');
        step._deletingRecommendation = false;
      },
      error: (error) => {
        this.toastr.error('Could not delete recommendation' + error.message, 'Error');
        step._deletingRecommendation = false;
      }
    });
  }
  startEditingRecommendation(recommendation) {
    this.recommendation = recommendation;
    if (window['jQuery']) {
      setTimeout(() => {
        window['jQuery']('html, body').animate({
          scrollTop: window['jQuery']("#edit-recommendation-section").offset().top - 80
        }, 300);
      }, 32);
    }
  }
}
