<nav class="navbar navbar-expand-lg topmenu" *ngIf="topMenuEnabled">
  <a class="navbar-brand" href="#">
    <img src="assets/img/tank-tracker-logo-dark.png" class="nav-logo">
    <img *ngIf="showLoader" src="assets/img/loader.gif" class='loader' alt="loading...">
  </a>
  <button
    class="navbar-toggler"
    type="button"
    data-toggle="collapse"
    data-target="#navbarSupportedContent"
    aria-controls="navbarSupportedContent"
    aria-expanded="false"
    aria-label="Toggle navigation"
  >
    <img src="assets/img/settings-btn.png">
  </button>

  <div class="collapse navbar-collapse" id="navbarSupportedContent" *ngIf="topMenuLinks.length">
    <ul class="navbar-nav ml-auto">
      <li class="nav-item" *ngFor="let navLink of topMenuLinks">
        <a
          class="nav-link {{navLink.classes}}"
          routerLink="{{navLink.href}}"
          routerLinkActive="active"
          [routerLinkActiveOptions]="{exact: true}"
        >
          {{navLink.label}}
        </a>
      </li>
      <li class="nav-item" *ngIf="logoutEnabled">
        <a class="nav-link" (click)="logout()" href="#">Log Out</a>
      </li>
      <li class="nav-item" *ngIf="!logoutEnabled">
        <a class="nav-link" href="login">Log In</a>
      </li>
    </ul>
  </div>
  <div *ngIf="!topMenuLinks.length">
    <a class="nav-link float-right" *ngIf="logoutEnabled" (click)="logout()" href="#">Log Out</a>
    <a class="nav-link float-right" *ngIf="!logoutEnabled" href="login">Log In</a>
  </div>
</nav>

<nav class="sidemenu" *ngIf="sideMenuEnabled && sideMenuLinks.length">
  <div
    class="menu-item {{navLink.classes}}"
    routerLink="{{navLink.href}}"
    *ngFor="let navLink of sideMenuLinks"
    routerLinkActive="active"
    [routerLinkActiveOptions]="{exact: true}"
  >
    <img *ngIf="navLink.icon" src="assets/img/{{navLink.icon}}" alt="{{navLink.label}}">
    <div class="label d-none d-sm-block">{{navLink.label}}</div>
  </div>
</nav>
<section
  class="page-content"
  [ngClass]="{'has-sidemenu': sideMenuEnabled && sideMenuLinks.length, 'has-topmenu': topMenuEnabled}">
  <router-outlet></router-outlet>
</section>
