import { Component, OnInit } from '@angular/core';
import { UserProvider } from '../../providers/user.provider';
import { ToastrService } from 'ngx-toastr';
import { Router, ActivatedRoute } from '@angular/router';
import { environment } from '../../../environments/environment';
import { InspectionStepProvider } from '../../providers/inspection-step.provider';
import { InspectionStep } from '../../models/inspection-step';

@Component({
  selector: 'app-admin-inspection-settings',
  templateUrl: './inspection-settings.component.html',
  styleUrls: ['./inspection-settings.component.scss'],
  providers: []
})
export class AdminInspectionSettingsComponent implements OnInit {
  inspectionSteps: InspectionStep[] = [];
  newInspectionStep: InspectionStep = InspectionStep();
  savingInspectionStep = false;
  savingQuestions = false;
  loadingInspectionSteps = true;
  addingNewStep = false;
  deletingFile = false;
  editingStep: InspectionStep;
  uploadingFile = false;
  savingInstructions = false;
  selectedFile: any;
  selectedFileDataUrl: any;
  apiUrl = environment.apiUrl;
  selectedExistingFiles = [];
  editMode: string;
  constructor(
    private userProvider: UserProvider,
    private toastr: ToastrService,
    private router: Router,
    private route: ActivatedRoute,
    private inspectionStepProvider: InspectionStepProvider
  ) {}

  ngOnInit() {
    this.loadInspectionSteps();
    this.route.paramMap.subscribe(params => {
    });
  }
  toggleExistingFile(file) {
    const index = this.selectedExistingFiles.indexOf(file);
    if (index === -1) {
      this.selectedExistingFiles.push(file);
    } else {
      this.selectedExistingFiles.splice(index, 1);
    }
  }
  deleteSelectedFiles() {
    if (this.deletingFile) {
      return ;
    }
    this.deletingFile = true;
    const fileIds: number[] = this.selectedExistingFiles.map(file => file.id);
    this.inspectionStepProvider.deleteFiles(this.editingStep.id, fileIds).subscribe({
      next: (response: any) => {
        this.editingStep.files = this.editingStep.files.filter((file) => {
          return fileIds.indexOf(file.id) === -1;
        });
        this.toastr.success('Files deleted', 'Success');
        this.deletingFile = false;
        this.selectedExistingFiles = [];
      },
      error: (error) => {
        console.log('step error', error);
        this.toastr.error('Could not delete files', 'Error');
        this.deletingFile = false;
      }
    });
  }
  closeUpload() {
    this.uploadingFile = false;
    this.selectedFile = false;
    this.editingStep._editMode = undefined;
    this.editingStep = undefined;
  }
  submitFile() {
    if (!this.selectedFile) {
      this.toastr.error('No file selected', 'Error');
      return ;
    }
    const formData = new FormData();
    formData.set('file', this.selectedFile);
    if (this.uploadingFile) {
      return ;
    }
    this.uploadingFile = true;
    this.inspectionStepProvider.update(this.editingStep.id, formData, true).subscribe({
      next: (response: any) => {
        this.toastr.success('File uploaded successfully', 'Success');
        Object.assign(this.editingStep, response.data);
        this.selectedFileDataUrl = false;
        this.uploadingFile = false;
      },
      error: (error) => {
        console.log('step error', error);
        this.toastr.error('Could not upload file', 'Error');
        this.uploadingFile = false;
      }
    });
    console.log('upload confirm');
  }

  saveStepName(step) {
    step._saving = true;
    this.inspectionStepProvider.update(step.id, {name: step.name}).subscribe({
      next: (response: any) => {
        this.toastr.success('Inspection step name updated', 'Success');
        step._saving = false;
        step._editName = false;
      },
      error: (error) => {
        this.toastr.error('Could not update inspection step name', 'Error');
        step._saving = false;
      }
    });
  }
  saveInstructions(step) {
    this.savingInstructions = true;
    this.inspectionStepProvider.update(step.id, {instructions: step.instructions}).subscribe({
      next: (response: any) => {
        this.toastr.success('Instructions saved successfully', 'Success');
        this.savingInstructions = false;
      },
      error: (error) => {
        this.toastr.error('Could not save instructions', 'Error');
        this.savingInstructions = false;
      }
    });
  }
  toggleEditMode(value, step) {
    if (this.editMode === value) {
      // toggle off
      if (step._editMode === 'upload') {
        this.closeUpload();
      }
      this.editMode = undefined;
      this.editingStep = undefined;
      step._editMode = undefined;
      return ;
    } else {
      this.editMode = value;
      this.editingStep = step;
      step._editMode = value;
    }
  }

  loadInspectionSteps() {
    this.loadingInspectionSteps = true;
    this.inspectionStepProvider.list().subscribe({
      next: (response: any) => {
        this.loadingInspectionSteps = false;
        this.inspectionSteps = response.data;
      },
      error: () => {
        this.toastr.error('Could not load inspection steps', 'Error');
        this.loadingInspectionSteps = false;
      }
    })
  }
  toggleInspectionStepAvailable(step) {
    step.available = !step.available;
    step._toggling = true;
    this.inspectionStepProvider.update(step.id, {available: step.available}).subscribe({
      next: (response: any) => {
        step._toggling = false;
      },
      error: (error) => {
        this.toastr.error('Could not create inspection step', 'Error');
        step._toggling = false;
      }
    });
  }

  createInspectionStep() {
    if (this.savingInspectionStep) {
      return ;
    }
    this.savingInspectionStep = true;
    this.inspectionStepProvider.create(this.newInspectionStep).subscribe({
      next: (response: any) => {
        this.toastr.success('New inspection step created', 'Success');
        this.savingInspectionStep = false;
        this.inspectionSteps.push(response.data);
      },
      error: (error) => {
        console.log('step error', error);
        this.toastr.error('Could not create inspection step', 'Error');
        this.savingInspectionStep = false;
      }
    });
  }
  fileSelected(event) {
    if (event.target.files && event.target.files[0]) {
      this.selectedFile = event.target.files[0];
      const reader = new FileReader();
      reader.onload = (event: any) => {
        this.selectedFileDataUrl  = event.target.result;
      };
      reader.readAsDataURL(event.target.files[0]);
    }
  }
  saveNrFilesRequired(step) {
    if (step._toggling) {
      return ;
    }
    step._toggling = true;
    this.inspectionStepProvider.update(step.id, {nrFiles: step.nrFiles}).subscribe({
      next: (response: any) => {
        this.toastr.success('Nr of files required set to ' + step.nrFiles, 'Success');
        step._toggling = false;
      },
      error: (error) => {
        this.toastr.error('Could not update inspection step', 'Error');
        step._toggling = false;
      }
    });
  }
}
